import React from 'react'
import ReactDOM from 'react-dom/client'
import { RouterProvider } from 'react-router-dom'
import './styles/default.scss'
import { register, unregister } from 'register-service-worker'
import router from './routes/root'
import { isDev } from './core/helper'
import { config } from './config'

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
)
root.render(
    <React.StrictMode>
        <RouterProvider router={router} />
    </React.StrictMode>,
)

const setHeight = () => {
    const root = document.getElementById('root') as HTMLElement
    root.style.minHeight = window.innerHeight + 'px'
    root.style.maxHeight = window.innerHeight + 'px'
    root.style.height = window.innerHeight + 'px'
}
window.addEventListener('resize', setHeight)
setHeight()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals()

if (!isDev()) {
    register(`${process.env.PUBLIC_URL}/service-worker.js`, {
        ready() {
            console.log('Service worker is active.')
        },
        registered() {
            console.log('Service worker has been registered.')
        },
        cached() {
            console.log('Content has been cached for offline use.')
        },
        updatefound() {
            console.log('New content is downloading.')
        },
        updated() {
            console.log('New content is available; please refresh.')
        },
        offline() {
            console.log('No internet connection found. App is running in offline mode.')
        },
        error(err: any) {
            console.error('Error during service worker registration:', err)
        },
    })
} else {
    unregister()
}
