import React, { useEffect, useRef, useState } from 'react'
import { useLoaderData, useNavigate } from 'react-router-dom'
import { Input } from '../../../component/Input'
import { LastAndFood, useFoods } from '../../../hooks/useFood'
import { FoodItem } from './FoodItem'
import { NutrientsLine } from '../../../component/NutrientsItem'
import { wait } from '@testing-library/user-event/dist/utils'
import { BackInfoManager, useBackInfo } from '../../../hooks/useBackInfo'
import { BottomMenu } from '../../../component/BottomMenu'
import { BabyItem } from '../../../component/BabyItem'
import { useBaby } from '../../../hooks/useBaby'
import { urls } from '../../../core/urls'
import { useErrors } from '../../../hooks/useErrors'
import { SmartErrorList } from '../../../component/SmartErrorList'
import { Page, PageMain } from '../../../component/Layout/Page'
import { ActionButtons } from '../../../component/ActionButtons'
import { AddButton } from '../../../component/ActionButtons/RoundButton'
import { SimpleButton } from '../../../component/Form/Button/SimpleButton'
import { DuetButtons } from '../../../component/ActionButtons/DuetButtons'
import { HeadTitle } from '../../../component/Head/HeadTitle'
import { useTablesCounts } from '../../../hooks/useDatabase'
import cl from './index.module.scss'

type StageType = 'wait_user' | 'load_more' | 'search' | 'loading' | 'reload' | 'insert_food' | 'inserting_food'
export const FoodsPage: React.FunctionComponent = (props) => {
    const data = useLoaderData() as { babyId?: number }
    const [baby] = useBaby()
    const backInfo = useBackInfo()
    const navigate = useNavigate()
    const [tablesCounts] = useTablesCounts()
    const [stage, setStage] = useState('wait_user' as StageType)
    const [search, setSearch] = useState('')
    const [foods, setFoods] = useState([] as LastAndFood[])
    const [lastAndFood, setLastAndFood] = useState(undefined as LastAndFood | undefined)
    const [offset, setOffset] = useState(0 as undefined | number)
    const [errors, addError, resetError] = useErrors()
    const foodsManager = useFoods()
    const v = useRef(0)
    const fxLoadMore = useRef(foodsManager.getLastAndFoods.bind(foodsManager))
    const [head, setHead] = useState(data.babyId ? (
        <BabyItem key={`${data.babyId}`} baby={baby} />
    ) : <HeadTitle />)
    useEffect(() => {
        setHead(data.babyId ? (
            <BabyItem key={`${data.babyId}`} baby={baby} />
        ) : <HeadTitle />)
    }, [baby])
    useEffect(() => {
        if (stage !== 'insert_food' || lastAndFood === undefined) {
            return
        }
        v.current += 1
        const ver = v.current
        resetError()
        setStage('inserting_food')
        const manager = new BackInfoManager('', backInfo)
        manager.addFood(lastAndFood).then((result) => {
            navigate(result, { replace: true })
        }).catch((err) => {
            if (v.current === ver) {
                console.log('addError from FoodPage', v.current)
                addError(err)
            }
        }).finally(() => setStage('wait_user'))
    }, [stage, lastAndFood])
    useEffect(() => {
        if (stage === 'load_more' || stage == 'search' || stage == 'reload') {
            const tStage = stage
            resetError()
            setStage('loading')
            v.current += 1
            const ver = v.current
            const limit = 30
            wait(stage === 'search' ? 300 : 0).then(() => {
                if (ver != v.current) {
                    return
                }
                let off = offset
                if (stage == 'reload') {
                    off = 0
                }
                return fxLoadMore.current(off, limit).then(res => {
                    if (ver != v.current) {
                        return
                    }
                    if (res.length === 0) {
                        setOffset(undefined)
                    } else {
                        setOffset((off || 0) + res.length)
                    }
                    if (tStage === 'load_more') {
                        setFoods(f => {
                            return [...onlyUniqueFoods(f.concat(...res))]
                        })
                    } else {
                        setFoods(res)
                    }
                    setStage('wait_user')
                })
            }).then(() => {
            }).catch((err) => {
                addError(err)
                setStage('wait_user')
            })
        }
    }, [stage, offset])
    useEffect(() => {
        if (!offset || !tablesCounts) {
            return
        }
        if (search.length === 0) {
            if (offset >= tablesCounts.lastFoods) {
                setOffset(undefined)
            }
        } else {
            if (offset >= tablesCounts.foods) {
                setOffset(undefined)
            }
        }
    }, [tablesCounts, foods, offset])
    useEffect(() => {
        if (search.length == 0) {
            fxLoadMore.current = foodsManager.getLastAndFoods.bind(foodsManager)
            setOffset(0)
            setStage('reload')
        } else {
            fxLoadMore.current = foodsManager.searchLastAndFood.bind(foodsManager, search)
            setOffset(0)
            setStage('search')
        }
    }, [search])
    useEffect(() => {

    })
    const change = (v: string) => {
        setSearch(v)
    }
    const loadMore = () => {
        setStage('load_more')
    }
    const selectFood = (laf: LastAndFood) => {
        if (!backInfo || (!backInfo.f && !backInfo.m)) {
            console.log('Открываем для редактирования')
            navigate(urls.editFoodPage(laf.f.dt, baby?.id))
        } else {
            setLastAndFood(laf)
            setStage('insert_food')
        }
    }
    const createNew = () => {
        const foodName = search.trim() || undefined
        if (!backInfo) {
            console.log('действуем вне рамок добавления в mealtime or food')
            navigate(urls.editFoodPage(-1, baby?.id, undefined, undefined, foodName))
        } else {
            console.log('будет куда встроить новую еду')
            navigate(urls.editFoodPage(-1, backInfo.b, backInfo.m, backInfo.f, foodName))
        }
    }
    const back = () => {
        const m = new BackInfoManager('', backInfo)
        try {
            navigate(m.back())
        } catch (err) {
            addError(err as any)
        }
    }
    const isDisabled = stage !== 'wait_user'
    return (
        <Page>
            <Page.Main>
                <PageMain.Title>
                    {head}
                </PageMain.Title>
                <PageMain.Content>
                    <div className={cl.foods}>
                        <h1>{backInfo && backInfo.b ? 'Выбрать продукт' : 'Список продуктов'}</h1>
                        <div className={cl.foods__find}>
                            <span>Поиск по имени:</span>
                            <Input value={search}
                                autofocus={true}
                                placeholder='название...'
                                onChange={change}/>
                        </div>
                        <div className={cl.foods__table_head}>
                            <div>Название</div>
                            <div className='nuts' style={{ width: 220 }}>
                                <NutrientsLine line={['б (г)', 'ФА (мг)', 'ж (г)', 'у (г)', 'ккал']}/>
                            </div>
                        </div>
                        <div className={cl.foods__items}>
                            {
                                foods.map(laf => {
                                    return <FoodItem key={laf.f.dt + ' ' + laf.lf?.dt} food={laf}
                                        search={search} click={selectFood} />
                                })
                            }
                            {
                                offset !== undefined && offset !== 0
                                    ? (
                                        <div className={cl.foods__items__load_more}>
                                            <SimpleButton type='transparent' click={loadMore} disabled={isDisabled}>
                                                загрузить ещё
                                            </SimpleButton>
                                        </div>
                                    )
                                    : undefined
                            }
                        </div>
                        <ActionButtons>
                            <AddButton onClick={createNew} disabled={isDisabled}/>
                        </ActionButtons>
                        <SmartErrorList errors={errors} resetError={resetError}/>
                    </div>
                </PageMain.Content>
            </Page.Main>
            <Page.Footer key="button">
                {
                    backInfo && (backInfo.m || backInfo.f) ? (
                        <div style={{ padding: 16 }}>
                            <DuetButtons
                                first={{
                                    type: 'transparent',
                                    action: back,
                                    content: 'Назад',
                                }}
                                disabled={isDisabled}
                            />
                        </div>
                    ) : (
                        <BottomMenu key="button-menu" selected='foods' />
                    )
                }
            </Page.Footer>
        </Page>
    )
}

function onlyUniqueFoods(laf: LastAndFood[]): LastAndFood[] {
    return laf.filter((value, index, self) => {
        return self.findIndex(one => {
            if (one.f.dt === value.f.dt) {
                return true
            }
            return false
        }) === index
    })

}
