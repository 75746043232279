import React, { useEffect, useState } from 'react'
import { Modal } from '../Modal'
import { curTime, dtToUnix } from '../../core/helper'
import { SimpleButton } from '../Form/Button/SimpleButton'
import { DuetButtons } from '../ActionButtons/DuetButtons'
import { MealtimeDate } from '../Form/MealtimeDateTime/MealtimeDate'
import { dtToTimeDate } from '../../pages/Client/MealtimePage/DateComp'
import { MealtimeTypeManager } from '../../hooks/useMealtimeTypes'
// import cl from './index.module.scss';


type Props = {
    title: string
    dt: number
    change: (dt: number) => void
    disabled: boolean
    visible: boolean
    setVisible: React.Dispatch<React.SetStateAction<boolean>>
}

export const ModalDateTime: React.FunctionComponent<Props> = ({
    visible,
    setVisible,
    ...props }) => {
    const [info, setInfo] = useState(dtToTimeDate(props.dt))
    const [initDt, setInitDt] = useState(props.dt)
    useEffect(() => {
        if (visible) {
            setInitDt(props.dt)
        }
    }, [visible])
    useEffect(() => {
        setInfo(dtToTimeDate(props.dt))
    }, [props.dt])

    const changeDate = (date: Date) => {
        date && date.getTime() && props.change(dtToUnix(date, true))
    }
    const changeTime = (e: React.ChangeEvent<HTMLInputElement>) => {
        const v = e.target.value
        const dt = MealtimeTypeManager.changeTime(props.dt, v)
        if (dt) {
            props.change(dt)
        }
    }
    const current = () => {
        const dt = curTime(true)
        props.change(dt)
    }
    const cancel = () => {
        props.change(initDt)
        setVisible(false)
    }
    const apply = () => {
        setVisible(false)
    }
    return (
        <Modal isShow={visible} changed={setVisible}>
            <div>
                <h1 className='font-kurale text-title font-20' style={{ fontWeight: 'normal' }}>{props.title}</h1>
                <div className='font-raleway' style={{
                    paddingTop: 32,
                    display: 'grid',
                    gridTemplateColumns: '1fr auto 2fr 1fr',
                    gridTemplateRows: '1fr 1fr',
                    justifyContent: 'center',
                    alignItems: 'center',
                    color: '#1A6637',
                    rowGap: 12,
                }}>
                    <div></div>
                    <div style={{ paddingRight: 6, textAlign: 'right' }}>Дата:</div>
                    <MealtimeDate dt={props.dt}
                        disable={props.disabled} change={changeDate} />
                    <div></div>
                    <div></div>
                    <div style={{ paddingRight: 6, textAlign: 'right' }}>Время:</div>
                    <input type='time' value={info.time} disabled={props.disabled} onChange={changeTime}/>
                    <div></div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'center', textAlign: 'center', marginTop: 24 }}>
                    <SimpleButton type='transparent' click={current}>установить текущее<br/>дату и время</SimpleButton>
                </div>
            </div>
            <div style={{ paddingTop: 64 }}>
                <DuetButtons
                    first={{
                        type: 'transparent',
                        action: cancel,
                        content: 'Отменить',
                    }}
                    second={initDt !== props.dt ? {
                        type: 'success',
                        action: apply,
                        content: 'Сохранить',
                    } : undefined}
                />
            </div>
        </Modal>
    )
}
