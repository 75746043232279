import React, { FunctionComponent, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { urls } from '../../core/urls'
import { useBaby } from '../../hooks/useBaby'
import { clickDelay, cls } from '../../core/helper'
import cl from './index.module.scss'


export type BottomButtonType = 'condition' | 'children' | 'mealtime' | 'listConditions' | 'foods' | 'settings' | 'mealtimeEdit'
type Props = {
    selected?: BottomButtonType
    disabled?: boolean
}

const delayMsec = 300
export const BottomMenu: React.FunctionComponent<Props> = (props) => {
    const [baby] = useBaby()
    const navigate = useNavigate()
    const goCondition = clickDelay(delayMsec, () => {
        navigate(urls.conditionPage(baby?.id || -1))
    })
    const goChildren = clickDelay(delayMsec, () => {
        navigate(urls.childrenPage)
    })
    const goMealtime = clickDelay(delayMsec, () => navigate(urls.mealtimePage(baby?.id)))
    const goListConditions = clickDelay(delayMsec, () => navigate(urls.listConditionsPage(baby?.id)))
    const goFoods = clickDelay(delayMsec, () => navigate(urls.foodsPage(baby?.id)))
    const goSettings = clickDelay(delayMsec, () => navigate(urls.initializeDatabase))
    const babyExist = baby && baby.id > 0
    const isCondition = props.selected !== 'condition' && babyExist
    return (
        <div className={cl.panel}>
            <Button click={!isCondition ? goChildren : goCondition} type={!isCondition ? 'children' : 'condition'}
                selected={props.selected} disabled={props.disabled}/>
            <Button type='mealtime' click={goMealtime} selected={props.selected} disabled={!babyExist || props.disabled}/>
            <Button type='listConditions' click={goListConditions} selected={props.selected} disabled={!babyExist || props.disabled}/>
            <Button type='foods' click={goFoods} selected={props.selected} disabled={!babyExist || props.disabled}/>
            <Button type='settings' click={goSettings} selected={props.selected} disabled={props.disabled}/>
        </div>
    )
}


export const Button: FunctionComponent<{
    type: BottomButtonType,
    click: () => void,
    selected?: BottomButtonType
    disabled?: boolean
}> = (props) => {
    const click = useCallback(() => {
        if (!props.disabled) {
            props.click()
        }
    }, [props.click, props.disabled])
    return (
        <div key={props.type} {...cls(
            cl.btn,
            props.selected === props.type ? cl['btn--selected'] : '',
            props.disabled === true ? cl['btn--disabled'] : '',
        )}
        onClick={click}><img src={`/img/ico-${props.type}.png`}/></div>
    )
}
