import React from 'react'
import { FormItems } from '../Components/FromItems'
import { FormFromToItems } from '../Components/FormFromToItems'
import { Condition } from '../../../../core/db_condition'
import cl from './../index.module.scss'


type Props = {
    condition: Partial<Condition> | undefined
    changeCondition: (cond: Partial<Condition>) => void
    disabled: boolean
    disabledReset: boolean
}

export const BaseCalculator: React.FunctionComponent<Props> = ({
    condition,
    disabled, disabledReset,
    ...props }) => {
    return (
        <>
            <div className={cl.cond__form__from_to} style={{ paddingTop: 24, paddingBottom: 12 }}>
                <FormItems condition={condition} disabled={disabled || !props.changeCondition}
                    updateCondition={props.changeCondition}
                    items={[{
                        key: 'e',
                        label: 'Калорий:',
                        ext: 'ккал',
                        step: 0.1,
                    },
                    ]}
                />
            </div>
            <div className={cl.cond__form__from_to}>
                <span></span>
                <span className={cl.loc_form_label}>от</span>
                <span className={cl.loc_form_label}>до</span>
                <span></span>
                <FormFromToItems condition={condition} disabled={disabled || !props.changeCondition}
                    updateCondition={props.changeCondition}
                    items={[{
                        key1: 'p',
                        key2: 'p2',
                        label: 'Белки:',
                        ext: 'г',
                        step: 1,
                    }, {
                        key1: 'f',
                        key2: 'f2',
                        label: 'Жиры:',
                        ext: 'г',
                        step: 1,
                    }, {
                        key1: 'c',
                        key2: 'c2',
                        label: 'Углеводы:',
                        ext: 'г',
                        step: 1,
                    },
                    ]}
                />
            </div>
        </>
    )
}
