import { Condition } from './db_condition'
import { Food } from './db_food'
import { Mealtime } from './db_mealtime'

abstract class Draft<T> {
    protected saveItems: Record<number, T>
    constructor(protected key: string) {
        this.saveItems = {}
    }
    protected save() {
        localStorage.setItem(this.key, JSON.stringify(this.saveItems))
    }
    protected load() {
        this.saveItems = JSON.parse(localStorage.getItem(this.key) || '{}')
    }
    protected delete() {
        localStorage.removeItem(this.key)
    }
}

export class ItemDraft<T> extends Draft<T> {
    constructor(
        protected id: number,
        protected key: string,
    ) { super(key)}

    public saveItem(obj: T) {
        this.saveItems[this.id] = obj
        this.save()
    }

    public loadItem(): T | undefined {
        this.load()
        return this.saveItems[this.id]
    }

    public deleteItem() {
        this.load()
        delete this.saveItems[this.id]
        if (Object.keys(this.saveItems).length == 0) {
            super.delete()
        } else {
            super.save()
        }
    }

    public getNextDraftId(): number {
        this.load()
        const nexId = Math.min(...Object.keys(this.saveItems).map(one => parseInt(one)).filter(one => one < 0), -1)
        return nexId - 1
    }

    public haveDraft() {
        return this.loadItem() !== undefined
    }
}

export class MealtimeDraft extends ItemDraft<{
    base?: Mealtime
    current?: Mealtime
}> {
    constructor(
        protected mealtimeId: number,
        protected babyId: number,
    ) {
        super(mealtimeId, MealtimeDraft.draftKey(babyId))
    }

    static draftKey(babyId: number): string {
        return `draft_mealtime__baby_${babyId}`
    }
}

export class FoodDraft extends ItemDraft<{
    parents: {
        mealtime?: number
        foods: number[]
    }
    base: Food
    current: Food
}> {
    constructor(protected foodId: number) {
        super(foodId, FoodDraft.draftKey())
    }

    static draftKey(): string {
        return 'draft_food'
    }
}


export class ConditionDraft extends ItemDraft<{
    base?: Condition
    current?: Condition
}> {
    constructor(
        protected conditionDt: number,
        protected babyId: number,
    ) {
        super(conditionDt, ConditionDraft.draftKey(babyId))
    }

    static draftKey(babyId: number): string {
        return `draft_condition__baby_${babyId}`
    }
}