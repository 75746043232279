/* eslint-disable max-len */
import React from 'react'
import { Page, PageMain } from '../../../component/Layout/Page'
import { HeadTitle } from '../../../component/Head/HeadTitle'
import { DuetButtons } from '../../../component/ActionButtons/DuetButtons'
import { useNavigate } from 'react-router-dom'
import { urls } from '../../../core/urls'
import cl from './index.module.scss'


export const CreateAccountInformation: React.FunctionComponent = () => {
    const navigate = useNavigate()
    const goBack = () => {
        navigate(urls.selectEnterPage)
    }
    return (
        <Page hFull={true}>
            <Page.Main>
                <PageMain.Title>
                    <HeadTitle showLangs={true}/>
                </PageMain.Title>
                <PageMain.Content>
                    <div className={cl.main}>
                        <div>
                            <h1>Новый аккаунт</h1>
                            <p>В течении первых трёх этапов бета-тестирования, регистрация новых пользователей идёт в полуавтоматическом режиме, в соответствии с текущим этапом тестирования.</p>
                        </div>
                        <div>
                            <h1>Бета-тестирование</h1>
                            <p>Доступ к бета-тестированию будет открываться поэтапно, само бета-тестирование пройдёт в четыре этапа:</p>
                            <div>
                                <h2>Первый этап</h2>
                                <p>На данный этап приглашаются преданные пользователи старой версии сервиса.</p>
                                <div className={cl.stage_target}>
                                    <strong>Дата начала</strong> – 01 апреля 2024
                                </div>
                                <div className={cl.stage_target}>
                                    <strong>Длительность</strong> – одна неделя, в случае обнаружения ошибок, до их устранения.
                                </div>
                                <div className={cl.stage_target}>
                                    <strong>Основная цель этапа</strong> – убедиться в правильности расчётов, успешной миграции данных со старой версии программы и корректной работы на различных устройствах.
                                </div>
                                <p>Для авторизации можете использовать логин/пароль со старой версии сервиса.</p>
                                <p>В случае если вы их забыли, можете их восстановить через телеграм бота <a href="https://t.me/DietWizardBot">@DietWizardBot</a></p>
                            </div>
                            <div>
                                <h2>Второй этап</h2>
                                <p>На втором этапе бета-тестирования приглашаются те люди, без чьей поддержки и ценных советов этот проект не был бы возможен. Вы внесли значительный вклад в развитие сервиса, и я глубоко благодарен вам за это. Ваши донаты, советы и замечания помогли мне преодолеть многие трудности и продолжать работу над проектом. Я надеюсь, что вы продолжите поддерживать меня и помогать мне в будущих начинаниях.</p>
                                <div className={cl.stage_target}>
                                    <strong>Длительность</strong> – две недели, в случае обнаружения ошибок, до их устранения.
                                </div>
                                <div className={cl.stage_target}>
                                    <strong>Основная цель этапа</strong> – убедиться, что режим «без интернета» и другие функции работают безупречно и что пользователи могут легко и удобно вносить новую информацию.
                                </div>
                                <p>Получение доступа для тех кто делал анонимный донат: к окончанию первого этапа будет можно будет написать телеграм боту <a href="https://t.me/DietWizardBot">@DietWizardBot</a> <span>/I_am_philanthropist</span>, размер доната, куда и примерно когда его делали.</p>
                                <p>Для людей которые помогали ценными советами и замечаниями, этому же боту написать <span>/I_am_advisor</span>.</p>
                            </div>
                            <div>
                                <h2>Третий этап</h2>
                                <p>На третий этап приглашаются подписчики <a href="https://t.me/pkudiet">канала в телеграм</a>.</p>
                                <p>Друзья вы не представляете как ваши лайки поддерживали мой дух в процессе разработки и я понимал, что делаю это не зря. Спасибо вам.</p>
                                <div className={cl.stage_target}>
                                    <strong>Длительность</strong> – месяц, в случае обнаружения ошибок, до их устранения.
                                </div>
                                <div className={cl.stage_target}>
                                    <strong>Основная цель этапа</strong> – сбор обратной связи по функционалу, обсуждение расширения функционала и тестирование результатов реализации.
                                </div>
                                <p>Для получения пароля написать боту <a href="https://t.me/DietWizardBot">@DietWizardBot</a> <span>/I_am_one_of_us</span>, будет доступно к окончанию второго этапа тестирования.</p>
                            </div>
                            <div>
                                <h2>Четвёртый этап</h2>
                                <p>Для всех желающих.</p>
                                <div className={cl.stage_target}>
                                     Основная цель этапа – проверить устойчивость сервиса к нагрузкам.
                                </div>
                                <p>На четвёртом этапе бета-тестирования станет доступна регистрация на самом ресурсе.</p>
                            </div>
                        </div>
                        <div>
                            <h1>Общая информация</h1>
                            <p>Как и прежде, я очень трепетно отношусь ко всем вашим данным, поэтому на сервисе не используются cookie, не используются сервисы отслеживания пользователей и их поведения.</p>
                            <p>Так же при регистрации генерируются логин и пароль, ваши личные данные не записываются, как и источник регистрации.</p>
                            <p>Поэтому и восстановить эти данные будет нельзя, сохраните их в удобном для вас месте.</p>
                        </div>

                    </div>
                </PageMain.Content>
            </Page.Main>
            <Page.Footer key="button">
                <div style={{ padding: 16 }}>
                    <DuetButtons first={{
                        type: 'transparent',
                        content: 'Назад',
                        action: goBack,
                    }}/>
                </div>
            </Page.Footer>
        </Page>
    )
}
