import React, { useEffect, useState } from 'react'
import cl from './input.module.scss'


type Props = {
    className?: string
    value?: number
    step: number
    disabled: boolean
    tabIndex?: number
    autofocus?: boolean
    change: (value: number) => void
    style?: object
    blur?: () => void
    focus?: () => void
}

export const InputNumber: React.FunctionComponent<Props> = (props) => {
    const [value, setValue] = useState(
        props.value === undefined || (props.autofocus && props.value == 0)
            ? ''
            : props.value)
    useEffect(() => {
        if (typeof (value) === 'string' || !isNaN(value)) {
            setValue(
                props.value === undefined || (props.autofocus && props.value == 0)
                    ? ''
                    : props.value)
        }
    }, [props.value])

    const change = (e: React.ChangeEvent<HTMLInputElement>) => {
        const s = e.target.value
        const sanitizedValue = s.replace(/[^0-9.,]/g, '')
        if (!sanitizedValue || (sanitizedValue.match(/[.,]/g) || []).length <= 1) {
            changeValue(sanitizedValue)
        }
    }
    const changeValue = (val: any) => {
        let v = parseFloat(val)
        if (v < 0) {
            v = 0
            if (value == v) {
                return
            }
        }
        setValue(val)
        if (val !== '') {
            if (!isNaN(v)) {
                props.change(v)
            } else {
                props.change(0)
            }
        }
    }
    const focus = () => {
        if (props.value === 0) {
            setValue('')
        }
        props.focus && props.focus()
    }
    const blur = () => {
        const v = calcBlur(`${value}`)
        setValue(v)
        v !== props.value && props.change(v)
        props.blur && props.blur()
    }
    return (
        <input className={cl.input + ' ' + (props.className || '')}
            style={{
                ...(props.style || {}),
            }} type="number"
            inputMode="decimal"
            pattern="[0-9,.]*"
            value={value} step={props.step}
            onChange={change} onFocus={focus} onBlur={blur}
            min={0} max={10000}
            placeholder='-'
            autoFocus={props.autofocus === true}
            tabIndex={props.tabIndex === undefined ? 0 : props.tabIndex}
            disabled={props.disabled}/>
    )
}

function calcBlur(val: string): number {
    let v = parseFloat(val)
    if (v < 0) {
        return 0
    }
    if (isNaN(v)) {
        v = 0
    }
    return v
}
