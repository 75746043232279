import React, { useCallback } from 'react'
import { cls } from '../../../core/helper'
import cl from './textarea.module.scss'


type Props = {
    textareaClass?: string
    value?: string
    disabled: boolean
    tabIndex?: number
    autofocus?: boolean
    change: (val: string) => void
}

export const TextArea: React.FunctionComponent<Props> = (props) => {
    const onChange = useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {
        props.change(e.target.value)
    }, [props.change])
    return (
        <textarea {...cls(
            cl.textarea,
            props.textareaClass || '',
        )}
        value={props.value}
        onChange={onChange}
        autoFocus={props.autofocus === true}
        tabIndex={props.tabIndex}
        disabled={props.disabled}
        />
    )
}
