import React, { useEffect, useMemo, useState } from 'react'
import axios from 'axios'
import { config } from '../../../config'
import { cls } from '../../../core/helper'
import cl from './index.module.scss'


type Props = {
    onChange: (server: number | undefined | null) => void
}

export const Version: React.FunctionComponent<Props> = (props) => {
    const serVer = useServerVersion()
    const isWrong = useMemo(() => !!serVer && serVer != config.cache_version, [serVer])
    useEffect(() => {
        props.onChange(serVer)
    }, [serVer])
    return (
        <div {...cls(
            cl.head_title__version,
            cl[`head_title__version--${isWrong ? 'not-equals' : serVer}`],
        )}>
            {
                isWrong ? (
                    <>{getFullVersion(config.cache_version)} ≠ {getFullVersion(serVer || 0)}</>
                ) : <>{getFullVersion(config.cache_version)}{serVer === null ? '(offline)' : ''}</>
            }
        </div>
    )
}


export function useServerVersion() {
    const [version, setVersion] = useState(undefined as undefined | number | null)
    useEffect(() => {
        axios({
            url: `/static/sub-version.txt?rnd=${Math.random()}`,
        }).then((result) => {
            if (result.data !== undefined && result.data != null) {
                setVersion(result.data)
            } else {
                setVersion(null)
            }
        }).catch(err => {
            setVersion(null)
        })
    }, [])
    return version
}


function getFullVersion(buildNum: number): string {
    const l = [0, 87].reduce((subversion, one, index) => {
        if (one < buildNum) {
            return index
        }
        return subversion
    }, 0)
    return `v2.${l}.${buildNum}`
}