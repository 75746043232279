import React, { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react'
import { dtDate, dtToDateString } from '../../../../core/helper'
// import cl from './index.module.scss';


type Props = {
    dt: number
    disable: boolean
    change: (date: Date) => void
}

export const MealtimeDate: FunctionComponent<Props> = (props) => {
    const [value, setValue] = useState(dtToDateString(props.dt))
    const r = useRef(null as HTMLInputElement | null)
    useEffect(() => {
        setValue(dtToDateString(props.dt))
    }, [props.dt])
    const callback = useCallback((newDate?: Date) => {
        if (!newDate) {
            setValue(dtToDateString(props.dt))
            return
        }
        props.change(newDate)
    }, [])
    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setValue(e.target.value)
    }
    const onInput = () => {
        callback(valueToDate(r.current?.value, props.dt))
    }
    const onBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        callback(valueToDate(e.target.value, props.dt))
    }
    return (
        <input ref={r} type='date' value={value} disabled={props.disable}
            onInput={onInput}
            onChange={onChange} onBlur={onBlur}/>
    )
}

function valueToDate(val: string | undefined, baseDt: number): Date | undefined {
    if (!val) {
        return undefined
    }
    const dt = new Date(val)
    if (isNaN(dt as any)) {
        return undefined
    }
    const cur = dtDate(baseDt)
    cur.setFullYear(dt.getFullYear())
    cur.setMonth(dt.getMonth())
    cur.setDate(dt.getDate())
    return cur
}