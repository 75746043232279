import React, { FunctionComponent, ReactNode } from 'react'
import { Await, useLoaderData } from 'react-router-dom'
import { AwaitError } from '../AwaitError'


type Props = {
    Component: React.FunctionComponent<{data: any}>
    waitTitle?: string
}

export const SuspenseAwait: FunctionComponent<Props> = ({ Component, ...props }) => {
    const data = useLoaderData() as {
        result: Promise<any>
    }
    return (
        <React.Suspense
            fallback={<p className="p-3">
                <span className="loading loading-spinner loading-xs"></span>
                <span className="pl-1">{props.waitTitle || 'Проверка авторизации...'}</span>
            </p>}
        >
            <Await
                resolve={data.result}
                errorElement={<AwaitError />}
            >
                {(data) => <Component data={data} />}
            </Await>
        </React.Suspense>
    )
}
