import React, { useCallback, useEffect, useRef, useState } from 'react'
import { BabyItem } from '../../../component/BabyItem'
import { useBaby } from '../../../hooks/useBaby'
import { BottomMenu } from '../../../component/BottomMenu'
import { dbManager } from '../../../core/db'
import { Mealtime } from '../../../core/db_mealtime'
import { Condition } from '../../../core/db_condition'
import { MealtimeItem } from './MealtimeItem'
import { useMealtimeTypes } from '../../../hooks/useMealtimeTypes'
import { ConditionItem } from './ConditionItem'
import { useLoaderData, useNavigate } from 'react-router-dom'
import { urls } from '../../../core/urls'
import { Page, PageMain } from '../../../component/Layout/Page'
import { ActionButtons } from '../../../component/ActionButtons'
import { curTime } from '../../../core/helper'
import { dtToTimeDate } from './DateComp'
import { RoundButton } from '../../../component/ActionButtons/RoundButton'
import { Baby } from '../../../core/db_baby'
import { isSomeDay } from '../../../core/time_helper'
import cl from './index.module.scss'

type ListItem = Mealtime | Condition
const limit = 30
export const MealtimePage: React.FunctionComponent = () => {
    const navigate = useNavigate()
    const data = useLoaderData() as { babyId: number }
    const [baby, _, babyManager] = useBaby()
    const [mts] = useMealtimeTypes()
    const [list, setList] = useState([] as ListItem[])
    const [nextPrevList, setNextPRevList] = useState({
        next: [] as ListItem[],
        ready: [] as ListItem[],
    })
    const [curDt, setCurDt] = useState(dtToTimeDate(curTime()))
    const v = useRef(1)
    useEffect(() => {
        v.current = 0
    }, [])
    const loadMealtimes = useCallback((baby: Baby, list: ListItem[]) => {
        v.current += 1
        const version = v.current
        return dbManager.db.mealtimes.where('b').equals(baby.id).reverse().limit(limit).toArray().then((result) => {
            const l = result as Mealtime[]
            if (version != v.current) return Promise.reject('updated')
            return [...list.concat(...l)
                .sort(function(a, b) { return b.dt - a.dt })]
        }).then(async list => {
            const last = await dbManager.db.conditions.where('b').equals(baby.id).last()
            return {
                list,
                last,
            }
        }).then(({ list, last }) => {
            if (!last) {
                return list
            }
            if (version != v.current) return Promise.reject('updated')
            return [...list.concat(last as Condition).sort(function(a, b) { return b.dt - a.dt })]
        })
    }, [baby])

    useEffect(() => {
        if (!baby) {
            return undefined
        }
        if (baby.id < 0) {
            navigate(urls.childrenPage)
            return undefined
        }
        const list: ListItem[] = []
        setList(list)
        loadMealtimes(baby, list)?.then(list => {
            if (list) {
                setList(list)
            }
        }).catch(err => {
            if (err !== 'updated') {
                throw err
            }
        })
    }, [baby])
    useEffect(() => {
        const cur = curTime()
        setNextPRevList({
            next: list.filter(one => one.dt > cur),
            ready: list.filter(one => one.dt <= cur),
        })

    }, [list])
    const newMealtime = () => {
        if (!draft() && baby) {
            navigate(urls.editMealtimePage(baby.id, -1))
        }
    }

    const draft = () => {
        const draft = babyManager.getMealtimeDraft()
        if (!draft || Object.keys(draft).length == 0 || !baby) {
            return false
        }
        navigate(urls.editMealtimePage(baby.id, Object.keys(draft)[0]))
        return true
    }
    return (
        <Page>
            <Page.Main>
                <PageMain.Title>
                    <BabyItem key={`${data.babyId}`} baby={baby} />
                </PageMain.Title>
                <PageMain.Content>
                    <div style={{}}>
                        <h1 className='title'
                            style={{ position: 'sticky', top: 0, zIndex: 11 }}>Расписание приёма пищи</h1>
                        {/* <h1>Расписание приёма пищи</h1> */}
                        {
                            babyManager.getMealtimeDraft() ? (
                                <button onClick={draft}>draft</button>
                            ) : undefined
                        }
                        <div className={cl['border-meal-next']}>
                            {
                                mts && DaysItems<ListItem>({
                                    items: nextPrevList.next,
                                    fxKey(item, i) {
                                        return 'm' + (item as any).id
                                    },
                                    fx(one) {
                                        if (typeof ((one as any).l) === 'object') {
                                            return <MealtimeItem item={one as Mealtime}
                                                mts={mts} future={true} />
                                        }
                                    },
                                })
                            }
                            <div>
                                <div className='text-fa font-roboto' style={{
                                    fontSize: 12,
                                    marginLeft: -1,
                                    fontStyle: 'italic',
                                    textDecorationLine: 'underline',
                                }}>
                                    {'\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0'}
                                    сейчас {curDt.date} ({curDt.day}) {curDt.time}
                                    {'\u00A0\u00A0'}
                                </div>
                            </div>
                        </div>
                        <div className={cl['border-meal-ready']}
                            style={{ minHeight: '100vh', paddingTop: 8 }}>
                            {
                                mts && DaysItems<ListItem>({
                                    items: nextPrevList.ready,
                                    fxKey(item, i) {
                                        if (typeof ((item as any).l) === 'object') {
                                            return 'm' + (item as any).id
                                        } else {
                                            return 'c' + item.dt
                                        }
                                    },
                                    fx(one: ListItem) {
                                        if (typeof ((one as any).l) === 'object') {
                                            return (
                                                <MealtimeItem item={one as Mealtime} mts={mts} />
                                            )
                                        } else {
                                            return (
                                                <ConditionItem item={one as Condition} />
                                            )
                                        }
                                    },
                                })
                            }
                        </div>
                        <ActionButtons>
                            <RoundButton onClick={newMealtime}>
                                <img src='/img/ico-plus.svg' width={24} height={24} />
                            </RoundButton>
                        </ActionButtons>
                    </div>
                </PageMain.Content>
            </Page.Main>
            <Page.Footer key="button-menu">
                <BottomMenu key="button-menu" selected='mealtime' />
            </Page.Footer>
        </Page>
    )
}


type DaysItemsProps<T extends { dt: number }> = {
    items: T[]
    fxKey: (item: T, i: number) => string
    fx: (item: T) => React.ReactNode
}

function DaysItems<T extends { dt: number }>(props: DaysItemsProps<T>) {
    return (
        <>
            {
                props.items.map((one, i) => {
                    const isNewDay = i > 0 ? !isSomeDay(props.items[i - 1].dt, props.items[i].dt) : false
                    return (
                        <React.Fragment key={props.fxKey(one, i)}>
                            {isNewDay ? <Separator /> : null}
                            {props.fx(one)}
                        </React.Fragment>
                    )
                })
            }
        </>
    )
}

const Separator: React.FC = () => {
    return (
        <div style={{ height: 10, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <div style={{ borderBottom: '1px solid #ccc', width: '20%', maxWidth: 100, opacity: 0.5 }}></div>
        </div>
    )
}