import React from 'react'
import { RequestError, SmartError } from './SmartError'
// import cl from './index.module.scss';


type Props = {
    errors: RequestError[]
    resetError: (err?: RequestError) => void
}

export const SmartErrorList: React.FunctionComponent<Props> = ({ errors, resetError }) => {
    return (
        <div style={{
            position: 'fixed', top: 0, right: 0, width: 200,
            zIndex: 20,
            maxHeight: '100vh',
            overflowY: 'auto',
        }}>
            {
                errors.map((err, index) => {
                    return <SmartError
                        key={`${err.ts}-${err.error}`}
                        error={err}
                        resetError={resetError}
                    />
                })
            }
        </div>
    )
}
