import React, { FunctionComponent } from 'react'
import styles from './index.module.scss'
import { Link } from 'react-router-dom'
import { urls } from '../../core/urls'

export const ClientCreatePage: FunctionComponent = () => {
    return (
        <div className={styles.class}>
            <h2>ClientCreatePage</h2>
            <Link to={urls.loginPage} >Login</Link>/<Link to={urls.logoutPage}>Log out</Link>
        </div>
    )
}
