import { dbManager } from './db'
import { Condition } from './db_condition'

export async function loadConditions(babyId: number, from: number, limit: number = 10) {
    const list = await dbManager.db.conditions
        .where('b')
        .equals(babyId)
        .toArray() as Condition[]
    return list.sort(function(a, b) {
        return b.dt - a.dt
    }).slice(from, from + limit)
}