import React from 'react'
import { curTime, declineNumber, dtDate } from '../../core/helper'
// import cl from './index.module.scss';


export function textAgo(ts: number) {
    let val = curTime(true) - ts
    if (val < 60) {
        return `${Math.floor(val)} сек.`
    }
    val /= 60
    if (val < 60) {
        return `${Math.floor(val)} мин.`
    }
    val /= 60
    if (val < 24) {
        return `${Math.floor(val)} ${declineNumber(Math.floor(val), ['час', 'часа', 'часов'])}`
    }
    val /= 24
    if (val < 365) {
        return `${Math.floor(val)} ${declineNumber(Math.floor(val), ['день', 'дня', 'дней'])}`
    }
    val /= 365
    return `${Math.floor(val)} ${declineNumber(Math.floor(val), ['год', 'года', 'лет'])}`
}

export function textAgoExt(ts: number, sep: string = ' ') {
    const dt = dtDate(ts)
    const cur = new Date()
    const info = getDateDifference(dt, cur)
    if (info.years > 0) {
        const s = `${info.years} ${declineNumber(Math.floor(info.years), ['год', 'года', 'лет'])}`
        if (info.months == 0) {
            return s
        }
        return `${s}${sep}${info.months} ${declineNumber(Math.floor(info.months), ['месяц', 'месяца', 'месяцев'])}`
    }
    if (info.months > 0) {
        const s = `${info.months} ${declineNumber(Math.floor(info.months), ['месяц', 'месяца', 'месяцев'])}`
        if (info.days == 0) {
            return s
        }
        return `${s}${sep}${info.days} ${declineNumber(Math.floor(info.days), ['день', 'дня', 'дней'])}`
    }
    return textAgo(ts)
}

function getDateDifference(date1: Date, date2: Date) {
    let months = date2.getMonth() - date1.getMonth() + 12 * (date2.getFullYear() - date1.getFullYear())
    const years = Math.floor(months / 12)
    months %= 12
    let days = date2.getDate() - date1.getDate()
    if (days < 0) {
        months--
        const lastDayOfMonth = new Date(date2.getFullYear(), date2.getMonth(), 0).getDate()
        days += lastDayOfMonth
    }
    return { years, months, days }
}