import React from 'react'
import { createBrowserRouter } from 'react-router-dom'
import { LoginPage, loader as loginPageLoader } from '../pages/LoginPage'
import { ClientMigrationPage, loader as clientMigrationPageLoader } from '../pages/ClientMigrationPage'
import { urls } from '../core/urls'
import { ClientCreatePage } from '../pages/ClientCreatePage'
import { loader as logoutPageLoader } from '../pages/LogoutPage'
import { InitializeDatabasePage, loader as initialDatabaseLoader } from '../pages/InitializeDatabasePage'
import { SuspenseAwait } from '../component/SuspenseAwait'
import { ChildrenPage } from '../pages/Client/ChildrenPage'
import { EditBabyPage } from '../pages/Client/EditBabyPage'
import { MealtimePage } from '../pages/Client/MealtimePage'
import { ConditionPage } from '../pages/Client/ConditionPage'
import { MealtimeEditPage } from '../pages/Client/MealtimeEditPage'
import { appMainPageLoader, authorizedLoader, babyIdLoader,
    conditionDtLoader, foodIdLoader, mealtimeIdLoader, smartLoader } from '../pages/loader'
import { FoodsPage } from '../pages/Client/FoodsPage'
import { FoodEditPage } from '../pages/Client/FoodEditPage'
import { ConditionListPage } from '../pages/Client/ConditionListPage'
import { ConditionEditPage } from '../pages/Client/ConditionEditPage'
import { ClientErrorPage } from '../pages/Client/ClientErrorPage'
import { AgreementsPage } from '../pages/Client/AgreementsPage'
import { SelectEnterPage } from '../pages/Client/Main/SelectEnterPage'
import { CreateAccountInformation } from '../pages/Information/CreateAccountInformation'

export default createBrowserRouter([
    {
        path: urls.childrenPage,
        element: <ChildrenPage />,
        errorElement: <ClientErrorPage />,
        loader: smartLoader(undefined, authorizedLoader),
    }, {
        path: urls.editBabyPage(':babyId'),
        element: <EditBabyPage />,
        errorElement: <ClientErrorPage />,
        loader: smartLoader(undefined, authorizedLoader, babyIdLoader),
    }, {
        path: urls.mealtimePage(':babyId'),
        element: <MealtimePage />,
        errorElement: <ClientErrorPage />,
        loader: smartLoader(undefined, authorizedLoader, babyIdLoader),
    }, {
        path: urls.editMealtimePage(':babyId', ':mealtimeId'),
        element: <MealtimeEditPage />,
        errorElement: <ClientErrorPage />,
        loader: smartLoader(undefined, authorizedLoader, babyIdLoader, mealtimeIdLoader),
    }, {
        path: urls.conditionPage(':babyId'),
        element: <ConditionPage />,
        errorElement: <ClientErrorPage />,
        loader: smartLoader(undefined, authorizedLoader, babyIdLoader),
    }, {
        path: urls.listConditionsPage(':babyId'),
        element: <ConditionListPage />,
        errorElement: <ClientErrorPage />,
        loader: smartLoader(undefined, authorizedLoader, babyIdLoader),
    }, {
        path: urls.editConditionsPage(':babyId', ':conditionDt'),
        element: <ConditionEditPage />,
        errorElement: <ClientErrorPage />,
        loader: smartLoader(undefined, authorizedLoader, babyIdLoader, conditionDtLoader),
    }, {
        path: urls.foodsPage(),
        element: <FoodsPage />,
        errorElement: <ClientErrorPage />,
        loader: smartLoader(undefined, authorizedLoader),
    }, {
        path: urls.editFoodPage(':foodId'),
        element: <FoodEditPage />,
        errorElement: <ClientErrorPage />,
        loader: smartLoader(undefined, authorizedLoader, foodIdLoader),
    }, {
        path: urls.editFoodPage(':foodId', ':babyId'),
        element: <FoodEditPage />,
        errorElement: <ClientErrorPage />,
        loader: smartLoader(undefined, authorizedLoader, foodIdLoader),
    }, {
        path: urls.foodsPage(':babyId'),
        element: <FoodsPage />,
        errorElement: <ClientErrorPage />,
        loader: smartLoader(undefined, authorizedLoader, babyIdLoader),
    }, {
        path: urls.createUserPage,
        element: <ClientCreatePage />,
    }, {
        path: urls.migrationPage,
        element: <SuspenseAwait Component={ClientMigrationPage} />,
        loader: clientMigrationPageLoader,
    }, {
        path: urls.initializeDatabase,
        element: <InitializeDatabasePage />,
        errorElement: <ClientErrorPage />,
        loader: initialDatabaseLoader,
    },
    {
        path: urls.selectEnterPage,
        element: <SelectEnterPage />,
        loader: appMainPageLoader,
    },
    {
        path: urls.logoutPage,
        loader: logoutPageLoader,
    }, {
        path: urls.loginPage,
        element: <LoginPage />,
        loader: loginPageLoader,
    }, {
        path: urls.agreementsPage,
        element: <AgreementsPage />,
        // loader: loginPageLoader,
    }, {
        path: urls.createAccountInformationPage,
        element: <CreateAccountInformation/>,
    }, {
        path: '*',
        element: <ClientErrorPage />,
    },
])