import Dexie, { IndexableType, Table } from 'dexie'
import { Food } from './db_food'
import { wait } from './helper'

export interface ITable {

}
export interface IBaby extends ITable {
    id: number
    name: string
    boy: number
}
export interface IFood extends ITable {
    dt: number
    name: string
    lu: number
}
export interface ICondition extends ITable {
    dt: number
    b: number
}
export interface ILastFood extends ITable {
    id?: number
    dt: number
    fdt: number
}
export interface IMealtimeType extends ITable {
    id: number
    on: number
}
export interface IMealtime extends ITable {
    id: number
    dt: number
    b: number
    d: number
}

export class MySubClassedDexie extends Dexie {
    // 'friends' is added by dexie when declaring the stores()
    // We just tell the typing system this is the case
    babies!: Table<IBaby>
    foods!: Table<IFood>
    conditions!: Table<ICondition>
    lastFoods!: Table<ILastFood>
    mealtimeTypes!: Table<IMealtimeType>
    mealtimes!: Table<IMealtime>

    constructor() {
        super('diet-database', {
            autoOpen: true,
        })
        // this.delete().then()
        this.version(13).stores({
            babies: 'id', // Primary key and indexed props
            foods: 'dt, name, lu', // Primary key and indexed props
            conditions: 'dt, b, [dt+b]', // Primary key and indexed props
            lastFoods: '++id, dt, fdt', // Primary key and indexed props
            mealtimeTypes: 'id, on', // Primary key and indexed props
            mealtimes: 'id, dt, b, d, [id+b], [dt+b]', // Primary key and indexed props
        })
    }


    public async clearTables() {
        await this.babies.clear()
        await this.foods.clear()
        await this.conditions.clear()
        await this.lastFoods.clear()
        await this.mealtimeTypes.clear()
        await this.mealtimes.clear()
        return true
    }
}

export const dbManager = function() {
    const db = new MySubClassedDexie()
    const man = {
        db,
        deleteDB: async () => {},
    }
    man.deleteDB = async () => {
        man.db.close()
        await Dexie.delete('diet-database')
        man.db = new MySubClassedDexie()
    }
    return man
}()

export function saveTableWithProgress(name: string, table: Table, data: any[]) {
    return (fxProgress: (name: string, p: number) => void) => {
        return new Promise((resolve, reject) => {
            fxProgress(name, 0)
            setTimeout(async () => {
                try {
                    await table.clear()
                    for (let i = 0; i < data.length; i += 100) {
                        const l = i + 100 > data.length ? data.length : i + 100
                        const bulk = data.slice(i, l)
                        await table.bulkAdd(bulk)
                        fxProgress(name, l / data.length)
                    }
                    await wait(0)
                    resolve(data.length)
                } catch (e) {
                    reject(e)
                }
            }, 0)

        })
    }
}

export type DbTableName = 'babies'| 'foods'| 'conditions'| 'lastFoods'| 'mealtimeTypes'| 'mealtimes'
const allDbTables: DbTableName[] = [
    'babies', 'foods', 'conditions', 'lastFoods', 'mealtimeTypes', 'mealtimes',
]
export async function getTablesCounts(): Promise<Record<DbTableName, number>> {
    const p = allDbTables.map(table => {
        if ((dbManager.db as any)[table] === undefined) {
            const t = {} as Record<DbTableName, number>
            t[table] = -1
            return t
        }
        return (dbManager.db as any)[table].count().then((count: number) => {
            const t = {} as Record<DbTableName, number>
            t[table] = count
            return t
        })
    })
    const res = await Promise.all(p)
    return res.reduce((res, one) => {
        res = { ...res, ...one }
        return res
    }, {} as Record<DbTableName, number>)
}


export async function getTablesDataToSave() {
    const p = allDbTables.map(async table => {
        if (dbManager.db[table] === undefined) {
            return Promise.reject('no_table')
        }
        const t: Record<DbTableName, any[]> = {} as any
        t[table] = await dbManager.db[table].toArray()
        return t
    })
    const res = await Promise.all(p)
    return res.reduce((res, one) => {
        res = { ...res, ...one }
        return res
    }, {} as Record<DbTableName, any[]>)
}