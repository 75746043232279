import React, { ReactNode } from 'react'
// import cl from './index.module.scss';


type Props = {
    children: ReactNode
}

export const ActionButtons: React.FunctionComponent<Props> = (props) => {
    return (
        <div style={{
            position: 'sticky', bottom: 0,
            pointerEvents: 'none',
            display: 'flex', justifyContent: 'flex-end' }}>
            {props.children}
        </div>
    )
}
