import React, { FunctionComponent } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useBaby } from '../../../hooks/useBaby'
import { cls, dtDate, minStringNumber } from '../../../core/helper'
import { urls } from '../../../core/urls'
import { Baby } from '../../../core/db_baby'
import { useLiveQuery } from 'dexie-react-hooks'
import { dbManager } from '../../../core/db'
import { Condition } from '../../../core/db_condition'
import { BottomMenu } from '../../../component/BottomMenu'
import { Page, PageMain } from '../../../component/Layout/Page'
import { dtToTimeDate } from '../MealtimePage/DateComp'
import { textAgoExt } from '../../../component/TextAgo'
import { SimpleButton } from '../../../component/Form/Button/SimpleButton'
import { HeadTitle } from '../../../component/Head/HeadTitle'
import cl from './index.module.scss'



export const ConditionPage: React.FC = () => {
    const navigate = useNavigate()
    const [baby] = useBaby()
    if (!baby) {
        return null
    }
    return (
        <Page>
            <Page.Main>
                <PageMain.Title>
                    <HeadTitle />
                </PageMain.Title>
                <PageMain.Content>
                    <div className={cl.condition}>
                        <UserInfo baby={baby} />
                        <UserCondition baby={baby} />
                    </div>
                </PageMain.Content>
            </Page.Main>
            <Page.Footer key="button-menu">
                <BottomMenu key="button-menu" selected='condition' />
            </Page.Footer>
        </Page>
    )
}


const UserInfo: FunctionComponent<{ baby: Baby }> = ({ baby }) => {
    const navigate = useNavigate()
    const location = useLocation()
    const edit = () => {
        navigate(urls.editBabyPage(baby.id) + '#' + location.pathname)
    }
    return (
        <div>
            <h1 className={cl.title}>Информация</h1>
            <div {...cls(
                'font-kurale',
                cl.info,
            )}>
                <div className={cl.info__ava}>
                    <img src={`/img/baby_${baby.boy ? 'boy' : 'girl'}.jpg`} height={80} width={48} />
                </div>
                <LineInfo title='Псевдоним:' value={baby.name} ext=''/>
                {/* <LineInfo title='День рождения:' value={birthDay(baby.bday)} ext=''/> */}
                <LineInfo title='Возраст:' value={age(baby.bday)} ext='' extClass={'baseline'}/>
                <div className={cl.info__action} style={{ textAlign: 'center', display: 'flex', justifyContent: 'center' }}>
                    <SimpleButton type='transparent' click={edit}>Редактировать</SimpleButton>
                </div>
            </div>
        </div>
    )
}

const UserCondition: FunctionComponent<{ baby: Baby }> = ({ baby }) => {
    const navigate = useNavigate()
    const condition: Condition | undefined = useLiveQuery(() => dbManager.db.conditions.where('b').equals(baby.id).last()) as Condition
    const goListConditions = () => {
        baby && navigate(urls.listConditionsPage(baby.id))
    }
    return (
        <>
            <div>
                <h1 className={cl.title}>Анализ состояния</h1>
                <div className={cl.state + ' font-kurale'}>
                    {
                        condition ? (
                            <>
                                {/* <LineInfo title='BabyId:' value={condition.b} ext=''/> */}
                                <LineInfo title='Рост:' value={condition.g} ext='см'/>
                                <LineInfo title='Вес:' value={condition.w} ext='кг'/>
                                <LineInfo title='Кол-во ФА:' value={condition.fa} ext='мг'/>
                                <LineInfo title='Потребность ФА:' value={condition.faNeed} ext='мг'/>
                                <LineInfo title='Примечание:' value={condition.ct} ext='' extClass={'simple-size'}/>
                                <LineInfo title='Введено:' value={`${age(condition.dt)} назад`} ext='' extClass={'simple-size'}/>

                            </>
                        ) : undefined
                    }
                    <div style={{ textAlign: 'center', display: 'flex', justifyContent: 'center' }}>
                        <SimpleButton type='transparent' click={goListConditions}>Перейти к записям<br/>состояния</SimpleButton>
                    </div>
                </div>
            </div>
            <UserNeeds cond={condition}/>
        </>
    )
}

const UserNeeds: React.FC<{ cond?: Condition }> = ({ cond }) => {
    if (!cond || (!cond.p && !cond.f && !cond.c && !cond.e)) {
        return null
    }
    return (
        <>
            <h1 className={cl.title}>Суточная норма БЖУ</h1>
            <div className={cl.state + ' font-kurale'} style={{ paddingBottom: 12 }}>
                {
                    cond.e
                        ? <LineInfo title='Калории:' value={minStringNumber(cond.e.toFixed(1))} ext='ккал'/>
                        : undefined
                }
                {
                    cond.p
                        ? <LineInfo title='Белки:' value={fromToText(cond.p, cond.p2)} ext='г'/>
                        : undefined
                }
                {
                    cond.f
                        ? <LineInfo title='Жиры:' value={fromToText(cond.f, cond.f2)} ext='г'/>
                        : undefined
                }
                {
                    cond.c
                        ? <LineInfo title='Углеводы:' value={fromToText(cond.c, cond.c2)} ext='г'/>
                        : undefined
                }
                <LineInfo title='Введено:' value={`${age(cond.dt)} назад`} ext='' extClass={'simple-size'}/>
            </div>
        </>
    )
}

const LineInfo: FunctionComponent<{
    title: string, value: any, ext: string,
    extClass?: 'simple-size' | 'baseline' }> = ({ title, value, ext, ...props }) => {
        return (
            <div {...cls(
                cl.li,
                props.extClass !== undefined ? cl[`li--${props.extClass}`] : '',
            )}>
                <div {...cls(cl.li__title)}>{title}</div>
                <div {...cls(cl.li__value)}>{value}{ext ? ` ${ext}` : ''}</div>
            </div>
        )
    }

function fromToText(a?: number, b?: number): string {
    if (a === undefined) {
        return '-'
    }
    if (b === undefined) {
        return minStringNumber(a.toFixed(1))
    }
    return `${minStringNumber(a.toFixed(1))} – ${minStringNumber(b.toFixed(1))}`
}

function birthDay(ts?: number): string {
    if (!ts) {return '-'}
    const dt = dtDate(ts)
    const info = dtToTimeDate(ts)
    return info.date + '.' + dt.getFullYear()
}

function age(ts?: number): string {
    if (!ts) {return '-'}
    return textAgoExt(ts, ' и ')
}