import { useCallback, useEffect, useState } from 'react'
import { DbTableName, getTablesCounts } from '../core/db'


export function useTablesCounts(): [
    Record<DbTableName, number>,
    (() => void)
    ] {
    const [info, setInfo] = useState({} as Record<DbTableName, number>)
    const [reload, setReload] = useState(1)
    useEffect(() => {
        getTablesCounts().then((res) => {
            setInfo(res)
        })
    }, [reload])
    const update = useCallback(() => {
        setReload(r => r + 1)
    }, [])
    return [info, update]
}