import React, { useCallback, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { urls } from '../core/urls'
import { logout } from '../core/auth'
import { SimpleButton } from './Form/Button/SimpleButton'


export type RequestError = {
    error: number
    message: string
    detail?: string
    ts?: number
}

type Props = {
    error?: RequestError
    onClick?: (some?: any) => void
    resetError: (err?: RequestError) => void
}

export const SmartError: React.FunctionComponent<Props> = ({ onClick, error, ...props }) => {
    const navigate = useNavigate()
    const r = useRef(0 as any)
    useEffect(() => {
        if (r.current === 0 && error) {
            r.current += 1
            r.current = setTimeout(() => {
                props.resetError(error)
            }, 10000)
        }
    }, [])
    const onLogin = useCallback(() => {
        logout()
        navigate(urls.loginPage)
        onClick && onClick('home')
    }, [navigate, onClick])
    const onLogout = useCallback(() => {
        navigate(urls.logoutPage)
        onClick && onClick('logout')
    }, [navigate, onClick])
    const onAgreements = useCallback(() => {
        navigate(urls.agreementsPage)
        onClick && onClick('agreements')
    }, [navigate, onClick])
    if (!error) {
        return null
    }
    const close = () => {
        if (!onClick && error) {
            clearTimeout(r.current)
        }
        !onClick && error && props.resetError(error)
    }
    return (
        <div role="alert" className="alert alert-error mt-4 md:mt-2 text-left text-sm md:block"
            style={{ background: 'white',
                border: '1px solid gray', borderRadius: 8,
                margin: 8, padding: 8, fontSize: '80%' }}
            onClick={close}
        >
            <p style={{ fontStyle: 'italic', marginTop: 0 }}>
                <span style={{ fontWeight: 'bold' }}>{error.error}</span> {error.message}
            </p>
            {error.detail ? <div className='text-accent'>{error.detail}</div> : undefined}
            {
                error.error === 403 || error.error === 401 ? (
                    <div style={{ marginTop: 12 }}>
                        <SimpleButton size='small' type='success' click={onLogin} >Авторизоваться</SimpleButton>
                    </div>
                ) : undefined
            }
            {
                error.error === 409 ? (
                    <div style={{ marginTop: 12 }}>
                        <SimpleButton size='small' type='success' click={onLogout} >Выйти</SimpleButton>
                    </div>
                ) : undefined
            }
            {
                error.error === 451 ? (
                    <div style={{ marginTop: 12 }}>
                        <SimpleButton size='small' type='success' click={onAgreements} >Посмотреть соглашения</SimpleButton>
                    </div>
                ) : undefined
            }

        </div>
    )
}
