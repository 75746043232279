import React, { FunctionComponent } from 'react'
import styles from './index.module.scss'

type Props = {
    className?: string
    value: string
    onChange: (value: string) => void
    autofocus?: boolean
    placeholder?: string
    disabled?: boolean
}

export const Input: FunctionComponent<Props> = (props) => {
    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        props.onChange(e.target.value)
    }
    return (
        <input className={props.className || ''} value={props.value}
            placeholder={props.placeholder}
            autoFocus={props.autofocus === true}
            onChange={onChange} disabled={props.disabled}/>
    )
}


export const Password: FunctionComponent<Props> = (props) => {
    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        props.onChange(e.target.value)
    }
    return (
        <input className={styles.class} value={props.value} onChange={onChange} type='password' disabled={props.disabled}/>
    )
}