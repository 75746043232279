import { ApiRequest } from './api'
import { curTime } from './helper'

export function authorization(login: string, pass: string): Promise<{ token: string, unix_expired: number }> {
    return ApiRequest({
        method: 'post',
        url: '/api/auth',
        data: {
            login,
            pass,
        },
        // headers: { 'Authorization': `Bearer ${session_uuid}` }
    })
}

export type Agreement = {
    date_unix_ts: number
    title: string
    content: string
    acceptance_text: string
}

export type Acceptance = Agreement & {
    accepted_unix_ts: number
}

export type TermsAcceptance = {
    start_reading_unix_ts: number
    accepted_agreements: Agreement[]
}
export function getTermsAgreements() {
    return ApiRequest<{result: string, agreements: Agreement[]}>({
        method: 'post',
        url: '/api/client/terms-agreements',
    })
}
export function sendTermsAcceptance(acceptance: TermsAcceptance) {
    return ApiRequest<{result: string}>({
        method: 'post',
        url: '/api/client/terms-acceptance',
        data: acceptance,
    })
}

export function clientVersion() {
    return ApiRequest<{version: string}>({
        method: 'post',
        url: '/api/version',
    })
}

export function clientLive() {
    return ApiRequest<{status: string, unix_expired?: number}>({
        method: 'post',
        url: '/api/client/live',
    })
}

export function authorized(data: { token: string, unix_expired: number }) {
    localStorage.setItem('session_uuid', data.token)
    setExpiredTime(data.unix_expired)
}

export function setExpiredTime(unix_expired: number) {
    localStorage.setItem('session_expired', `${unix_expired}`)
}

export function setInAdvanceRequestTime(unix_time: number) {
    localStorage.setItem('session_in_advance_request', `${unix_time}`)
}

export function getInAdvanceRequestTime(): number | undefined {
    const t = localStorage.getItem('session_in_advance_request')
    if (t == null) {
        return undefined
    }
    const x = parseInt(t)
    return !x ? undefined : x
}

export type authCheckErrorType = '' | 'no_session' | 'session_expired' | 'soon_session_expired'
export function checkAuthorized(): [boolean, authCheckErrorType] {
    const uuid = localStorage.getItem('session_uuid')
    const expired = localStorage.getItem('session_expired')
    if (!uuid || !expired) {
        return [false, 'no_session']
    }
    const tsExpired = parseInt(expired)
    if (!tsExpired) {
        return [false, 'no_session']
    }
    if (curTime() > tsExpired) {
        return [false, 'session_expired']
    }
    if (curTime() + 60 * 60 * 20 > tsExpired) {
        return [false, 'soon_session_expired']
    }
    return [true, '']
}


export type ClientType = 'async' | 'local'
export type ClientVersion = 'new' | 'v1' | 'v2'
function toClientType(str: string): ClientType | undefined {
    if (['async', 'local'].includes(str)) {
        return str as ClientType
    }
    return undefined
}
function toClientVersion(str: string): ClientVersion | undefined {
    if (['new', 'v1', 'v2'].includes(str)) {
        return str as ClientVersion
    }
    return undefined
}
export function setClientVersion(type: ClientType | undefined, version: ClientVersion) {
    if (!['new', 'v1', 'v2'].includes(version)) {
        return
    }
    let clientType = type
    if (clientType === undefined) {
        if (checkAuthorized()[0]) {
            clientType = 'async'
        } else {
            clientType = 'local'
        }
    }
    localStorage.setItem('version', `${clientType}-${version}`)
}

export function getClient(): { type: ClientType, version: ClientVersion} | undefined {
    const v = localStorage.getItem('version')
    if (!v) {
        return undefined
    }
    const arr = v?.split('-')
    if (arr?.length !== 2) {
        return undefined
    }
    const ty = toClientType(arr[0])
    const ve = toClientVersion(arr[1])
    if (!ty || !ve) {
        return undefined
    }
    return {
        type: ty,
        version: ve,
    }
}

export function logout() {
    localStorage.removeItem('session_uuid')
    localStorage.removeItem('session_token')
    localStorage.removeItem('session_expired')
    localStorage.removeItem('session_in_advance_request')
    localStorage.removeItem('version')
}