import React, { useEffect, useMemo, useState } from 'react'
import styles from './index.module.scss'
import { Baby } from '../../core/db_baby'
import { BabyNeedStatistics, babyNeedsStatisticsInDay } from '../../core/baby_info'
import { useLiveQuery } from 'dexie-react-hooks'
import { cls, isDev } from '../../core/helper'
import { useNavigate } from 'react-router-dom'
import { urls } from '../../core/urls'
import { TargetsTracker } from './NutTargets'
import { NetLastDays } from './NetLastDays'
import { SwipeEventData, useSwipeable } from 'react-swipeable'
import { Nutrients } from '../../core/db_food'

type Props = {
    baby?: Baby
    onClick?: (baby: Baby) => void
    disable?: boolean
    tsInDay?: number
    selectedItem?: number
    includePlanning?: boolean
    excludeMealtimes?: number[]
    additionNutrients?: Nutrients
}

export const BabyItem: React.FC<Props> = (props) => {
    const navigate = useNavigate()
    const info = useLiveQuery(() => {
        return babyNeedsStatisticsInDay(
            props.baby?.id, props.tsInDay,
            props.includePlanning, props.excludeMealtimes || [],
        )
    }, [props.baby, props.tsInDay])
    const stats: BabyNeedStatistics | undefined = useMemo(() => {
        if (!info) {
            return undefined
        }
        const s = {
            ...info,
        }
        if (info?.lastDay) {
            s.lastDay = {
                dt: info.lastDay.dt,
                nutrients: {
                    p: (info.lastDay.nutrients.p || 0) + (props.additionNutrients?.p || 0),
                    f: (info.lastDay.nutrients.f || 0) + (props.additionNutrients?.f || 0),
                    c: (info.lastDay.nutrients.c || 0) + (props.additionNutrients?.c || 0),
                    e: (info.lastDay.nutrients.e || 0) + (props.additionNutrients?.e || 0),
                    fa: (info.lastDay.nutrients.fa || 0) + (props.additionNutrients?.fa || 0),
                },
            }
        }
        return s
    }, [info, props.additionNutrients])

    const onClick = () => {
        if (props.disable) {
            return
        }
        props.baby && props.onClick ? props.onClick(props.baby) : navigate(urls.childrenPage)
    }
    return (
        <div className={styles.class + ' font-roboto font-13'}>
            <div className={styles.class__img} onClick={onClick}>
                {
                    props.baby
                        ? <img src={`/img/baby_${props.baby.boy ? 'boy' : 'girl'}.jpg`} height={80} width={48} />
                        : undefined
                }
            </div>
            <div className={styles.class__content}>
                <div className={styles.class__content_title} onClick={onClick}>
                    <Nickname baby={props.baby} />
                </div>
                <div className={styles.class__content_some}>
                    <Content onClick={onClick} selectedItem={props.selectedItem || 0}
                        elements={[
                            <NetLastDays key="last-days" info={stats} />,
                            <TargetsTracker key="target-track" babyId={props.baby?.id} stat={stats} />,
                        ]} />
                </div>
            </div>
        </div>
    )
}

const Nickname: React.FC<{ baby?: Baby }> = (props) => {
    return (
        <div {...cls(styles.class__user_name, 'font-kurale', 'font-18')}>{props.baby?.name || '-'}</div>
    )
}

type ContentProps = {
    selectedItem: number
    elements: any[]
    onClick: () => void
}

const Content: React.FC<ContentProps> = (props) => {
    const [selectedItem, setSelectedItem] = useState(props.selectedItem)
    useEffect(() => {
        setSelectedItem(props.selectedItem)
    }, [props.selectedItem])
    const handlers = useSwipeable({
        onSwipedLeft: (e: SwipeEventData) => {
            setSelectedItem(v => v + 1 < props.elements.length ? v + 1 : v)
        },
        onSwipedRight: (e: SwipeEventData) => {
            setSelectedItem(v => v - 1 >= 0 ? v - 1 : 0)
        },
    })
    const onClick = () => {
        if (isDev()) {
            setSelectedItem(v => v + 1 < props.elements.length ? v + 1 : 0)
        } else {
            props.onClick()
        }
    }
    const oneEl = 100 / props.elements.length
    return (
        <div {...handlers} onClick={onClick} style={{
            width: `${100 * props.elements.length}%`,
            height: '100%',
            top: 0,
            position: 'absolute',
        }}>
            {
                props.elements.map((one, i) => {
                    return (
                        <div key={one.key} style={{
                            position: 'absolute',
                            transition: 'left 0.15s ease',
                            left: `${oneEl * i - oneEl * selectedItem}%`,
                            top: 0,
                            width: `${oneEl}%`,
                            height: '100%',
                            overflow: 'hidden',
                        }}>
                            {one}
                        </div>
                    )
                })
            }
        </div>
    )
}