import React, { FunctionComponent, useEffect, useState } from 'react'
import { MealtimeTypeManager } from '../../../../hooks/useMealtimeTypes'
import { MealtimeType } from '../../../../core/db_mealtime_type'
// import cl from './index.module.scss';


type Props = {
    dt: number
    mts: Record<number, MealtimeType>
    change: (dt: number, typeId: number) => void
    disable: boolean
}

export const MealtimeTime: FunctionComponent<Props> = (props) => {
    const [value, setValue] = useState(MealtimeTypeManager.dtToTimeString(props.dt))
    useEffect(() => {
        setValue(MealtimeTypeManager.dtToTimeString(props.dt))
    }, [props.dt])
    const changeTime = (e: React.ChangeEvent<HTMLInputElement>) => {
        const v = e.target.value
        const dt = MealtimeTypeManager.changeTime(props.dt, v)
        if (dt) {
            const typeId = MealtimeTypeManager.calcTypeByTime(dt, props.mts).id
            props.change(dt, typeId)
        }
        setValue(v)
    }
    return (
        <input type='time' value={value} disabled={props.disable} onChange={changeTime}/>
    )
}
