import React, { FunctionComponent } from 'react'
import { MealtimeType } from '../../../../core/db_mealtime_type'
import { changeDayMealtimeType } from '../../../../core/mealtimeTypes'
import cl from './index.module.scss'


type Props = {
    dt: number
    mts: Record<number, MealtimeType>
    disable: boolean
    type?: number
    change: (dt: number, typeId: number) => void
}

export const SelectMealtimeType: FunctionComponent<Props> = (props) => {
    const change = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const v = e.target.value
        const mt = props.mts[parseInt(v)]
        const ts = changeDayMealtimeType(props.dt, mt, props.mts)
        props.change(ts, mt.id)
    }
    return (
        <div className={cl.select}>
            <select className={cl.select__input} style={{ width: '100%', height: 24 }}
                value={props.type} disabled={props.disable} onChange={change}>
                {
                    Object.keys(props.mts).map(k => {
                        return (
                            <option key={k} value={k}>{props.mts[parseInt(k)].n}</option>
                        )
                    })
                }
            </select>
        </div>
    )
}


export type OptionType<T = any> = {
    typeId: number
    text: string
    value?: T
}
export type SelectProps = {
    change: (item: OptionType) => void
    typeId?: number
    options: OptionType[]
    disable: boolean

}
export const Select: React.FC<SelectProps> = (props) => {
    const change = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const v = e.target.value
        const id = parseInt(v)
        const item = props.options.find(one => one.typeId === id)
        item && props.change(item)
    }
    return (
        <div className={cl.select}>
            <select className={cl.select__input} style={{ width: '100%', height: 24 }}
                value={props.typeId} disabled={props.disable} onChange={change}>
                {
                    props.options.map(one => {
                        return <option key={one.typeId} value={one.typeId}>{one.text}</option>
                    })
                }
            </select>
        </div>
    )
}