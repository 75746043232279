import { LastAndFood } from '../hooks/useFood'
import { Condition } from './db_condition'
import { Food, IngredientFood, Nutrients } from './db_food'
import { Mealtime, MealtimeDish } from './db_mealtime'
import { ConditionDraft, FoodDraft, ItemDraft, MealtimeDraft } from './draft'
import { EdibleItem, EdibleItemsManager } from './edibleItemsManager'
import { stableJsonStringify } from './helper'

export abstract class AbstractManager<
    T extends Mealtime | Food | Condition = Mealtime | Food | Condition,
    K extends ItemDraft<any> = MealtimeDraft | FoodDraft | ConditionDraft
    > {
    abstract getEdibleItems(val: T): EdibleItem[]
    abstract applyEdibleItems(val: T): void
    abstract hasDraft(...args: any): boolean

    protected base?: T
    protected current: T
    protected edibleManager!: EdibleItemsManager
    protected draft: K
    protected initId: number

    constructor(initId: number, current: T, draft: K) {
        this.initId = initId
        this.current = current
        this.draft = draft
    }

    public setBase(base?: T) {
        this.base = base
    }

    public setCurrent(current: T) {
        this.current = current
        this.edibleManager.setItems(this.getEdibleItems(current))
    }

    public getCurrent(): T {
        this.applyEdibleItems(this.current)
        return this.current
    }

    public hasChanges() {
        const old = this.stableStringify(this.base || {})
        const newM = this.stableStringify(this.current || {})
        return newM !== old
    }

    public async addFood(laf: LastAndFood) {
        this.edibleManager.addItem(EdibleItemsManager.fromLastAndFood(laf))
    }

    public getTotalWeight(): number {
        return this.edibleManager.getTotalWeight()
    }

    public getTotalNutrients(): Nutrients {
        return this.edibleManager.getTotalNutrients()
    }

    public getDraftId() {
        return this.initId
    }

    public saveDraft(saveInDraft: any = {}) {
        // вдруг мы перезаписываем и не помним, что там было до этого ))
        const item = this.draft.loadItem() || {}
        if (this.current) {
            this.applyEdibleItems(this.current)
        }
        this.draft.saveItem({
            ...item,
            base: this.base,
            current: this.current,
            ...saveInDraft,
        })
    }

    public deleteDraft() {
        this.draft.deleteItem()
    }

    protected stableStringify(obj: any) {
        return stableJsonStringify(obj)
    }
}


export function nutrientsByWeightAndPortions(item: MealtimeDish | IngredientFood): Nutrients {
    let pn = 1
    if ('pn' in item) {
        pn = item.pn
    }
    return {
        p: nutWeight(item.p, item.w, pn),
        f: nutWeight(item.f, item.w, pn),
        c: nutWeight(item.c, item.w, pn),
        fa: nutWeight(item.fa, item.w, pn),
        e: nutWeight(item.e, item.w, pn),
    }
}


function nutWeight(nut100: number, weight: number, portions: number): number {
    if (nut100 === 0 || weight === 0 || portions === 0) {
        return 0
    }
    return nut100 / 100 * weight * portions
}