import React, { ReactNode } from 'react'
import { SimpleButton, SimpleBtnType } from '../Form/Button/SimpleButton'
// import cl from './index.module.scss';


export type BtnSettings = {
    type: SimpleBtnType
    content: ReactNode | string
    action: () => void
    disabled?: boolean
}

type Props = {
    first?: BtnSettings | ReactNode
    second?: BtnSettings | ReactNode
    disabled?: boolean
}

export const DuetButtons: React.FunctionComponent<Props> = ({ first, second, disabled }) => {
    return (
        <div style={{
            display: 'flex',
            justifyContent: 'space-between',
        }}>
            {
                first ? (
                    React.isValidElement(first) ? (
                        first
                    ) : (
                        <SimpleButton type={(first as BtnSettings).type}
                            click={(first as BtnSettings).action}
                            disabled={(first as BtnSettings).disabled || disabled}>{(first as BtnSettings).content}</SimpleButton>
                    )
                ) : <div style={{ minHeight: 32, visibility: 'hidden' }}/>
            }
            <div>&nbsp;</div>
            {
                second ? (
                    React.isValidElement(second) ? (
                        second
                    ) : (
                        <SimpleButton type={(second as BtnSettings).type}
                            click={(second as BtnSettings).action}
                            disabled={(second as BtnSettings).disabled || disabled}>{(second as BtnSettings).content}</SimpleButton>
                    )

                ) : undefined
            }
        </div>
    )
}
