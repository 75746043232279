import React, { ReactNode, useCallback } from 'react'
import { cls } from '../../../core/helper'
import cl from './checkbox.module.scss'


type Props = {
    checkboxId: string
    name: string
    checked: boolean
    change: (checked: boolean) => void
    disabled?: boolean
}

export const CheckboxInput: React.FunctionComponent<Props> = (props) => {
    const onChange = useCallback(() => {
        props.change(!props.checked)
    }, [props.checked, props.change])
    return (
        <>
            <input id={props.checkboxId}
                {...cls(
                    cl.checkbox_input__input,
                )}
                type="checkbox"
                name={props.name}
                checked={props.checked}
                onChange={onChange}
                disabled={props.disabled === true}
            />
            <div className={cl.checkbox_input__ico}/>
        </>
    )
}

export const Checkbox: React.FC<Props & { children: ReactNode} > = ({ children, ...props }) => {
    return (
        <div {...cls(
            cl.check_true,
            props.disabled === true ? cl['check_true--disabled'] : '',
        )}>
            <div className={cl.check_true__input}><CheckboxInput {...props}/></div>
            <label htmlFor={props.checkboxId}>{children}</label>
        </div>
    )
}