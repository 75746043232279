import { RequestError } from '../component/SmartError'
import { ApiError } from './api'

export function cls(...args: string[]): { className: string } {
    return {
        className: args.join(' '),
    }
}

export function isDev(): boolean {
    return process.env.NODE_ENV === 'development'
}

export function roundString(v: number): string {
    if (isNaN(v)) {
        return '0'
    }
    return (Math.round(v * 100) / 100).toString()
}

export function makeOnKeyEnter(callback: () => void) {
    return (e: any) => {
        if (e && (e.key === 'Enter' || e.keyCode === 32)) {
            callback()
        }
    }
}

export function curTime(onlySec?: boolean): number {
    return dtToUnix(new Date(), onlySec)
}

export function dtToUnix(dt: Date, onlySec?: boolean): number {
    const t = dt.getTime() / 1000
    if (onlySec) {
        return Math.floor(t)
    }
    return t
}

export function curTimeLastUpdate(): number {
    return time2lastUpdate(curTime())
}

export function dtDate(dt: number): Date {
    return new Date(Math.round(dt * 1000))
}

export function time2lastUpdate(unix: number): number {
    return Math.floor(unix * 10)
}

export function parseError(err: any): RequestError {
    return {
        error: err.response?.status || err?.status || err?.error,
        message: err.message || `${err.response?.statusText || err?.statusText }`,
        detail: err.detail || `${err.response?.data?.detail || err.response?.data || err.data }`,
    }
}

export function ApiErrorToRequestError(err?: ApiError): RequestError | undefined {
    if (!err) {
        return undefined
    }
    const a = err as RequestError
    a.ts = curTime()
    return a
}

export function wait(msec: number) {
    return new Promise((resolve) => {
        setTimeout(resolve, msec)
    })
}

export const defaultDelay = 250
export function clickDelay(msec: number, fx: (...args: any[]) => void) {
    return function(...args: any[]) {
        setTimeout(() => {
            fx(...args)
        }, msec)
    }
}

export function numberToString(value?: number, fixedNum: number = 1, docWithMaxLen?: number, undefinedString: string = ''): string {
    if (value === undefined) {
        return undefinedString
    }
    if (docWithMaxLen !== undefined && value >= docWithMaxLen) {
        return `${Math.round(value)}`
    }
    return minStringNumber(value.toFixed(fixedNum))

}

export function minStringNumber(value: string): string {
    if (value.indexOf('.') === -1) {
        return value
    }
    while (value.length > 0 && value[value.length - 1] == '0') {
        value = value.substring(0, value.length - 1)
    }
    if (value.length == 0) {
        value = '0'
    }
    if (value[value.length - 1] == '.') {
        value = value.substring(0, value.length - 1)
    }
    return value
}

export const HOUR_SEC = 60 * 60
export const DAY_SEC = 60 * 60 * 24


export function declineNumber(number: number, titles: string[]): string {
    const cases = [2, 0, 1, 1, 1, 2]
    return titles[(number % 100 > 4 && number % 100 < 20) ? 2 : cases[(number % 10 < 5) ? number % 10 : 5]]
}

export function dtToDateString(uts: number): string {
    const dt = dtDate(uts)
    return `${dt.getFullYear()}-${leadZero(dt.getMonth() + 1)}-${leadZero(dt.getDate())}`
}

export function stableJsonStringify(obj: any): string {
    if (typeof obj !== 'object' || obj === null || obj === undefined) {
        return JSON.stringify(obj)
    }

    const keys = Object.keys(obj).sort()
    const sortedObj = keys.reduce((acc: any, key: any) => {
        acc[key] = stableJsonStringify(obj[key])
        return acc
    }, {})

    return JSON.stringify(sortedObj)
}

export function leadZero(n: number | string, l: number = 2) {
    let str = ''
    if (typeof n === 'string') {
        str = n
    } else {
        str = n.toString()
    }
    while (str.length < l) {
        str = '0' + str
    }
    return str
}