import React, { FunctionComponent, useState } from 'react'
import { dtDate, leadZero } from '../../../core/helper'
import cl from './index.module.scss'


type Props = {
    dt: number
}

export const DateComp: FunctionComponent<Props> = (props) => {
    const [info] = useState(dtToTimeDate(props.dt))
    return (
        <div className={cl.dt}>
            <div className={cl.dt__time}>{info.time}</div>
            <div className={cl.dt__day}>{info.day}</div>
            <div className={cl.dt__date}>{info.date}</div>
        </div>
    )
}


export function dtToTimeDate(dts: number) {
    const dt = dtDate(dts)
    const days = ['вс', 'пн', 'вт', 'ср', 'чт', 'пт', 'сб']
    return {
        time: `${leadZero(dt.getHours())}:${leadZero(dt.getMinutes())}`,
        date: `${leadZero(dt.getDate())}.${leadZero(dt.getMonth() + 1)}`,
        day: days[dt.getDay()],
    }
}