import React, { FunctionComponent } from 'react'
import { Condition } from '../../../../core/db_condition'
import { DateComp } from '../DateComp'
import cl from './index.module.scss'
import { minStringNumber } from '../../../../core/helper'


type Props = {
    item: Condition
}

export const ConditionItem: FunctionComponent<Props> = ({ item }) => {
    return (
        <div style={{ position: 'relative' }}>
            <div className={cl.class}>
                <DateComp dt={item.dt} />
                <div className={cl.class__info}>
                    <OneElement title='Вес:' value={item.w.toFixed(1)} ext='кг'/>
                    <OneElement title='Количество ФА:' value={item.fa.toFixed(2)} ext='мг'/>
                    <OneElement title='Рост:' value={item.g.toFixed(1)} ext='см'/>
                    <OneElement title='Потребность ФА:' value={item.faNeed.toFixed(1)} ext='мг'/>
                </div>
            </div>
            <div className={cl.flag }>
                <img src='/img/item-past.png' width={6}/>
            </div>
        </div>
    )
}

const OneElement: FunctionComponent<{title: string, value: string, ext: string}> = ({ title, value, ext }) => {
    const val = minStringNumber(value)
    return (
        <div>
            <span className={cl.class__title}>{title}</span><span className={cl.class__value}>{val}</span>
            <span className={cl.class__ext}>{ext}</span>
        </div>
    )
}
