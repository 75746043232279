import React, { useCallback } from 'react'
import { FormFromToInputProps, FromFromToInput } from '../../FoodEditPage/SimpleFood'
import { FromToNutrientsCondition } from '../../../../core/db_condition'


export const OneFromToInput: React.FC<Omit<FormFromToInputProps, 'change1' | 'change2'> & {
    k1: keyof FromToNutrientsCondition
    k2: keyof FromToNutrientsCondition
    change1: (cond: Partial<FromToNutrientsCondition>) => void
    change2: (cond: Partial<FromToNutrientsCondition>) => void
}> = (props) => {
    const change1 = useCallback((val: number) => {
        const tmp: Partial<FromToNutrientsCondition> = {}
        tmp[props.k1] = val
        props.change1(tmp)
    }, [props.k1, props.change1])
    const change2 = useCallback((val: number) => {
        const tmp: Partial<FromToNutrientsCondition> = {}
        tmp[props.k2] = val
        props.change2(tmp)
    }, [props.k2, props.change2])
    return (
        <FromFromToInput {...props} change1={change1} change2={change2} />
    )

}
