import { useEffect, useRef, useState } from 'react'
import { dbManager } from '../core/db'
import { Food, IngredientFood, emptyNutrients } from '../core/db_food'
import { LastFood } from '../core/db_lastfood'
import { useLoaderData, useLocation } from 'react-router-dom'
import { FoodDraft } from '../core/draft'
import { curTime } from '../core/helper'
import { ApiError } from '../core/api'
import { EdibleItem, EdibleItemsManager } from '../core/edibleItemsManager'
import { AbstractManager } from '../core/abstractManager'
import { BackInfoManager } from './useBackInfo'
import { LastFoodManager } from '../core/lastFood'

export function useFoods() {
    const [manager] = useState(new FoodsManager)
    return manager
}

export function useFood(addError: (err: ApiError) => void):[
    FoodManager | undefined,
    Food | undefined, React.Dispatch<React.SetStateAction<Food | undefined>>
    ] {
    const data = useLoaderData() as { foodId: number }
    const location = useLocation()
    const [curDataId, setCurDataId] = useState(data.foodId)
    const [food, setFood] = useState(undefined as Food | undefined)
    const [manager, setManager] = useState(undefined as FoodManager | undefined)
    const v = useRef(0)
    useEffect(() => {
        if (!food || !manager) {
            return
        }
        manager.setCurrent(food)
    }, [food])
    useEffect(() => {
        if (food && data.foodId === curDataId) {
            return
        }
        setCurDataId(data.foodId)
        v.current += 1
        const version = v.current
        createFoodManager(data.foodId)
            .then(manager => {
                if (version !== v.current) {
                    throw 'version_changed'
                }
                const food = manager.getCurrent()
                if (data.foodId == -1) {
                    food.m = false
                    const name = loadNameFromHash(location.hash)
                    if (name) {
                        food.name = name
                    }
                }
                setFood(food)
                setManager(manager)
            }).catch((err)=> {
                if (err != 'version_changed') {
                    addError(err)
                }
            })
    }, [data])
    return [manager, food, setFood]
}


function loadNameFromHash(hash: string): string | undefined {
    const arr = hash.slice(1).split('&')
    for (let i = 0; i < arr.length; i++) {
        const line = arr[i]
        const els = line.split('=')
        if (els[0] == 'fname') {
            return decodeURIComponent(els[1])
        }
    }
    return undefined
}

export type LastAndFood = {
    lf?: LastFood
    f: Food
}
export class FoodsManager {
    public async getLastAndFoods(from?: number, limit: number = 30): Promise<LastAndFood[]> {
        const lfds = await dbManager.db.lastFoods.orderBy('dt').reverse().offset(from || 0).limit(limit).toArray() as LastFood[]
        const lfKeys = lfds.reduce((r, one) => {
            r[one.fdt] = one
            return r
        }, {} as Record<number, LastFood>)
        const foods = await this.getFoods(lfds.map(one => one.fdt))
        return foods.map(one => {
            return {
                f: one,
                lf: lfKeys[one.dt],
            }
        }).sort(sortLastAndFood)
    }

    public async searchLastAndFood(search: string, from?: number, limit: number = 30): Promise<LastAndFood[]> {
        const regex = searchFoodRegexp(search)
        const foods = await dbManager.db.foods.orderBy('name')
            .filter(one => regex.test(one.name)).offset(from || 0).limit(limit).toArray() as Food[]
        const lastFoods = await this.getLastFoods(foods.map(one => one.dt))
        const lfKeys = lastFoods.reduce((r, one) => {
            r[one.fdt] = one
            return r
        }, {} as Record<number, LastFood>)
        return foods.map(one => {
            return {
                f: one,
                lf: lfKeys[one.dt],
            }
        }).sort(sortLastAndFood)
    }
    private async getFoods(dts: number[]): Promise<Food[]> {
        return await dbManager.db.foods.where('dt').anyOf(dts).toArray() as Food[]
    }
    private async getLastFoods(dts: number[]): Promise<LastFood[]> {
        return await dbManager.db.lastFoods.where('fdt').anyOf(dts).toArray() as LastFood[]
    }
}

export async function createFoodManager(foodId: number): Promise<FoodManager> {
    const draft = new FoodDraft(foodId)
    if (foodId == -1) { // isNew
        const initBabyId = draft.getNextDraftId()
        return new FoodManager(initBabyId, emptyFood())
    }
    if (foodId < -1 || draft.haveDraft()) { // isDraft
        const df = draft.loadItem()
        if (df && df.current) {
            const manager = new FoodManager(foodId, df.current)
            manager.setBase(df.base)
            return manager
        }
    }
    const food = await dbManager.db.foods.where('dt')
        .equals(foodId)
        .first() as Food | undefined
    if (food) {
        const manager = new FoodManager(foodId, food)
        manager.setBase(JSON.parse(JSON.stringify(food)))
        return manager
    }
    return Promise.reject({
        error: -101,
        message: 'No food',
        detail: 'Food не найден',
    } as ApiError)
}

export class FoodManager extends AbstractManager<Food, FoodDraft> {
    constructor(
        initId: number,
        current: Food,
    ) {
        super(initId, current, new FoodDraft(initId))
        this.edibleManager = new EdibleItemsManager(EdibleItemsManager.fromIngredientsList(current.mc))
    }

    public async save(): Promise<number> {
        this.current.name = this.current.name.trim()
        if (this.current.m) {
            this.current.mc = this.edibleManager.getAsIngredients()
        } else {
            this.current.mcw = 0
            this.current.mc = []
            this.edibleManager.setItems([])
        }
        this.actualizeNutrients()
        await this.checkToSave()



        const f = await dbManager.db.foods.where('dt').equals(this.current.dt).first()
        let dt: number = this.current.dt
        if (!f) {
            dt = await dbManager.db.foods.add(this.current) as number
        } else {
            await dbManager.db.foods.put(this.current)
        }
        this.base = JSON.parse(JSON.stringify(this.current))
        await this.updateLastFood()
        return dt
    }

    public hasChanges(): boolean {
        if (this.current.name.length === 0) {
            if (this.current.p + this.current.f + this.current.c + this.current.fa + this.current.e === 0) {
                return false
            }
        }
        return super.hasChanges()
    }

    private async updateLastFood() {
        const mngr = new LastFoodManager(
            EdibleItemsManager.fromIngredientsList([{
                dt: this.current.dt,
                name: this.current.name,
                p: this.current.p,
                f: this.current.f,
                c: this.current.c,
                fa: this.current.fa,
                e: this.current.e,
                w: 0,

            }, ...this.current.mc]),
        )
        return await mngr.save()
    }

    private actualizeNutrients() {
        if (!this.current.m) {
            return
        }
        const nutItemsTotalGram = this.getTotalNutrients()
        const itemsWeight = this.getTotalWeight()
        this.current.mcw = this.current.mcw || itemsWeight
        const productWeight = this.current.mcw
        if (productWeight === 0) {
            const t = emptyNutrients()
            this.current.p = t.p
            this.current.f = t.f
            this.current.c = t.c
            this.current.fa = t.fa
            this.current.e = t.e
            return
        }
        this.current.p = nutItemsTotalGram.p / productWeight * 100
        this.current.f = nutItemsTotalGram.f / productWeight * 100
        this.current.c = nutItemsTotalGram.c / productWeight * 100
        this.current.fa = nutItemsTotalGram.fa / productWeight * 100
        this.current.e = nutItemsTotalGram.e / productWeight * 100
    }

    public changeProductWeight(weight: number) {
        this.current.mcw = weight
        this.actualizeNutrients()
    }

    public changeIngredient(item?: IngredientFood) {
        const items = this.edibleManager.getAsIngredients().map(one => {
            if (one.dt === item?.dt) {
                return item
            }
            return one
        })
        this.edibleManager.setItems(EdibleItemsManager.fromIngredientsList([...items]))
        this.current.mcw = this.getTotalWeight()
        this.actualizeNutrients()
    }

    public deleteIngredient(dt: number) {
        const items = this.edibleManager.getAsIngredients().filter(one => one.dt !== dt)
        this.edibleManager.setItems(EdibleItemsManager.fromIngredientsList([...items]))
        this.actualizeNutrients()
    }

    getEdibleItems(val: Food): EdibleItem[] {
        return EdibleItemsManager.fromIngredientsList(val.mc)
    }

    applyEdibleItems(val: Food): void {
        val.mc = this.edibleManager.getAsIngredients()
    }

    hasDraft(...args: any): boolean {
        if (this.initId < 0) {
            return true
        }
        return this.draft.haveDraft()
    }

    public back(bim: BackInfoManager, defaultUrl: string): string {
        try {
            return bim.back()
        } catch (err) {
            return defaultUrl
        }
    }

    private async checkToSave(): Promise<void> {
        if (this.current.name.length == 0) {
            return Promise.reject({
                error: -30,
                message: 'No name',
                detail: 'Требуется указать имя продукта',
            } as ApiError)
        }
        if (this.current.m && this.current.mc.length == 0) {
            return Promise.reject({
                error: -31,
                message: 'No edible items',
                detail: 'Смесь не должна быть пустой',
            } as ApiError)
        }
        const fn = await dbManager.db.foods.where('name').equalsIgnoreCase(this.current.name).toArray()
        for (let i = 0; i < fn.length; i++) {
            const element = fn[i]
            if (element.dt !== this.current.dt) {
                return Promise.reject({
                    error: -32,
                    message: 'Name already exists',
                    detail: 'Имя продукта уже используется',
                } as ApiError)
            }
        }
    }
}

function emptyFood(): Food {
    return {
        dt: curTime(true),
        name: '',
        p: 0,
        f: 0,
        c: 0,
        fa: 0,
        e: 0,
        lw: 100,
        lu: curTime(true),
        m: false,
        mc: [],
        mcw: 0,
        md5: '',
    }
}

export function searchFoodRegexp(search: string): RegExp {
    return new RegExp('(.*)(' + escapeRegex(search) + ')(.*)', 'i')
}

function escapeRegex(str: string) {
    return str.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&')
}

/** самые последние использованные, а потом новые вверх  */
function sortLastAndFood(one: LastAndFood, two: LastAndFood):number {
    if (!!one.lf && !!two.lf) {
        return two.lf.dt - one.lf.dt
    }
    if (two.lf) {
        return 1
    }
    if (one.lf) {
        return -1
    }
    return two.f.dt - one.f.dt
}