import React, { useEffect, useState } from 'react'
import { OnlyInput, Props } from '.'
import cl from './index.module.scss'


export const Input: React.FunctionComponent<Props & {label: string, labelClass?: string}> = (props) => {
    const [inputId, setInputId] = useState(props.inputId || `${Math.random()}`)
    useEffect(() => {
        setInputId(props.inputId || `${Math.random()}`)
    }, [props.inputId])
    return (
        <>
            <label className={cl.input__label + (props.labelClass ? ` ${props.labelClass}` : '')} htmlFor={inputId}>{props.label}</label>
            <OnlyInput inputId={inputId} {...props}/>
        </>
    )
}
