
export type Food = {
    dt: number
    name: string
    md5: string

    /** @description микстура ли это */
    m: boolean

    /** @description ингредиенты микстуры */
    mc: IngredientFood[]

    /** @description вес готового продукта */
    mcw: number

    /** @description последний использованный вес */
    lw: number

    /** @description время последнего изменения продукта */
    lu: number
} & Nutrients

export type Nutrients = {
    /** @description протеины */
    p: number
    /** @description жиры */
    f: number
    /** @description углеводы */
    c: number
    /** @description ФА */
    fa: number
    /** @description калории */
    e: number
}

export type IngredientFood = {
    dt: number
    name: string
    /** @description вес */
    w: number
} & Nutrients


export function emptyNutrients(): Nutrients {
    return {
        p: 0,
        f: 0,
        c: 0,
        fa: 0,
        e: 0,
    }
}

export function extractNutrients(obj: Partial<Nutrients>): Partial<Nutrients> {
    return {
        p: obj.p,
        f: obj.f,
        c: obj.c,
        fa: obj.fa,
        e: obj.e,
    }

}