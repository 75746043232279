import React, { useEffect, useMemo, useRef, useState } from 'react'
import cl from './index.module.scss'
import { BabyNeedStatistics } from '../../core/baby_info'
import { numberToString } from '../../core/helper'

type TargetType = 'is-fa' | 'normal'
type TargetStatus = 'need-more' | 'normal' | 'need-less'
interface TargetProps {
    name: string;
    targetSymbol?: string
    status?: TargetStatus
    target?: number
    value?: number
    type: TargetType
    children: React.ReactNode
}


export interface TargetsTrackerProps {
    babyId?: number
    tsInDay?: number
    stat?: BabyNeedStatistics
}
export const TargetsTracker: React.FC<TargetsTrackerProps> = ({ stat, ...props }) => {
    const statusEnergy = useMemo(() => {
        if (stat?.pfcaNeeds.e === undefined || stat.lastDay?.nutrients.e === undefined) {
            return 'need-more'
        }
        const min = stat.pfcaNeeds.e - stat.pfcaNeeds.e * 0.1
        const max = stat.pfcaNeeds.e + stat.pfcaNeeds.e * 0.1
        if (stat.lastDay.nutrients.e < min) {
            return 'need-more'
        } else if (stat.lastDay.nutrients.e > max) {
            return 'need-less'
        } else {
            return 'normal'
        }

    }, [stat])
    return (
        <div className={cl.targets}>
            <TargetInterval name='б (г)' value={stat?.lastDay?.nutrients.p}
                from={stat?.pfcaNeeds.p} to={stat?.pfcaNeeds.p2} type='normal' />
            <Target name='ФА (мг)' value={stat?.lastDay?.nutrients.fa} target={stat?.faNeeds} type='is-fa' />
            <TargetInterval name='ж (г)' value={stat?.lastDay?.nutrients.f}
                from={stat?.pfcaNeeds.f} to={stat?.pfcaNeeds.f2} type='normal' />
            <TargetInterval name='у (г)' value={stat?.lastDay?.nutrients.c}
                from={stat?.pfcaNeeds.c} to={stat?.pfcaNeeds.c2} type='normal' />
            <Target name='ккал' value={stat?.lastDay?.nutrients.e} target={stat?.pfcaNeeds.e} type='normal' status={statusEnergy} />
        </div>
    )
}

const CurrentToTarget: React.FC<TargetProps> = (props) => {
    return (
        <div className={cl.target + ' ' + cl[`target--${props.type}`]}>
            <div className={cl.target__title}>{props.name}</div>
            <div className={cl.target__value}>{numberToString(props.target) || '-'}</div>
            <div className={cl.target__view}>{props.children}</div>
            <div className={cl.target__value}>{numberToString(props.value) || '-'}</div>
        </div>
    )
}

const TargetInterval: React.FC<Omit<TargetProps, 'target' | 'children'> & { from?: number, to?: number }> = (props) => {
    const target = useMemo(() => {
        const s = '>'
        const target = props.value !== undefined && props.from !== undefined && props.value < props.from ? props.from : props.to
        let status: TargetStatus = 'need-more'
        if (props.from === undefined || props.value === undefined) {
            status = 'need-more'
        } else {
            if (props.from < props.value) {
                status = 'normal'
            }
            if (props.to && props.to < props.value) {
                status = 'need-less'
            }
        }
        return {
            symbol: s,
            status,
            target,
        }
    }, [props])
    return (
        <CurrentToTarget name={props.name}
            target={target.target}
            targetSymbol={target.symbol}
            value={props.value} type={props.type}>
            <Colba value={props.value} tMin={props.from} tMax={props.to} type={props.type} status={target.status} />
        </CurrentToTarget>
    )
}

const Target: React.FC<Omit<TargetProps, 'children'>> = (props) => {
    return (
        <CurrentToTarget name={props.name}
            target={props.target}
            value={props.value} type={props.type}>
            <Colba value={props.value} tMax={props.target} type={props.type} status={props.status} />
        </CurrentToTarget>
    )
}


interface ColbaProps {
    tMax?: number
    tMin?: number
    value?: number
    type: TargetType
    status?: TargetStatus
}
const Colba: React.FC<ColbaProps> = (props) => {
    const [lines, setLines] = useState(calcLines(props))
    const r = useRef<HTMLDivElement>(null)
    const observer = useRef<ResizeObserver>()
    const updateHeight = () => {
        setLines(calcLines(props, r.current?.clientHeight))
    }
    useEffect(() => {
        updateHeight()
        observer.current = new ResizeObserver(updateHeight)
        if (r.current) {
            observer.current.observe(r.current)
        }
        return () => {
            if (r.current && observer.current) {
                observer.current.unobserve(r.current)
            }
        }
    }, [])
    useEffect(() => {
        updateHeight()
        if (r.current && observer.current) {
            observer.current.unobserve(r.current)
        }
        observer.current = new ResizeObserver(updateHeight)
    }, [props])
    return (
        <div ref={r} className={cl.colba}>
            {
                lines.value ? (
                    <div className={cl.colba__value + ' ' + cl[`colba__value--${props.status || props.type}`]}
                        style={{ bottom: 0, height: lines.value }} />
                ) : undefined
            }
            {
                lines.top ? (
                    <div className={cl.colba__line + ' ' + cl[`colba__line--${props.type}`]} style={{ bottom: lines.top }} />
                ) : undefined
            }
            {
                lines.bottom ? (
                    <div className={cl.colba__line + ' ' + cl[`colba__line--${props.type}`]} style={{ bottom: lines.bottom }} />
                ) : undefined
            }
        </div>
    )
}

interface linesY {
    top?: number
    bottom?: number
    value?: number
    height?: number
}
function calcLines(info: ColbaProps, height?: number): linesY {
    if (!height) {
        return {
            height,
        }
    }
    if (info.value === undefined) {
        return {
            height,
        }
    }
    if ((info.tMin === undefined && info.tMax === undefined) || info.tMax === undefined) {
        return {
            value: Math.round(height / 3 * 10) / 10,
            height,
        }
    }
    const topLine = height - height / 10
    const step = info.tMax > info.value ? topLine / info.tMax : topLine / info.value
    const top = info.tMax * step
    const bottom = info.tMin !== undefined ? info.tMin * step : undefined
    const value = info.value * step
    return {
        top,
        bottom,
        value,
        height,
    }
}