import React, { FunctionComponent, useEffect, useState } from 'react'
import { Baby, babySave } from '../../../core/db_baby'
import { useLoaderData, useLocation, useNavigate } from 'react-router-dom'
import { dbManager } from '../../../core/db'
import { urls } from '../../../core/urls'
import { useBaby } from '../../../hooks/useBaby'
import { useErrors } from '../../../hooks/useErrors'
import { SmartErrorList } from '../../../component/SmartErrorList'
import { Page, PageMain } from '../../../component/Layout/Page'
import { DuetButtons } from '../../../component/ActionButtons/DuetButtons'
import { Radio } from '../../../component/Form/Input/RadioInput/Radio'
import { Input } from '../../../component/Form/Input/TextInput/Input'
import { stableJsonStringify } from '../../../core/helper'
import { HeadTitle } from '../../../component/Head/HeadTitle'
import cl from './index.module.scss'


type StageType = 'wait_user' | 'save' | 'saving'
export const EditBabyPage: React.FunctionComponent = () => {
    const data = useLoaderData() as { babyId?: number }
    const [baby, setBaby] = useBaby()
    const [baseBaby, setBaseBaby] = useState(stableJsonStringify(baby))
    const location = useLocation()
    const navigate = useNavigate()
    const [stage, setStage] = useState('wait_user' as StageType)
    const [errors, addError, resetError] = useErrors()

    useEffect(() => {
        if (baseBaby === undefined) {
            setBaseBaby(stableJsonStringify(baby))
        }
    }, [baby])

    useEffect(() => {
        if (stage != 'save') {
            return
        }
        if (!baby) {
            addError({ error: -1, message: 'Не выбран' })
            setStage('wait_user')
            return
        }
        setStage('saving')
        babySave(baby).then(result => {
            if (data.babyId !== result) {
                navigate(urls.editConditionsPage(baby.id, -1))
            } else {
                cancel()
            }
        }).catch(err => {
            addError(err)
        }).finally(() => setStage('wait_user'))
    }, [stage])
    if (!baby) {
        return null
    }
    const onBoyChange = (boy: number) => {
        setBaby(baby => (baby && { ...baby, boy }))
    }
    const onNameChange = (name: string) => {
        setBaby(baby => (baby && { ...baby, name }))
    }
    const onBdayChange = (date?: number) => {
        // setBaby(baby => (baby && { ...baby, bday: date }))
    }
    const cancel = () => {
        if (!location.hash || location.hash.substring(1).length == 0) {
            navigate(urls.childrenPage, { replace: true })
        } else {
            navigate(location.hash.substring(1), { replace: true })
        }
    }
    const next = () => {
        setStage('save')
    }
    const disableNext = baby.name.length === 0 || stableJsonStringify(baby) === baseBaby
    const disabled = stage != 'wait_user'
    return (
        <Page>
            <Page.Main>
                <PageMain.Title>
                    <HeadTitle />
                </PageMain.Title>
                <PageMain.Content>
                    <div className={cl.class}>
                        <h1>{
                            baby.id !== -1
                                ? 'Редактирование'
                                : baby.boy == 1
                                    ? 'Добавление нового'
                                    : 'Добавление новой' }</h1>
                        <div className={cl.child}>
                            <BoyGirl baby={baby} change={onBoyChange} disabled={disabled}/>
                            <Input label='*Псевдоним:' value={baby.name} change={onNameChange} disabled={disabled}/>
                            <Input type='date' label='Дата рождения:' value={baby.bday} change={onBdayChange} disabled={true}
                                labelClass={cl.disabled_label}/>
                        </div>
                        <SmartErrorList errors={errors} resetError={resetError} />
                    </div>
                    {/* <div>
            Мы не храним персональных данных (не используем cookie и други методы слежения),
            поэтому введённые имя и дата рождения будут храниться у вас в браузере.
             А на стороне сервера вместо имени будут храниться цифры 1, 2, 3...
             Поэтому при повторной авторизации или загрузке данных с сервера на новом устройсте,
             вам потребуется сменить имя с цифры на необходимое вам.
        </div> */}
                </PageMain.Content>
            </Page.Main>
            <Page.Footer key="button">
                <div style={{ padding: 16 }}>
                    <DuetButtons
                        first={{
                            type: 'transparent',
                            action: cancel,
                            content: 'Отменить',
                        }}
                        second={!disableNext ? {
                            type: 'success',
                            action: next,
                            content: baby.id > 0 ? 'Сохранить' : 'Дальше',
                        } : undefined}
                        disabled={disabled}
                    />
                </div>
            </Page.Footer>
        </Page>
    )
}


const BoyGirl: FunctionComponent<{ baby: Baby, change: (boy: number) => void, disabled?: boolean }> = ({ baby, ...props }) => {
    const boy = baby.id === -1 ? 'Мальчика' : 'Мальчик'
    const girl = baby.id === -1 ? 'Девочки' : 'Девочка'
    return (
        <>
            <div className={cl.child__avatar}>
                <img src={`/img/baby_${baby.boy ? 'boy' : 'girl'}.jpg`} height={80} width={48} />
            </div>
            <div className={cl.child__form}>
                <Radio label={boy} radioId='boy-girl-1' group='boy-girl'
                    value={1} selected={baby.boy === 1}
                    change={props.change}
                    disabled={props.disabled}/>
                <Radio label={girl} radioId='boy-girl-2' group='boy-girl'
                    value={0} selected={baby.boy === 0}
                    change={props.change}
                    disabled={props.disabled}/>
            </div>
        </>
    )
}

function initBaby(babyId: number): Baby | Promise<Baby | undefined> {
    if (babyId < 0) {
        return {
            id: -1,
            boy: 1,
            name: '',
        }
    }
    return dbManager.db.babies.where('id').equals(babyId).first()
        .then(r => {
            if (!r) {
                return {
                    id: -1,
                    boy: 1,
                    name: '',
                }
            }
            return r
        })
}
