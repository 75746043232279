import React, { useState } from 'react'
import { SimpleButton } from '../../../component/Form/Button/SimpleButton'
import { Modal } from '../../../component/Modal'
import { DuetButtons } from '../../../component/ActionButtons/DuetButtons'
// import cl from './index.module.scss';


type Props = {
    delete: () => void
    disabled?: boolean
}

export const ModalBack: React.FunctionComponent<Props> = (props) => {
    const [modalVisible, setModalVisible] = useState(false)
    const del = () => {
        setModalVisible(true)
    }
    const cancel = () => {
        setModalVisible(false)
    }
    return (
        <>
            <SimpleButton type='transparent' click={del} disabled={props.disabled}>Назад</SimpleButton>
            <Modal isShow={modalVisible} changed={setModalVisible}>
                <h1 className='font-kurale text-title font-20'
                    style={{ fontWeight: 'normal' }}>Внимание?</h1>
                <p>Обнаружены внесённые, но не сохранённые изменения.</p>
                <p>Вы уверены, что хотите их удалить?</p>
                <div style={{ paddingTop: 64 }}>
                    <DuetButtons
                        first={{
                            type: 'transparent',
                            action: cancel,
                            content: 'Отмена',
                        }}
                        second={{
                            type: 'danger',
                            action: props.delete,
                            content: 'Удалить',
                        }}
                    />
                </div>
            </Modal>
        </>
    )
}
