import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { LastAndFood, createFoodManager } from './useFood'
import { urls } from '../core/urls'
import { createMealtimeManager } from './useMealtime'
import { ApiError } from '../core/api'

type WhereAddType = 'Food' | 'Mealtime'
type BackInfo = {
    b?: number
    m?: number
    f?: number[]
    whereAdd: () => {
        type: WhereAddType
        id?: number
        babyId?: number
    } | undefined
    addDraftFood: (foodId: number) => void
}
export function useBackInfo(): BackInfo | undefined {
    const location = useLocation()
    const [info, setInfo] = useState(undefined as BackInfo | undefined)
    useEffect(() =>{
        const m = new BackInfoManager(location.hash)
        if (m.backInfo !== undefined) {
            setInfo(m.backInfo)
        }
    }, [location])
    return info
}

export class BackInfoManager {
    constructor(
        hash: string,
        private _backInfo?: BackInfo,
    ) {
        if (_backInfo) {
            return
        }
        if (hash.length > 1) {
            const info = hash.substring(1)
                .split('&').reduce((r, one) => {
                    const arr = one.split('=')
                    if (arr.length != 2) {
                        return r
                    }
                    const [key, val] = arr
                    if (key !== 'fname') {
                        r[key] = JSON.parse(val)
                    } else {
                        r[key] = decodeURIComponent(val)
                    }
                    return r

                }, {} as Record<string, any>) as BackInfo
            info.whereAdd = () => {
                if (info.f && info.f.length > 0) {
                    return {
                        type: 'Food',
                        id: info.f[info.f.length - 1],
                        babyId: info.b,
                    }
                }
                if (info.b && info.m) {
                    return {
                        type: 'Mealtime',
                        id: info.m,
                        babyId: info.b,
                    }
                }
                if (info.b) {
                    return {
                        type: 'Food',
                        id: undefined,
                        babyId: info.b,
                    }
                }
                return undefined
            }
            info.addDraftFood = (foodId: number) => {
                if (!info.f) {
                    info.f = []
                }
                info.f.push(foodId)
            }
            this._backInfo = info
        }
    }
    public get backInfo() {
        return this._backInfo
    }

    public async addFood(laf: LastAndFood): Promise<string> {
        const throwIfNullable = makeThrowIfNullable({
            error: -20,
            message: 'Wrong target',
            detail: 'Выбор сделан, но не ясно куда добавлять. Требуется перейти на предыдущую страницу',
        })
        const bi: BackInfo = throwIfNullable(this._backInfo)
        const where = throwIfNullable(bi.whereAdd())
        console.log('backInfoManager addFood where:', where)
        if (where.type === 'Food') {
            if (where.id) {
                const fmngr = await createFoodManager(where.id)
                await fmngr.addFood(laf)
                fmngr.changeIngredient()
                fmngr.saveDraft()
                return urls.editFoodPage(where.id, bi.b, bi.m, bi.f?.slice(0, -1))
            } else {
                return urls.foodsPage(where.babyId)
            }
        }
        if (where.type === 'Mealtime' && where.babyId !== undefined) {
            const manager = await createMealtimeManager(where.babyId, where.id)
            await manager.addFood(laf)
            manager.saveDraft()
            return urls.editMealtimePage(where.babyId, where.id)
        }
        return throwIfNullable(undefined)
    }
    public back(): string {
        const throwIfNullable = makeThrowIfNullable({
            error: -21,
            message: 'Wrong back target',
            detail: 'Не ясно куда возвращаться.',
        })
        const bi: BackInfo = throwIfNullable(this._backInfo)
        const where = throwIfNullable(bi.whereAdd())
        console.log('backInfoManager back where:', where)
        if (where.type == 'Food') {
            if (where.id) {
                return urls.editFoodPage(where.id, bi.b, bi.m, bi.f?.slice(0, -1))
            } else {
                return urls.foodsPage(where.babyId)
            }
        }
        if (where.type === 'Mealtime' && where.babyId !== undefined) {
            return urls.editMealtimePage(where.babyId, where.id)
        }
        return throwIfNullable(undefined)
    }
}

function makeThrowIfNullable(defaultError: ApiError): (obj: any) => NonNullable<any> {
    return function<T>(obj: T | undefined | null, error?: ApiError): NonNullable<T> {
        if (!obj) {
            const err = error || defaultError
            throw err
        } else {
            return obj
        }
    }
}