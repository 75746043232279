import React, { useCallback, useEffect, useState } from 'react'
import { SimpleButton } from '../../Form/Button/SimpleButton'
import { Modal } from '../../Modal'
import { DuetButtons } from '../../ActionButtons/DuetButtons'
import { Version } from './Version'
import cl from './index.module.scss'


export const HeadTitle: React.FC<{
    showLangs?: boolean
    showVersion?: boolean
    onServerChanged?: (server: number | undefined | null) => void

}> = ({ showLangs, showVersion, onServerChanged }) => {
    const [isLang, setIsLang] = useState(false)
    const [serVer, setSerVer] = useState(undefined as number | null | undefined)
    useEffect(() => {
        onServerChanged && onServerChanged(serVer)
    }, [serVer])
    const updateCache = useCallback(() => {
        if (!showVersion) {
            return
        }
        window.location.href = `/update.html?rnd=${Math.random()}`
    }, [serVer])
    return (
        <>
            <div className={cl.head_title}>
                <div onClick={updateCache} className={cl.head_title__desc}>контроль питания</div>
                <div className={cl.head_title__name}>ФКУ Диета²</div>
                {showVersion ? <div><Version onChange={setSerVer} /></div> : null}
                {showLangs ? <DonationButton modalVisible={setIsLang} /> : null}
            </div>
            {showLangs ? <DonationModal modalVisible={setIsLang} isVisible={isLang} /> : null}
        </>
    )
}

const DonationButton: React.FC<{ modalVisible: (v: boolean) => void }> = (props) => {
    const toEng = useCallback(() => {
        props.modalVisible(true)
    }, [props.modalVisible])
    return (
        <div className={cl.head_title__lang}>
            <SimpleButton type='transparent' click={toEng}>English</SimpleButton>
        </div>
    )
}

const DonationModal: React.FC<{ modalVisible: (v: boolean) => void, isVisible: boolean }> = ({ modalVisible, isVisible }) => {
    const cancel = useCallback(() => {
        modalVisible(false)
    }, [modalVisible])
    const toDonate = useCallback(() => {
        window.location.href = 'https://telegra.ph/PKU-Diet-Donate-04-02'
    }, [])
    return (
        <Modal isShow={isVisible} changed={modalVisible}>
            <div className={cl.modal}>
                <h2>Support English Implementation for PKU Diet²</h2>
                <p>
                    Help me implement an English version of the PKU Diet² program.
                    Your donation will enable me to reach and assist more individuals in managing PKU.
                    Every contribution counts. Donate now or share to support my cause.
                </p>
            </div>
            <DuetButtons
                first={{
                    type: 'transparent',
                    content: 'Cancel',
                    action: cancel,

                }}
                second={{
                    type: 'success',
                    content: 'Donate now',
                    action: toDonate,
                }} />
        </Modal>
    )
}
