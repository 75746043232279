import React, { ReactNode } from 'react'
import { clickDelay, cls, defaultDelay } from '../../../../core/helper'
import cl from './index.module.scss'


export type SimpleBtnType = 'transparent' | 'danger' | 'success' | 'light-danger' | 'light-success'
export type SimpleBtnProps = {
    size?: 'normal' | 'small' | 'big'
    type: SimpleBtnType
    children: ReactNode
    click: () => void
    disabled?: boolean
}

export const SimpleButton: React.FunctionComponent<SimpleBtnProps> = (props) => {
    const click = clickDelay(defaultDelay, () => {
        props.click()
    })
    return (
        <button {...cls(
            cl.btn,
            cl[`btn--size-${props.size || 'normal'}`],
            cl[`btn--${props.type}`],
        )} onClick={click} disabled={props.disabled}
        >{props.children}</button>
    )
}
