import React, { ReactNode, useEffect, useState } from 'react'
import { LastAndFood, searchFoodRegexp } from '../../../hooks/useFood'
import { NutrientsItem } from '../../../component/NutrientsItem'
// import cl from './index.module.scss';


type Props = {
    food: LastAndFood
    search: string
    click: (food: LastAndFood) => void
}

export const FoodItem: React.FunctionComponent<Props> = ({ food, search, ...props }) => {
    const [name, setName] = useState(food.f.name as ReactNode | string)
    useEffect(() => {
        const version = search
        if (search.length > 0) {
            if (version != search) {
                return
            }
            const arr = food.f.name.split(searchFoodRegexp(search))
            if (arr.length != 5) {
                setName(name)
            } else {
                setName(<span>{
                    arr.slice(1, -1).map((text, i) => {
                        if (i == 1) {
                            return <span key={text + '-' + i} style={{ color: 'orange' }}>{text}</span>
                        } else {
                            return <span key={text + '-' + i}>{text}</span>
                        }
                    })
                }</span>)
            }
        } else {
            setName(food.f.name)
        }
    }, [search])
    const click = () => {
        props.click(food)
    }
    return (
        <>
            <div style={{
                padding: '2px 0px', display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-end',
            }} onClick={click}>
                <div style={{ flex: '1 0 auto', paddingBottom: 4, maxWidth: '100%',
                    boxSizing: 'border-box',
                    paddingLeft: 8, paddingRight: 8 }}>{name}</div>
                <div className='nuts' style={{ width: 220, paddingBottom: 4, alignItems: 'center', marginRight: 10 }}>
                    <NutrientsItem nutrients={food.f} ext={'no'}/>
                </div>
            </div>
            <div style={{ width: 150, margin: '4px auto 8px auto', borderBottom: '1px solid #ccc' }}/>
        </>
    )
}
