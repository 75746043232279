import React, { ReactNode, useCallback, useEffect, useState } from 'react'
import { SimpleBtnProps, SimpleButton } from '../Form/Button/SimpleButton'
import { BtnSettings, DuetButtons } from '../ActionButtons/DuetButtons'
import cl from './index.module.scss'


type Props = {
    isShow: boolean
    changed?: (visible: boolean) => void
    children: ReactNode
}

export const Modal: React.FunctionComponent<Props> = (props) => {
    if (!props.isShow) {
        return null
    }
    const close = () => {
        props.changed && props.changed(!props.isShow)

    }
    const prevent = (e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation()
        return false
    }
    return (
        <>
            <div className={cl.bg}></div>
            <div className={cl.content} onClick={close}>
                <div className={cl.modal} onClick={prevent}>
                    <div className={cl.modal__content}>
                        {props.children}
                    </div>
                    <div className={cl.modal__close} onClick={close}>X</div>
                </div>
            </div>
        </>
    )
}


export const ModalWithButton: React.FC<{
    children: ReactNode
    modal?: Omit<Props, 'isShow' | 'children'>
    btn: Omit<SimpleBtnProps, 'click'>
    duetBtn?: {
        first?: 'cancel' | BtnSettings | ReactNode
        second?: BtnSettings | ReactNode
    }
    disabled?: boolean
}> = (props) => {
    const [isShow, setIsShow] = useState(false)
    const [first, setFirst] = useState(props.duetBtn?.first as ReactNode | BtnSettings | undefined)
    const [second, setSecond] = useState(props.duetBtn?.second as ReactNode | BtnSettings | undefined)
    useEffect(() => {
        if (!props.duetBtn?.second) {
            return setSecond(undefined)
        }
        if (React.isValidElement(props.duetBtn.second)) {
            return setSecond(props.duetBtn.second)
        }
        const second: BtnSettings = props.duetBtn.second as BtnSettings
        setSecond({
            ...second,
            action: () => {
                setIsShow(false)
                second.action()
            },
            disabled: second.disabled || props.disabled,
        })
    }, [props.duetBtn])
    useEffect(() => {
        if (!props.duetBtn?.first) {
            return setFirst(undefined)
        }
        if (typeof props.duetBtn.first === 'string' && props.duetBtn.first === 'cancel') {
            return setFirst({
                type: 'transparent',
                content: 'Отменить',
                action: btnClose,
                disabled: props.disabled,
            })
        }
        if (React.isValidElement(props.duetBtn?.first)) {
            return setFirst(props.duetBtn?.first)
        }
        const first: BtnSettings = props.duetBtn?.first as BtnSettings
        setFirst({
            ...first,
            action: () => {
                setIsShow(false)
                first.action()
            },
        })
    }, [props.duetBtn])

    const btnOpen = useCallback(() => {
        setIsShow(true)
    }, [])
    const btnClose = useCallback(() => {
        setIsShow(false)
    }, [])
    return (
        <>
            <SimpleButton {...props.btn} click={btnOpen} disabled={props.disabled}/>
            <Modal {...(props.modal || {})} isShow={isShow} changed={setIsShow}
            >
                {props.children}
                {
                    props.duetBtn
                        ? (
                            <DuetButtons
                                first={first}
                                second={second}
                                disabled={props.disabled}/>
                        ) : undefined
                }
            </Modal>
        </>
    )
}