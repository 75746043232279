import React, { FunctionComponent, useEffect, useState } from 'react'
import { Nutrients } from '../../core/db_food'
import { numberToString } from '../../core/helper'

type ExtType = 'no' | 'no_energy' | 'all' | 'no_with_dot_less'
export const NutrientsItem: FunctionComponent<{ nutrients: Nutrients, ext?: ExtType, dotLess?: number }> = ({
    nutrients, ext, dotLess,
}) => {
    const [line, setLine] = useState(nutToString(nutrients))
    useEffect(() => {
        setLine(nutToString(nutrients, ext, dotLess))
    }, [nutrients])
    return <NutrientsLine line={line} />
}


export const NutrientsLine: React.FunctionComponent<{ line: (string | React.ReactNode)[] }> = (props) => {
    return (
        <>
            {
                props.line.map((one, i) => {
                    return <div key={i}>{one}</div>
                })
            }
        </>
    )
}


function nutToString(nutrients: Nutrients, ext?: ExtType, dotLess: number = 100): string[] {
    if (ext === undefined) {
        return [
            numberToString(nutrients.p, 1) + 'г',
            numberToString(nutrients.fa, 1) + 'мг',
            numberToString(nutrients.f, 1) + 'г',
            numberToString(nutrients.c, 1) + 'г',
            numberToString(nutrients.e, 0) + ' ккал',
        ]
    }
    if (ext === 'no') {
        return [
            numberToString(nutrients.p, 1),
            numberToString(nutrients.fa, 1),
            numberToString(nutrients.f, 1),
            numberToString(nutrients.c, 1),
            numberToString(nutrients.e, 0),
        ]
    }
    if (ext === 'no_with_dot_less') {
        return [
            numberToString(nutrients.p, 1, dotLess),
            numberToString(nutrients.fa, 1, dotLess),
            numberToString(nutrients.f, 1, dotLess),
            numberToString(nutrients.c, 1, dotLess),
            numberToString(nutrients.e, 0),
        ]
    }
    return [
        numberToString(nutrients.p, 1) + 'г',
        numberToString(nutrients.fa, 1) + 'мг',
        numberToString(nutrients.f, 1) + 'г',
        numberToString(nutrients.c, 1) + 'г',
        numberToString(nutrients.e, 0),
    ]
}