import { dbManager } from './db'
import { wait } from './helper'

export type Baby = {
    id: number
    name: string
    boy: number
    /** @description День рождения */
    bday?: number
}

export async function babySave(baby: Baby) {
    if (baby.id > 0) {
        await dbManager.db.babies.put(baby)
        return baby.id
    } else {
        baby.id = await getNextBabyId()
        return await dbManager.db.babies.add(baby)
    }
}

async function getNextBabyId(): Promise<number> {
    const count = await dbManager.db.babies.count()
    if (count === 0) {
        return 1
    }
    let mid = await dbManager.db.babies.orderBy('id').uniqueKeys((keys) => {
        const t = keys.map(one => {
            return parseInt(one.toString())
        })
        console.log(t, typeof (t[0]))
        return Math.max(...t, 0)
    })
    const a = await dbManager.db.mealtimes.where('b').aboveOrEqual(mid).toArray()
    mid = Math.max(mid, ...a.map(one => one.b), 0)

    const b = await dbManager.db.conditions.where('b').aboveOrEqual(mid).toArray()
    mid = Math.max(mid, ...b.map(one => one.b), 0)
    return mid + 1
}