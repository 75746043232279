import React, { useCallback } from 'react'
import { FormInputProps, FromInput } from '../../FoodEditPage/SimpleFood'
import { Condition } from '../../../../core/db_condition'


export const OneInput: React.FC<Omit<FormInputProps, 'change'> & {
    k: Exclude<keyof Condition, 'ct'>
    change: (cond: Partial<Condition>) => void
}> = (props) => {
    const change = useCallback((val: number) => {
        const tmp: Partial<Condition> = {}
        tmp[props.k] = val
        props.change(tmp)
    }, [props.k, props.change])
    return (
        <FromInput {...props} change={change} />
    )

}