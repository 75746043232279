import { dbManager } from './db'
import { MealtimeType } from './db_mealtime_type'
import { DAY_SEC, dtToUnix } from './helper'

export async function getMealtimeTypes(): Promise<Record<number, MealtimeType>> {
    return dbManager.db.mealtimeTypes.toArray().then((res) => {
        return (res as MealtimeType[]).reduce((r, o) => {
            r[o.id] = o
            return r
        }, {} as Record<number, MealtimeType>)
    })
}


export async function getMealtimeDayStartEnd(tsInDay: number) {
    const t = await getMealtimeTypes()
    return getMealtimeDayStartEndByMealtimeTypes(tsInDay, t)
}

function getMealtimeDayStartEndByMealtimeTypes(tsInDay: number, mealtimeTypes: Record<number, MealtimeType>) {
    const p1 = mealtimeTypes[1].tf
    let p2 = mealtimeTypes[1].tf
    for (let i = 1; i < 20; i++) {
        const x = mealtimeTypes[i]
        if (!x) {
            break
        }
        p2 = x.tt
    }
    const diff = timeDifference(p2, p1)
    const half = halveDuration(diff)
    const sDay = addTimes(p2, half)
    const [start, end] = getUnixStartEndDay(tsInDay, sDay)
    return [start, end]
}

export function changeDayMealtimeType(tsInDay: number, mt: MealtimeType, mealtimeTypes: Record<number, MealtimeType>) {
    const [start, end] = getMealtimeDayStartEndByMealtimeTypes(tsInDay, mealtimeTypes)
    const diff = timeDifference(mt.tf, mt.tt)
    const half = halveDuration(diff)
    const sDay = addTimes(mt.tf, half)
    const { hours, minutes } = parseTime(sDay)

    const dt = new Date(tsInDay * 1000)
    const newDt = new Date(dt.getFullYear(), dt.getMonth(), dt.getDate(), hours, minutes, 0, 0)
    let newTs = dtToUnix(newDt, true)
    if (newTs < start) {
        newTs += DAY_SEC
    }
    if (newTs >= end) {
        newTs -= DAY_SEC
    }
    return newTs
}

function getUnixStartEndDay(tsBetweenStartEnd: number, sDay: string): [number, number] {
    // Get the date from the provided timestamp
    const date = new Date(tsBetweenStartEnd * 1000)

    // Parse the start and end times
    const startTime = parseTime(sDay)

    // Get the beginning of the day in the respective timezone
    const beginDay = new Date(date.getFullYear(), date.getMonth(), date.getDate(), startTime.hours, startTime.minutes, 0)
    if (dtToUnix(beginDay) > tsBetweenStartEnd) {
        beginDay.setDate(beginDay.getDate() - 1)
    }
    // End of the day is the next day at 00:00 in the respective timezone
    const endDay = new Date(beginDay.getFullYear(), beginDay.getMonth(), beginDay.getDate(), startTime.hours, startTime.minutes, 0)
    endDay.setDate(endDay.getDate() + 1)

    // Return the Unix timestamps
    const beginUnix = Math.floor(beginDay.getTime() / 1000)
    const endUnix = Math.floor(endDay.getTime() / 1000)

    return [beginUnix, endUnix]
}

function timeDifference(start: string, end: string): string {
    // Get the total minutes for start and end times
    const startMinutes = toMinutes(start)
    const endMinutes = toMinutes(end)

    // Calculate the difference in minutes
    let diffMinutes = endMinutes - startMinutes
    if (diffMinutes < 0) {
        // If the difference is negative, it means the end time is past midnight
        diffMinutes += 24 * 60 // Add 24 hours worth of minutes
    }

    // Convert the difference back to hours and minutes
    const diffHours = Math.floor(diffMinutes / 60)
    const diffMins = diffMinutes % 60

    // Format the result as "HH:MM"
    const formattedDiff = `${String(diffHours).padStart(2, '0')}:${String(diffMins).padStart(2, '0')}`

    return formattedDiff
}


function halveDuration(time: string): string {
    // Convert the input time to total minutes
    const totalMinutes = toMinutes(time)

    // Halve the total minutes
    const halfMinutes = Math.floor(totalMinutes / 2)

    // Convert the halved minutes back to "HH:MM" format
    return fromMinutes(halfMinutes)
}

function addTimes(time1: string, time2: string): string {
    // Convert both input times to total minutes
    const minutes1 = toMinutes(time1)
    const minutes2 = toMinutes(time2)

    // Add the two times
    let totalMinutes = minutes1 + minutes2

    // Ensure the result is within 24 hours
    totalMinutes = totalMinutes % (24 * 60)

    // Convert back to "HH:MM" format
    return fromMinutes(totalMinutes)
}

// Helper function to convert time string "HH:MM" to total minutes
function toMinutes(time: string): number {
    const [hours, minutes] = time.split(':').map(Number)
    return hours * 60 + minutes
}

// Helper function to convert total minutes to time string "HH:MM"
function fromMinutes(totalMinutes: number): string {
    const hours = Math.floor(totalMinutes / 60)
    const minutes = totalMinutes % 60
    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`
}

function parseTime(time: string): { hours: number, minutes: number } {
    const [hours, minutes] = time.split(':').map(Number)
    return { hours, minutes }
}