import React, { useEffect, useState } from 'react'
import { MealtimeDish } from '../../../core/db_mealtime'
import { NutrientsItem } from '../../../component/NutrientsItem'
import { emptyNutrients } from '../../../core/db_food'
import { nutrientsByWeightAndPortions } from '../../../core/abstractManager'
import { DishNumber } from './DishNumber'
import { SimpleButton } from '../../../component/Form/Button/SimpleButton'
import { useSwipeable } from 'react-swipeable'
// import cl from './index.module.scss';


type Props = {
    dish: MealtimeDish
    disabled: boolean
    change: (dish: MealtimeDish) => void
    del: (dish: MealtimeDish) => void
}

export const MealtimeDishItem: React.FunctionComponent<Props> = ({ dish, change, disabled, ...props }) => {
    const [calculatedNutrients, setCalculatedNutrients] = useState(emptyNutrients())
    const [showDel, setShowDel] = useState(0)
    const handlers = useSwipeable({
        onSwipedLeft: () => setShowDel(m => m - 1 < 0 ? -1 : 0),
        onSwipedRight: () => setShowDel(m => m + 1 > 0 ? 1 : 0),
    })
    useEffect(() => {
        setCalculatedNutrients(nutrientsByWeightAndPortions(dish))
    }, [dish])
    const changeWeight = (value: number) => {
        dish.w = value
        change({ ...dish })
    }
    const changePortion = (value: number) => {
        dish.pn = value
        change({ ...dish })
    }
    const del = () => {
        props.del(dish)
    }
    return (
        <div style={{
            position: 'relative',
            display: 'flex',
            overflow: 'hidden',
        }}>
            <div style={{
                width: 100,
                transition: 'margin 0.15s ease',
                marginLeft: (showDel > 0 ? 0 : -100 * (Math.abs(showDel) + 1)),
                display: 'flex',
                justifyContent: 'center', alignItems: 'center',
                padding: '0 8px', boxSizing: 'border-box',
            }}>
                <SimpleButton type='danger' disabled={disabled} click={del} >Удалить</SimpleButton>
            </div>
            <div style={{ flex: 1, display: 'flex', padding: '6px 8px',

                width: '100%',
                minWidth: '100%',
                boxSizing: 'border-box',
            }}>
                <div style={{ flex: 1 }} {...handlers} onClick={() => setShowDel(m => m != 0 ? 0 : 1)}>
                    <div>{dish.n}</div>
                    <div className='nuts' style={{ marginTop: 4 }}>
                        <NutrientsItem nutrients={calculatedNutrients} ext={'no'} />
                    </div>
                </div>
                <div style={{ width: 100, display: 'flex' }}>
                    <DishNumber value={dish.w} step={1} disabled={disabled} change={changeWeight}/>
                    <DishNumber value={dish.pn} step={0.1} disabled={disabled} change={changePortion}/>
                </div>
            </div>
            <div style={{
                width: 100,
                transition: 'margin 0.15s ease',
                marginRight: (showDel < 0 ? 0 : -100),
                display: 'flex',
                justifyContent: 'center', alignItems: 'center',
                padding: '0 8px', boxSizing: 'border-box',
            }}>
                <SimpleButton type='danger' disabled={disabled} click={del} >Удалить</SimpleButton>
            </div>
        </div>
    )
}
