import React from 'react'
import { Page, PageMain } from '../../../component/Layout/Page'
import { BabyItem } from '../../../component/BabyItem'
import { useBaby } from '../../../hooks/useBaby'
import { useLoaderData, useNavigate } from 'react-router-dom'
import { useErrors } from '../../../hooks/useErrors'
import { useConditionList } from '../../../hooks/useConditionList'
import { ConditionItem } from './ConditionItem'
import { BottomMenu } from '../../../component/BottomMenu'
import { SimpleButton } from '../../../component/Form/Button/SimpleButton'
import { Condition } from '../../../core/db_condition'
import { urls } from '../../../core/urls'
import { BabyManager } from '../../../core/mealtime'
import { SmartErrorList } from '../../../component/SmartErrorList'
import { ActionButtons } from '../../../component/ActionButtons'
import { RoundButton } from '../../../component/ActionButtons/RoundButton'
import cl from './index.module.scss'


export const ConditionListPage: React.FunctionComponent = () => {
    const navigate = useNavigate()
    const data = useLoaderData() as { babyId: number, babyManager: BabyManager }
    const [errors, addError, resetError] = useErrors()
    const [baby, _, babyManager] = useBaby()
    const [list, loadMore] = useConditionList(baby?.id, addError, resetError)
    const openCondition = (item: Condition) => {
        if (!baby) { return }
        navigate(urls.editConditionsPage(baby.id, item.dt))
    }
    const newCondition = () => {
        if (!baby) { return }
        navigate(urls.editConditionsPage(baby.id, -1))
    }
    const disabled = loadMore === undefined
    return (
        <Page>
            <Page.Main>
                <PageMain.Title>
                    <BabyItem key={`${data.babyId}`} baby={baby} />
                </PageMain.Title>
                <PageMain.Content>
                    <div className={cl.cond}>
                        <h1>Анализ состояния</h1>
                        <div className={cl.cond__list}>
                            {
                                list.map(one => {
                                    return (
                                        <ConditionItem key={one.dt} item={one} click={openCondition} />
                                    )
                                })
                            }
                            <div className={cl.cond__load_more}>
                                {
                                    loadMore !== undefined
                                        ? (
                                            <SimpleButton type='transparent' click={loadMore} disabled={disabled}>
                                                загрузить ещё
                                            </SimpleButton>
                                        )
                                        : undefined
                                }
                            </div>
                        </div>
                        <SmartErrorList errors={errors} resetError={resetError} />
                        <ActionButtons>
                            <RoundButton onClick={newCondition}>
                                <img src='/img/ico-plus.svg' width={24} height={24} />
                            </RoundButton>
                        </ActionButtons>
                    </div>
                </PageMain.Content>
            </Page.Main>
            <Page.Footer key="button-menu">
                <BottomMenu key="button-menu" selected='listConditions' />
            </Page.Footer>
        </Page>
    )
}
