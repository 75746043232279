import React, { useEffect, useRef, useState } from 'react'
import { BackInfoManager, useBackInfo } from '../../../hooks/useBackInfo'
import { useFood } from '../../../hooks/useFood'
import { useLoaderData, useNavigate } from 'react-router-dom'
import { SmartCheckbox } from '../../../component/Form/Elements/SmartCheckbox'
import { Food, Nutrients } from '../../../core/db_food'
import { urls } from '../../../core/urls'
import { SimpleFood } from './SimpleFood'
import { MixedFood } from './MixedFood'
import { addErrorIfVersionNotChanged, useErrors } from '../../../hooks/useErrors'
import { SmartErrorList } from '../../../component/SmartErrorList'
import { Page, PageMain } from '../../../component/Layout/Page'
import { DuetButtons } from '../../../component/ActionButtons/DuetButtons'
import { Input } from '../../../component/Input'
import { cls } from '../../../core/helper'
import { ModalBack } from './ModalBack'
import { HeadTitle } from '../../../component/Head/HeadTitle'
import cl from './index.module.scss'


type StageType = 'save' | 'saving' | 'wait_user'
export const FoodEditPage: React.FunctionComponent = () => {
    const navigate = useNavigate()
    const data = useLoaderData() as { foodId: number, babyId?: number }
    const backInfo = useBackInfo()
    const [errors, addError, resetError] = useErrors()
    const [manager, food, setFood] = useFood(addError)
    const [stage, setStage] = useState('wait_user' as StageType)
    const [hasChanges, setHasChanges] = useState(false)
    const v = useRef(0)
    useEffect(() => {
        if (stage !== 'save' || !manager) {
            return
        }
        v.current += 1
        const version = v.current
        setStage('saving')
        manager.save().then(dt => {
            if (v.current !== version) {
                return Promise.reject('version_changed')
            }
            manager.deleteDraft()
            if (!backInfo) {
                return urls.foodsPage(data.babyId)
            }
            const bim = new BackInfoManager('', backInfo)
            if (!backInfo.m && !backInfo.f) {
                return bim.back()
            }
            const foodToAdd = manager.getCurrent()
            return bim.addFood({
                f: foodToAdd,
            })
        }).then(url => {
            console.log('saved navigate to url:', url)
            navigate(url, { replace: true })
        }).catch(addErrorIfVersionNotChanged(addError)).finally(() => {
            if (v.current === version) {
                setFood({ ...manager.getCurrent() })
                setStage('wait_user')
            }
            v.current === version && setStage('wait_user')
        })
    }, [stage])
    useEffect(() => {
        setHasChanges(manager?.hasChanges() == true)
    }, [food])
    if (!food || !manager) {
        return <SmartErrorList errors={errors} resetError={resetError} />
    }
    const onChangeName = (value: string) => {
        setFood({ ...food, ...{ name: value } })
    }
    const onChangeMix = (value: boolean) => {
        setFood({ ...food, ...{ m: value } })
    }
    const changeSimpleFood = (val: Nutrients) => {
        setFood({ ...food, ...val })
    }
    const changeMixedFood = (val: Food) => {
        setFood({ ...val })
    }
    const save = () => {
        setStage('save')
    }
    const deleteDraftAndBack = () => {
        manager.deleteDraft()
        back()
    }
    const back = () => {
        if (!backInfo) {
            navigate(urls.foodsPage(data.babyId), { replace: true })
            return
        }
        navigate(manager.back(
            new BackInfoManager('', backInfo),
            urls.foodsPage(backInfo.b, backInfo.m, backInfo.f),
        ))
    }
    const disabled = stage !== 'wait_user'
    return (
        <Page>
            <Page.Main>
                <PageMain.Title>
                    <HeadTitle />
                </PageMain.Title>
                <PageMain.Content>
                    <div className={cl.food}>
                        <h1>
                            { data.foodId < 0 ? 'Новый продукт' : 'Редактирование продукта' }
                        </h1>
                        <div {...cls(
                            cl.form,
                            cl['form--one'],
                        )}>
                            <span className={cl.form__label}>Название:</span>
                            <div><Input className={cl.form__input}
                                value={food.name}
                                autofocus={food.name.length === 0}
                                placeholder='название...'
                                onChange={onChangeName}
                                disabled={disabled}/></div>
                            <SmartCheckbox title='Смесь' value={food.m} change={onChangeMix} disabled={disabled} />
                            { !food.m ? <SimpleFood food={food} change={changeSimpleFood} disabled={disabled}/> : undefined }
                        </div>
                        { food.m ? <MixedFood food={food} manager={manager}
                            addError={addError} resetError={resetError}
                            change={changeMixedFood} disabled={disabled}/> : undefined }
                        {/* {data.foodId} */}
                        {/* <pre style={{ maxWidth: '100vw' }}>{JSON.stringify(backInfo, null, 2)}</pre> */}
                        {/* <pre style={{ maxWidth: '100vw' }}>{JSON.stringify(food, null, 2)}</pre> */}
                        <SmartErrorList errors={errors} resetError={resetError} />
                    </div>
                </PageMain.Content>
            </Page.Main>
            <Page.Footer key="button">
                <div style={{ padding: 16 }}>
                    <DuetButtons
                        first={hasChanges
                            ? <ModalBack delete={deleteDraftAndBack} disabled={disabled}/>
                            : {
                                type: 'transparent',
                                action: back,
                                content: 'Назад',
                            }}
                        second={hasChanges ? {
                            type: 'success',
                            action: save,
                            content: 'Сохранить',
                        } : undefined}
                        disabled={disabled}
                    />
                </div>
            </Page.Footer>
        </Page>
    )
}
