import React, { useEffect, useState } from 'react'
import { Mealtime } from '../../../core/db_mealtime'
import { MealtimeTime } from './MealtimeTime'
import { SelectMealtimeType } from './SelectMealtimeType'
import { MealtimeDate } from './MealtimeDate'
import { DAY_SEC, curTime, dtDate, dtToUnix } from '../../../core/helper'
import { MealtimeTypeManager, useMealtimeTypes } from '../../../hooks/useMealtimeTypes'
import { dtToTimeDate } from '../../../pages/Client/MealtimePage/DateComp'
import { Modal } from '../../Modal'
import { SimpleButton } from '../Button/SimpleButton'
import { DuetButtons } from '../../ActionButtons/DuetButtons'
// import cl from './index.module.scss';


type Props = {
    mealtime: Mealtime
    change: (dt: number, typeId: number) => void
    disable: boolean
}

export const MealtimeDateTime: React.FunctionComponent<Props> = (props) => {
    const [mts] = useMealtimeTypes()
    const [dtInfo, setDtInfo] = useState(dtToString(props.mealtime.dt))
    const [modalVisible, setModalVisible] = useState(false)
    const [initDt, setInitDt] = useState([props.mealtime.dt, props.mealtime.t])
    const showModal = () => setModalVisible(true)
    useEffect(() => {
        if (modalVisible) {
            setDtInfo(dtToString(props.mealtime.dt))
            setInitDt([props.mealtime.dt, props.mealtime.t])
        }
    }, [modalVisible])
    useEffect(() => {
        setDtInfo(dtToString(props.mealtime.dt))
    }, [props.mealtime.dt])
    if (!mts) {
        return null
    }
    const changeDate = (date: Date) => {
        const dt = dtToUnix(date, true)
        const typeId = MealtimeTypeManager.calcTypeByTime(dt, mts).id
        props.change(dt, typeId)
    }
    const current = () => {
        const dt = curTime(true)
        const typeId = MealtimeTypeManager.calcTypeByTime(dt, mts).id
        props.change(dt, typeId)
    }
    const cancel = () => {
        props.change(initDt[0], initDt[1])
        setModalVisible(false)
    }
    const apply = () => {
        setModalVisible(false)
    }
    const isChanged = initDt[0] !== props.mealtime.dt || initDt[1] !== props.mealtime.t
    return (
        <div style={{ display: 'grid',
            gridTemplateColumns: '2fr 4fr 3fr',
            alignItems: 'center',
        }}>
            <div className='font-roboto' onClick={showModal}
                style={{ fontWeight: 'bold', textAlign: 'center' }}>{dtInfo.time}</div>
            <SelectMealtimeType dt={props.mealtime.dt} type={props.mealtime.t} mts={mts}
                disable={props.disable} change={props.change} />
            <div className='font-roboto' onClick={showModal}
                style={{ textAlign: 'center' }}>{dtInfo.date}</div>
            <Modal isShow={modalVisible} changed={setModalVisible}>
                <div>
                    <h1 className='font-kurale text-title font-20' style={{ fontWeight: 'normal' }}>Дата и время приёма пищи</h1>
                    <div className='font-raleway' style={{
                        display: 'grid',
                        gridTemplateColumns: '1fr auto 2fr 1fr',
                        gridTemplateRows: '1fr 1fr',
                        justifyContent: 'center',
                        alignItems: 'center',
                        color: '#1A6637',
                        rowGap: 12,
                    }}>
                        <div></div>
                        <div style={{ paddingRight: 6, textAlign: 'right' }}>Дата:</div>
                        <MealtimeDate dt={props.mealtime.dt}
                            disable={props.disable} change={changeDate} />
                        <div></div>
                        <div></div>
                        <div style={{ paddingRight: 6, textAlign: 'right' }}>Время:</div>
                        <MealtimeTime dt={props.mealtime.dt} mts={mts}
                            disable={props.disable} change={props.change} />
                        <div></div>
                        <div style={{
                            gridArea: '3 / 2 / 4 / 4',
                        }}>
                            <SelectMealtimeType dt={props.mealtime.dt} type={props.mealtime.t} mts={mts}
                                disable={props.disable} change={props.change} />
                        </div>
                        <div></div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center', textAlign: 'center', marginTop: 24 }}>
                        <SimpleButton type='transparent' click={current}>установить текущее<br/>дату и время</SimpleButton>
                    </div>
                </div>
                <div style={{ paddingTop: 64 }}>
                    <DuetButtons
                        first={{
                            type: 'transparent',
                            action: cancel,
                            content: 'Отменить',
                        }}
                        second={isChanged ? {
                            type: 'success',
                            action: apply,
                            content: 'Сохранить',
                        } : undefined}
                    />
                </div>
            </Modal>

        </div>
    )
}

function dtToString(ts: number): {
    time: string,
    date: string,
} {
    const info = dtToTimeDate(ts)
    const cur = new Date()
    const dt = dtDate(ts)
    let date = dt.getFullYear() == cur.getFullYear()
        ? `${info.date} (${info.day})`
        : `${info.date}.${dtDate(ts).getFullYear()} (${info.day})`
    const sdt = dtDate(ts).toDateString()
    if (sdt == cur.toDateString()) {
        date = `сегодня (${info.day})`
    } else if (sdt == (new Date(cur.getTime() + DAY_SEC * 1000)).toDateString()) {
        date = `завтра (${info.day})`
    } else if (sdt == (new Date(cur.getTime() + (DAY_SEC * 2) * 1000)).toDateString()) {
        date = `послезавтра (${info.day})`
    } else if (sdt == (new Date(cur.getTime() - DAY_SEC * 1000)).toDateString()) {
        date = `вчера (${info.day})`
    } else if (sdt == (new Date(cur.getTime() - (DAY_SEC * 2) * 1000)).toDateString()) {
        date = `позавчера (${info.day})`
    }
    return {
        time: info.time,
        date,
    }
}