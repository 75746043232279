import { Food, Nutrients, emptyNutrients, extractNutrients } from './db_food'

export class FoodNutrientsManager {
    nut: Partial<Nutrients>
    proteinsChangeStage: number
    constructor(
        nut: Nutrients,
        private isNewFood: boolean) {
        this.nut = extractNutrients(nut)
        this.proteinsChangeStage = this.updateProteinsStage()
    }
    public getNutrients(): Nutrients {
        return { ...emptyNutrients(), ...this.nut }
    }
    public extractPartialNutrients(food: Food): Partial<Nutrients> {
        const nut = extractNutrients(food)
        if (!this.isNewFood) {
            return nut
        }
        const keys = [...Object.keys(nut)]
        keys.forEach(one => {
            const key = one as keyof Nutrients
            if (nut[key] === 0) {
                delete nut[key]
            }
        })
        return nut
    }
    public change(val: Partial<Nutrients>): Nutrients {
        const t = extractNutrients(val)
        if (val.fa !== undefined) {
            this.proteinsChangeStage = 2
        }
        if (val.p !== undefined) {
            if (this.proteinsChangeStage != 2) {
                val.fa = val.p * 50
            }
        }
        if (val.c !== undefined) {
            this.nut.c = val.c
        }
        if (val.f !== undefined) {
            this.nut.f = val.f
        }
        if (val.p !== undefined) {
            this.nut.p = val.p
        }
        if (val.fa !== undefined) {
            this.nut.fa = val.fa
        }
        if (val.e !== undefined) {
            this.nut.e = val.e
        }
        return this.getNutrients()
    }

    private updateProteinsStage() {
        if (this.isNewFood) {
            this.proteinsChangeStage = 0
            if (this.nut.fa && this.nut.fa > 0) {
                this.proteinsChangeStage = 2
            }
        } else {
            this.proteinsChangeStage = 2
        }
        return this.proteinsChangeStage
    }
}