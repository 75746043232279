import React, { useEffect, useRef } from 'react'
import { useAsyncError, useNavigate } from 'react-router-dom'
import { parseError } from '../core/helper'
import { SmartErrorList } from './SmartErrorList'
import { useErrors } from '../hooks/useErrors'
import { urls } from '../core/urls'


export const AwaitError: React.FunctionComponent = () => {
    const navigate = useNavigate()
    const error = useAsyncError()
    const [errors, setErr, resetError] = useErrors()
    const v = useRef(0)
    useEffect(() => {
        if (!error) {
            resetError()
        } else {
            console.error(error)
            if (v.current === 0) {
                v.current += 1
                const err = parseError(error)
                if (err.error === 451) {
                    navigate(urls.agreementsPage)
                } else {
                    setErr(err)
                }
            }
        }

    }, [])
    return (
        <div>
            ERROR: {JSON.stringify(error)}
            <SmartErrorList errors={errors} resetError={resetError} />
            ERR: {JSON.stringify(errors)}
        </div>
    )
}
