import { ILastFood, dbManager } from './db'
import { LastFood } from './db_lastfood'
import { EdibleItem } from './edibleItemsManager'
import { curTime } from './helper'

export class LastFoodManager {
    constructor(private items: EdibleItem[]) {}
    public async save() {
        const arr = this.items.map((item, i) => {
            return this.saveEdibleItem(item.dt, item.w, item.pn, i === 0)
        })
        await Promise.all(arr)
        await this.optimizeLastFoods()
    }
    private async saveEdibleItem(foodDt: number, gram: number = 100, portions: number = 1, isFirst = false) {
        const lastFood: LastFood & ILastFood = {
            dt: curTime(true) + (isFirst ? 0 : -1),
            fdt: foodDt,
            g: gram,
            pt: portions,
        }
        const lf = await dbManager.db.lastFoods.where('fdt').equals(foodDt).first() as LastFood & ILastFood
        if (lf) {
            lastFood.id = lf.id
            lastFood.g = lastFood.g || lf.g
            await dbManager.db.lastFoods.put(lastFood)
        } else {
            lastFood.g = lastFood.g || 100
            const insertedId = await dbManager.db.lastFoods.add(lastFood)
            lastFood.id = insertedId as any
        }
        return lastFood
    }

    private async optimizeLastFoods() {
        return true
    }
}