/* eslint-disable max-len */
import React, { useCallback, useEffect, useState } from 'react'
import { Condition } from '../../../../core/db_condition'
import { Age, CalculateMacroNutrients, CalculateMacroNutrientsByEnergy, CalculateMacroNutrientsFindHandler, FromToNutrientsAndEnergy, Gender } from '../../../../core/calc_macronutrients_mz'
import { BaseCalculator } from './BaseCalculator'
import { SimpleButton } from '../../../../component/Form/Button/SimpleButton'
import { FromBlankInput, FromFromToInput } from '../../FoodEditPage/SimpleFood'
import { OptionType, Select } from '../../../../component/Form/MealtimeDateTime/SelectMealtimeType'
import cl from './MZCalculator.module.scss'


type Props = {
    gender: Gender
    age: Age
    condition: Condition | undefined
    updateCondition: (cond: Partial<Condition>) => void
    disabled: boolean
    disabledReset: boolean
}


const activities: OptionType<number>[] = [
    { typeId: 1, value: 1.4, text: 'Очень низкая' },
    { typeId: 2, value: 1.6, text: 'Низкая' },
    { typeId: 3, value: 1.9, text: 'Средняя' },
    { typeId: 4, value: 2.2, text: 'Высокая' },
    { typeId: 5, value: 1.7, text: '65+' },
]
const farNorthOptions: OptionType<number>[] = [
    { typeId: 1, value: 1, text: 'Нет' },
    { typeId: 2, value: 1.15, text: 'Условия Крайнего севера' },
]

const url = 'https://www.pediatr-russia.ru/information/klin-rek/proekty-klinicheskikh-rekomendatsiy/КР%20ФКУ_ГФА%20в%20МЗ%20РФ.pdf'
const url2 = 'https://www.rospotrebnadzor.ru/bitrix/redirect.php?event1=file&event2=download&event3=1.-mr-2.3.1.0253_21-normy-pishchevykh-veshchestv.pdf&goto=/upload/iblock/789/1.-mr-2.3.1.0253_21-normy-pishchevykh-veshchestv.pdf'
export const MZCalculator: React.FunctionComponent<Props> = (props) => {
    const [activity, setActivity] = useState<OptionType<number>>(activities[2])
    const [farNorth, setFarNorth] = useState<OptionType<number>>(farNorthOptions[0])
    const [percentInfo, setPercentInfo] = useState<string[] | undefined>(undefined)
    const [canEdit, setCanEdit] = useState(false)
    const [percentRange, setPercentRange] = useState<{
        from?: number
        to?: number
    }>({ from: undefined, to: undefined })
    const [condition, setCondition] = useState<Partial<Condition>>(CalculateMacroNutrients(
        props.age, props.gender, props.condition, activity.value || 0,
    ))
    const calcEnergy = useCallback((energy: number) => {
        if (props.age.years * 12 + props.age.months >= 18 * 12) {
            return Math.round(energy * (farNorth.value || 1))
        }
        return energy
    }, [farNorth.value, props.age])
    const [showSource, setShowSource] = useState(false)
    useEffect(() => {
        const handler = CalculateMacroNutrientsFindHandler(props.age, props.gender, props.condition, activity.value || 0)
        if (!handler) {
            setCanEdit(false)
            setPercentInfo(undefined)
            if (props.condition?.e || props.condition?.p || props.condition?.p2 || props.condition?.f || props.condition?.f2 || props.condition?.c || props.condition?.c2) {
                setCondition({
                    ...props.condition,
                    e: undefined,
                    p: undefined,
                    p2: undefined,
                    f: undefined,
                    f2: undefined,
                    c: undefined,
                    c2: undefined,
                })
            }
            return
        }
        let nuts = CalculateMacroNutrients(
            props.age, props.gender, props.condition, activity.value || 0,
        )
        if (nuts.e) {
            nuts.e = calcEnergy(nuts.e)
            nuts = CalculateMacroNutrientsByEnergy(
                props.age, props.gender, props.condition, activity.value || 0, nuts.e,
            )
        }
        if (handler && nuts.p && nuts.p2 && nuts.f && nuts.f2 && nuts.c && nuts.c2 && nuts.e) {
            setCanEdit(handler.canEdit())

            const str = calculateShowingPercentage(percentRange, nuts)
            const percents = handler.nutrientsPercents()

            if (percents) {
                setPercentInfo([
                    `${str && str[0]}`,
                    `${str && str[1]}`,
                    `${str && str[2]}`,
                ])
            } else {
                setPercentInfo(undefined)
            }
        }
        setCondition({
            ...props.condition,
            ...nuts,
        })
    }, [props.age, props.gender, props.condition?.w, props.condition?.g, activity.value, farNorth.value, calcEnergy, percentRange])
    useEffect(() => {
        const str = calculateShowingPercentage(percentRange, condition)
        if (str) {
            setPercentInfo([
                `${str && str[0]}`,
                `${str && str[1]}`,
                `${str && str[2]}`,
            ])
        } else {
            setPercentInfo(undefined)
        }
    }, [percentRange, condition])
    const changeCondition = (cond: Partial<Condition>) => {
        console.log('changeCondition:')
        if (cond.e !== undefined) {
            const nuts = CalculateMacroNutrientsByEnergy(
                props.age, props.gender, props.condition, activity.value || 0, cond.e,
            )
            cond = {
                ...nuts,
            }
        } else {
            if (percentRange.from !== undefined || percentRange.to !== undefined) {
                setPercentRange({ from: undefined, to: undefined })
            }
        }
        setCondition({
            ...condition,
            ...cond,
        })
    }
    const changePercentRange = (name: 'from' | 'to', val: number) => {
        if (name == 'from') {
            setPercentRange(one => {
                return {
                    ...one,
                    from: val,
                }
            })
        } else {
            setPercentRange(one => {
                return {
                    ...one,
                    to: val,
                }
            })
        }
    }
    useEffect(() => {
        props.updateCondition(condition)
    }, [condition])
    return (
        <div className={cl.mz}>
            {
                props.age.years * 12 + props.age.months >= 18 * 12 ? (
                    <div className={cl.mz__activity}>
                        <FromBlankInput label={'Активность:'} labelExt={''}>
                            <Select change={setActivity} disable={props.disabled}
                                typeId={activity.typeId} options={activities}
                            />
                        </FromBlankInput>
                    </div>
                ) : null
            }
            <BaseCalculator condition={condition} changeCondition={changeCondition}
                disabled={props.disabled || !canEdit} disabledReset={props.disabledReset} />
            {
                props.age.years * 12 + props.age.months >= 18 * 12 ? (
                    <div className={cl.mz__activity} style={{ overflow: 'hidden' }}>
                        <FromBlankInput label={'Дополнительно:'} labelExt={''}>
                            <Select change={setFarNorth} disable={props.disabled}
                                typeId={farNorth.typeId} options={farNorthOptions}
                            />
                        </FromBlankInput>
                    </div>
                ) : null
            }
            <div className={cl.mz__percent_range}>
                <FromFromToInput label='% разбега:' labelExt=''
                    value1={percentRange.from} change1={changePercentRange.bind(null, 'from')}
                    value2={percentRange.to} change2={changePercentRange.bind(null, 'to')}
                    disabled={props.disabled || !canEdit}
                    step={1}
                />
            </div>
            <div className={cl.mz__info} style={{ visibility: percentInfo ? 'visible' : 'hidden' }}>
                <div></div>
                <div>
                    <div>- белки {percentInfo ? `${percentInfo[0]}` : ''} (1г = 4 ккал)</div>
                    <div>- жиры {percentInfo ? `${percentInfo[1]}` : ''} (1г = 9 ккал)</div>
                    <div>- углеводы {percentInfo ? `${percentInfo[2]}` : ''} (1г = 4 ккал)</div>
                </div>
                <div></div>
            </div>

            <div style={{ marginBottom: 30 }}>
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}>
                    <SimpleButton click={() => setShowSource(one => !one)} type={showSource ? 'light-success' : 'transparent'} size='small'>Источник данных</SimpleButton>
                </div>
                <div style={{ display: showSource ? 'block' : 'none', padding: 8, fontSize: '80%' }}>
                    <p>Для возраста от 0 лет до 17 лет включительно используются таблицы «Приложения Г9. Нормы физиологической потребности в основных нутриентах для здоровых детей различных возрастных групп и взрослых»:</p>
                    <ul>
                        <li>Среднесуточные нормы потребностей в основных пищевых веществах и энергии для детей первого года жизни (на кг массы тела)</li>
                        <li>Среднесуточные нормы физиологической потребности в основных пищевых веществах и энергии для детей старше года</li>
                    </ul>
                    <p>документа «Клинические рекомендации: Классическая фенилкетонурия и другие виды гиперфенилаланинемии»</p>
                    <p>Разработчик клинической рекомендации:</p>
                    <ul>
                        <li>Ассоциация медицинских генетиков</li>
                        <li>Профессиональная общественная организация (ассоциация)детских врачей «Инициатива специалистов педиатрии и неонатологии в развитии клинических практик»</li>
                        <li>Союз педиатров России</li>
                    </ul>
                    <p>
                        <a href={url} target='_blank' rel="noreferrer">ссылка на документ</a>
                    </p>
                </div>
                <div style={{ display: showSource ? 'block' : 'none', padding: 8, fontSize: '80%' }}>
                    <p>Для возраста от 18 лет и старше используются таблицы:</p>
                    <ul>
                        <li>5.1. Нормы физиологических потребностей в энергии и пищевых веществах для мужчин</li>
                        <li>Оптимальное соотношение долей макронутриентов в калорийности рациона для мужчин</li>
                        <li>5.2. Нормы физиологических потребностей в энергии и пищевых веществах для женщин</li>
                        <li>Оптимальное соотношение долей макронутриентов в калорийности рациона для женщин</li>
                    </ul>
                    <p>документа «Нормы физиологических потребностей в энергии и пищевых веществах для различных групп населения РФ. Методические рекомендации МР 2.3.1. 0253-21»</p>
                    <p>Разработаны:</p>
                    <ul>
                        <li>ФГБУН «ФИЦ питания и биотехнологии»</li>
                        <li>Федеральной службой по надзору в сфере защиты прав потребителей и благополучия человека</li>
                        <li>ФМБФ России</li>
                        <li>ФГАОУ ВО ПМГМУ</li>
                        <li>ФБУН «Новосибирский НИИ гигиены» Роспотребнадзора</li>
                    </ul>
                    <p>
                        <a href={url2} target='_blank' rel="noreferrer">ссылка на документ</a>
                    </p>
                </div>
            </div>
        </div>
    )
}

function calculateShowingPercentage(percentRange: { from?: number, to?: number }, nuts: Partial<FromToNutrientsAndEnergy>): string[] | undefined {
    if (percentRange.from && nuts.p) {
        nuts.p = Math.floor(nuts.p * (1 - percentRange.from / 100))
    }
    if (percentRange.to && nuts.p2) {
        nuts.p2 = Math.ceil(nuts.p2 * (1 + percentRange.to / 100))
    }
    if (percentRange.from && nuts.f) {
        nuts.f = Math.floor(nuts.f * (1 - percentRange.from / 100))
    }
    if (percentRange.to && nuts.f2) {
        nuts.f2 = Math.ceil(nuts.f2 * (1 + percentRange.to / 100))
    }
    if (percentRange.from && nuts.c) {
        nuts.c = Math.floor(nuts.c * (1 - percentRange.from / 100))
    }
    if (percentRange.to && nuts.c2) {
        nuts.c2 = Math.ceil(nuts.c2 * (1 + percentRange.to / 100))
    }
    if (nuts.p && nuts.p2 && nuts.f && nuts.f2 && nuts.c && nuts.c2 && nuts.e) {
        const pp = nuts.p * 4 / nuts.e
        const pp2 = nuts.p2 * 4 / nuts.e
        const pf = nuts.f * 9 / nuts.e
        const pf2 = nuts.f2 * 9 / nuts.e
        const pc = nuts.c * 4 / nuts.e
        const pc2 = nuts.c2 * 4 / nuts.e
        return [
            `${fromto(pp, pp2)}`,
            `${fromto(pf, pf2)}`,
            `${fromto(pc, pc2)}`,
        ]
    }
    return undefined
}


function fromto(x: number, y: number) {
    const rx = Math.round(x * 1000) / 10
    const ry = Math.round(y * 1000) / 10
    if (rx == ry) {
        return `${rx}%`
    }
    return `${rx}-${ry}%`
}