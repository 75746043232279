import React, { useEffect, useState } from 'react'
import { defer, useNavigate } from 'react-router-dom'
import { urls } from '../../core/urls'
import { createNewBase, migration } from '../../core/migration'
import { ApiError } from '../../core/api'
import { ClientVersion, clientVersion, getClient, setClientVersion } from '../../core/auth'
import { SmartErrorList } from '../../component/SmartErrorList'
import { useErrors } from '../../hooks/useErrors'
import { Page, PageMain } from '../../component/Layout/Page'
import { HeadTitle } from '../../component/Head/HeadTitle'
import { SimpleButton } from '../../component/Form/Button/SimpleButton'
import { dbManager } from '../../core/db'
import { MealtimeType } from '../../core/db_mealtime_type'
import styles from './index.module.scss'


type Stages = 'wait_user' | 'import' | 'importing' | 'create_new' | 'creating' | 'wrong_version'
type Props = {
    data: { version: ClientVersion }
}
export const ClientMigrationPage: React.FunctionComponent<Props> = (props) => {
    const navigate = useNavigate()
    const [stage, setStage] = useState('wait_user' as Stages)
    const [ver, setVer] = useState('v1' as ClientVersion)
    const [errors, addError, resetError] = useErrors()
    useEffect(() => {
        if (props?.data?.version) {
            setVer(props.data.version)
        }
    }, [props.data])
    useEffect(() => {
        if (ver !== 'v1') {
            setStage('wrong_version')
        }
    }, [ver])

    useEffect(() => {
        if (stage === 'import') {
            setStage('importing')
            resetError()
            migration().then(result => {
                if (result.error_message === 'already_v2') {
                    setClientVersion(undefined, 'v2')
                    navigate(urls.initializeDatabase)
                } else if (result.result === 'ok') {
                    setClientVersion(undefined, 'v2')
                    navigate(urls.initializeDatabase)
                } else {
                    console.info(result)
                    setStage('wait_user')
                }
            }).catch((err: ApiError) => {
                console.error('ApiError:', err)
                addError(err)
                setStage('wait_user')
            })
        } else if (stage === 'create_new') {
            setStage('creating')
            createNewBase().then(result =>{
                if (result.error_message === 'already_v2') {
                    setClientVersion(undefined, 'v2')
                    navigate(urls.childrenPage)
                    return undefined
                } else if (result.result == 'ok') {
                    setClientVersion(undefined, 'v2')
                    return new Promise<MealtimeType[] | undefined>((resolve, reject) => {
                        dbManager.db.clearTables().then(() => {
                            resolve(result['mealtime_types'])
                        }).catch(reject)
                    })
                } else {
                    console.info(result)
                }
            }).then(res => {
                console.log(res)
                if (res !== undefined) {
                    return dbManager.db.mealtimeTypes.bulkAdd(res)
                }
                return undefined
            }).then(res => {
                if (res !== undefined) {
                    navigate(urls.childrenPage)
                }
            }).catch(addError).finally(()=>{
                setStage('wait_user')
            })
        } else if (stage === 'wrong_version') {
            if (ver === 'v2') {
                navigate(urls.initializeDatabase)
            } else if (ver === 'new') {
                navigate(urls.createUserPage)
            } else {
                console.error('wrong version', ver)
            }
        }
    }, [stage, ver])
    const importData = () => {
        setStage('import')
    }
    const createNew = () => {
        setStage('create_new')
    }
    const logout = () => {
        navigate(urls.logoutPage)
    }
    const isDisabled = stage !== 'wait_user'
    return (
        <Page hFull={true}>
            <Page.Main>
                <PageMain.Title>
                    <HeadTitle />
                </PageMain.Title>
                <PageMain.Content>
                    <div className={styles.class}>
                        <h1>Миграция данных</h1>
                        <p>Вы имеете сохранённые данные из предыдущей версии!</p>
                        <p>Требуется ли их импортировать для использования в новой версии?</p>
                        <p>Внимание данный выбор можно сделать только один раз!</p>
                        {/* <p>Stage: {stage}</p> */}
                        <div className={styles.class__btns}>
                            <SimpleButton type='light-success' click={createNew} disabled={isDisabled}>С чистого листа</SimpleButton>
                            <div></div>
                            <SimpleButton type='success' click={importData} disabled={isDisabled}>Импортировать данные</SimpleButton>
                            <div style={{ marginTop: 16 }}>
                                <SimpleButton type='light-success' click={logout} disabled={isDisabled}>Выйти</SimpleButton>
                            </div>
                        </div>
                        <SmartErrorList errors={errors} resetError={resetError} />
                    </div>
                </PageMain.Content>
            </Page.Main>
            <Page.Footer key="button-menu">
                <br/>
                <br/>
                <br/>
            </Page.Footer>
        </Page>
    )
}

export function loader() {
    const client = getClient()
    if (!client) {
        console.info('Нет данных о клиенте, требуется их получить')
        const promise = clientVersion().then(result => {
            setClientVersion(undefined, result.version as ClientVersion)
            return {
                version: result.version,
            }
        })
        return defer({ result: promise })
    }
    return defer({
        result: Promise.resolve(() => { return { version: client.version } }),
    })
}