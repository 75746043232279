import { FromToNutrientsCondition } from './db_condition'


class NutrientInfo {
    constructor(
        public percentFrom: number,
        public percentTo: number,
        public energyPerGram: number) {

    }
    public calcGramFrom(totalEnergy: number): number {
        return Math.ceil(totalEnergy * this.percentFrom / this.energyPerGram)
    }
    public calcGramTo(totalEnergy: number): number {
        return Math.floor(totalEnergy * this.percentTo / this.energyPerGram)
    }
    public calcGram(totalEnergy: number): [number, number] {
        return [
            this.calcGramFrom(totalEnergy),
            this.calcGramTo(totalEnergy),
        ]
    }
}

export function CalculateMacroNutrients(energy: number): FromToNutrientsCondition {
    const p = new NutrientInfo(0.20, 0.35, 4)
    const f = new NutrientInfo(0.20, 0.35, 9)
    const c = new NutrientInfo(0.45, 0.65, 4)

    return {
        p: p.calcGramFrom(energy),
        p2: p.calcGramTo(energy),
        f: f.calcGramFrom(energy),
        f2: f.calcGramTo(energy),
        c: c.calcGramFrom(energy),
        c2: c.calcGramTo(energy),
    }
}