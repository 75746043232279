import React, { useCallback } from 'react'
import cl from './index.module.scss'


type Props<T = any> = {
    radioId: string
    group: string
    value: T
    selected: boolean
    change: (val: T) => void
    disabled?: boolean
}

export const RadioInput: React.FunctionComponent<Props> = ({ value, change, ...props }) => {
    const onChange = useCallback(() => {
        change(value)
    }, [change, value])
    return (
        <>
            <input className={cl.radio_input__input}
                id={props.radioId} name={props.group}
                type='radio'
                value={value}
                checked={props.selected}
                onChange={onChange}
                disabled={props.disabled === true}
            />
            <div className={cl.radio_input__ico}></div>
        </>
    )
}
