import React, { useEffect, useState } from 'react'
import { Food, IngredientFood, emptyNutrients } from '../../../core/db_food'
import { NutrientsItem, NutrientsLine } from '../../../component/NutrientsItem'
import { InputNumber } from '../../../component/Form/Elements/InputNumber'
import { useBackInfo } from '../../../hooks/useBackInfo'
import { FoodManager } from '../../../hooks/useFood'
import { ApiError } from '../../../core/api'
import { useLoaderData, useNavigate } from 'react-router-dom'
import { urls } from '../../../core/urls'
import { MixedFoodItem } from './MixedFoodItem'
import { ActionButtons } from '../../../component/ActionButtons'
import { RoundButton } from '../../../component/ActionButtons/RoundButton'
import { cls, minStringNumber } from '../../../core/helper'
import cl from './index.module.scss'



type Props = {
    food: Food
    manager: FoodManager
    addError: (err: ApiError) => void
    resetError: () => void
    change: (food: Food) => void
    disabled: boolean
}

export const MixedFood: React.FunctionComponent<Props> = ({ manager, ...props }) => {
    const data = useLoaderData() as { foodId: number, babyId?: number }
    const navigate = useNavigate()
    const backInfo = useBackInfo()
    const [weight, setWeight] = useState(0)
    const [ingredientsWeight, setIngredientsWeight] = useState(0)
    const [totalNutrients, setTotalNutrients] = useState(emptyNutrients())
    useEffect(() => {
        setIngredientsWeight(manager.getTotalWeight())
        setTotalNutrients(manager.getTotalNutrients())
        setWeight(props.food.mcw)
    }, [props.food])
    const changeProductWeight = (val: number) => {
        manager.changeProductWeight(val)
        props.change({ ...manager.getCurrent() })
    }
    const addFood = () => {
        manager.saveDraft({
            parent: {
                babyId: data.babyId,
            },
        })
        if (!backInfo) {
            return navigate(urls.foodsPage(data.babyId))
        }
        backInfo.addDraftFood(manager.getDraftId())
        navigate(urls.foodsPage(backInfo.b, backInfo.m, backInfo.f))
    }
    const change = (item: IngredientFood) => {
        manager.changeIngredient(item)
        props.change({ ...manager.getCurrent() })
        manager.saveDraft({ parent: { babyId: data.babyId } })
    }
    const deleteIngredient = (dt: number) => {
        manager.deleteIngredient(dt)
        manager.changeProductWeight(manager.getTotalWeight())
        manager.saveDraft({ parent: { babyId: data.babyId } })
        props.change({ ...manager.getCurrent() })
    }
    return (
        <>
            <div className={cl.mixed_food__nuts}>
                <NutrientsLine line={['б (г)', 'ФА (мг)', 'ж (г)', 'у (г)', 'ккал']}/>
                <span style={{ paddingRight: 16 }}>вес</span>
                <NutrientsItem nutrients={totalNutrients} ext={'no'}/>
                <span style={{ paddingRight: 16 }}>{minStringNumber(props.food.mcw.toFixed(2))}</span>
                <NutrientsItem nutrients={props.food} ext={'no'}/>
                <span style={{ paddingRight: 16 }}>100</span>
            </div>
            <div className={cl.mixed_food__weights}>
                <div {...cls(
                    cl.weight,
                    cl['weight--accent'],
                )}>
                    <div className={cl.weight__label}>Вес готового продукта</div>
                    <div className={cl.weight__value}>
                        <InputNumber
                            value={weight} step={0.1}
                            change={changeProductWeight}
                            disabled={props.disabled}/>
                        <span>г</span>
                    </div>
                </div>
                <div {...cls(
                    cl.weight,
                    cl['weight--total'],
                )}>
                    <div className={cl.weight__label}>Вес ингредиентов</div>
                    <div className={cl.weight__value}>{ingredientsWeight} г</div>
                </div>
            </div>
            <div className={cl.mixed_food__table_head}>
                <div>Название</div>
                <div>Вес</div>
            </div>
            <div className={cl.mixed_food__items}>
                {
                    props.food.mc.map(one => <MixedFoodItem key={one.dt}
                        item={one}
                        change={change}
                        del={deleteIngredient}
                        disabled={props.disabled}/>)
                }
            </div>
            {/* <pre style={{ maxWidth: '100vw' }}>{JSON.stringify(backInfo, null, 2)}</pre> */}
            <ActionButtons>
                <RoundButton onClick={addFood} disabled={props.disabled}>
                    <img src='/img/ico-plus.svg' width={24} height={24} />
                </RoundButton>
            </ActionButtons>
        </>
    )
}
