import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Page, PageMain } from '../../../component/Layout/Page'
import { BabyItem } from '../../../component/BabyItem'
import { useLoaderData, useLocation, useNavigate } from 'react-router-dom'
import { useBaby } from '../../../hooks/useBaby'
import { DuetButtons } from '../../../component/ActionButtons/DuetButtons'
import { urls } from '../../../core/urls'
import { Condition } from '../../../core/db_condition'
import { FormLabelOnly } from '../FoodEditPage/SimpleFood'
import { useCondition } from '../../../hooks/useCondition'
import { addErrorIfVersionNotChanged, useErrors } from '../../../hooks/useErrors'
import { throwVersionChanged } from '../../../hooks/hooksHelper'
import { SmartErrorList } from '../../../component/SmartErrorList'
import { TextArea } from '../../../component/Form/Elements/TextArea'
import { DateTime } from './DateTime'
import { CalculateMacroNutrients } from '../../../core/calc_macronutrients'
import { FormItems } from './Components/FromItems'
import { CalculatorsManager } from './Calculators/CalculatorsManager'
import cl from './index.module.scss'



type StageType = 'wait_user' | 'save' | 'saving'
export const ConditionEditPage: React.FunctionComponent = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const [stage, setStage] = useState('wait_user' as StageType)
    const data = useLoaderData() as { babyId: number, conditionDt: number }
    const [errors, addError, resetError] = useErrors()
    const [baby, _, babyManager] = useBaby()
    const [manager, condition, updateCondition] = useCondition(addError)
    const [disabledReset, setDisabledReset] = useState(false)
    const [canSave, setCanSave] = useState(false)
    const v = useRef(0)
    const changeEnergy = useCallback((cond: Partial<Condition>) => {
        if (cond.e !== undefined) {
            const macroNutrients = CalculateMacroNutrients(cond.e)
            cond = {
                ...cond,
                ...macroNutrients,
            }
        }
        updateCondition(cond)
    }, [updateCondition])
    useEffect(() => {
        if (stage !== 'save' || !manager) {
            return
        }
        setStage('saving')
        v.current += 1
        const ver = v.current
        manager.save()
            .then(() => {
                throwVersionChanged(v.current !== ver)
                navigate(urls.listConditionsPage(data.babyId))
            })
            .catch(addErrorIfVersionNotChanged(addError))
            .finally(() => {
                setStage('wait_user')
            })
    }, [stage, manager])
    useEffect(() => {
        if (!condition) {
            setDisabledReset(true)
            return
        }
        setDisabledReset(
            condition.p === undefined
            && condition.f === undefined
            && condition.c === undefined
            && condition.e === undefined)
        manager && setCanSave(manager.hasChanges())
    }, [condition, manager])
    const back = useCallback(() => {
        if (location.hash.length > 1) {
            console.error('нужно реализовать')
        } else {
            navigate(urls.listConditionsPage(data.babyId))
        }
    }, [])
    const resetNutrients = useCallback(() => {
        updateCondition({
            p: undefined,
            f: undefined,
            c: undefined,
            p2: undefined,
            f2: undefined,
            c2: undefined,
            e: undefined,
        })
    }, [updateCondition])
    const changeComment = useCallback((val: string) => {
        updateCondition({ ct: val })
    }, [updateCondition])
    const save = useCallback(() => {
        setStage('save')
    }, [])
    const disabled = stage !== 'wait_user'
    const center = {
        textAlign: 'center !important',
    }
    return (
        <Page>
            <Page.Main>
                <PageMain.Title>
                    <BabyItem key={`${data.babyId}`} baby={baby} />
                </PageMain.Title>
                <PageMain.Content>
                    <div className={cl.cond}>
                        <h1>{
                            data.conditionDt > 0
                                ? 'Редактирование'
                                : 'Новый'}
                        </h1>
                        <div>
                            <h2>Анализ состояния</h2>
                            <div className={cl.cond__form}>
                                <DateTime dt={condition?.dt} updateCondition={updateCondition}
                                    disabled={disabled}/>
                                <FormItems condition={condition} disabled={disabled}
                                    updateCondition={updateCondition}
                                    items={[{
                                        autofocus: true,
                                        key: 'g',
                                        label: 'Рост:',
                                        ext: 'см',
                                        step: 1,
                                    }, {
                                        key: 'w',
                                        label: 'Вес:',
                                        ext: 'кг',
                                        step: 1,
                                    }, {
                                        key: 'fa',
                                        label: 'Кол-во ФА:',
                                        ext: 'мг',
                                        step: 1,
                                    }, {
                                        key: 'faNeed',
                                        label: 'Потребность ФА:',
                                        ext: 'мг',
                                        step: 0.1,
                                    },
                                    ]}
                                />
                                <FormLabelOnly label='Примечание:'/>
                                <TextArea
                                    textareaClass={cl.cond__form__textarea}
                                    value={condition?.ct}
                                    change={changeComment}
                                    disabled={disabled}
                                />
                            </div>
                        </div>
                        <div>
                            <h2>Суточная норма</h2>
                            {
                                baby !== undefined ? (
                                    <CalculatorsManager gender={baby.boy == 1 ? 'male' : 'female'}
                                        condition={condition} updateCondition={updateCondition}
                                        disabled={disabled} disabledReset={disabledReset}/>
                                ) : null
                            }
                            <SmartErrorList errors={errors} resetError={resetError} />
                        </div>
                        {/* <pre style={{ overflow: 'auto' }}>
                            {JSON.stringify(condition, null, '  ')}
                        </pre> */}
                    </div>
                </PageMain.Content>
            </Page.Main>
            <Page.Footer key="button">
                <div style={{ padding: 16 }}>
                    <DuetButtons
                        first={{
                            type: 'transparent',
                            action: back,
                            content: 'Назад',
                        }}
                        second={
                            canSave ? {
                                type: 'success',
                                action: save,
                                content: 'Сохранить',
                            } : undefined
                        }
                        disabled={disabled}
                    />
                </div>
            </Page.Footer>
        </Page>
    )
}
