import React, { useCallback } from 'react'
import { SimpleButton } from '../../../../component/Form/Button/SimpleButton'
import { Condition } from '../../../../core/db_condition'
import { CalculateMacroNutrients } from '../../../../core/calc_macronutrients'
import { BaseCalculator } from './BaseCalculator'
import cl from './../index.module.scss'


type Props = {
    condition: Partial<Condition> | undefined
    updateCondition: (cond: Partial<Condition>) => void
    disabled: boolean
    disabledReset: boolean
}

export const PercentCalculator: React.FunctionComponent<Props> = ({
    condition,
    disabled, disabledReset,
    ...props }) => {
    const changeCondition = useCallback((cond: Partial<Condition>) => {
        if (cond.e !== undefined) {
            const macroNutrients = CalculateMacroNutrients(cond.e)
            cond = {
                ...cond,
                ...macroNutrients,
            }
        }
        props.updateCondition(cond)
    }, [props.updateCondition])
    const resetNutrients = useCallback(() => {
        props.updateCondition({
            p: undefined,
            f: undefined,
            c: undefined,
            p2: undefined,
            f2: undefined,
            c2: undefined,
            e: undefined,
        })
    }, [props.updateCondition])
    return (
        <>
            <BaseCalculator condition={condition}
                changeCondition={changeCondition}
                disabled={disabled} disabledReset={disabledReset} />
            <div className={cl.calc_info}>
                <div></div>
                <div>
                    <div>- белки 20-35% (1г = 4 ккал)</div>
                    <div>- жиры 20-35% (1г = 9 ккал)</div>
                    <div>- углеводы 45-65% (1г = 4 ккал)</div>
                </div>
                <div></div>
            </div>
            <div className={cl.cond__form_actions}>
                <div className={cl.cond__form_actions__btn}
                    style={{ textAlign: 'center', display: 'flex', justifyContent: 'center' }}>
                    <SimpleButton type='transparent'
                        click={resetNutrients}
                        disabled={disabled || disabledReset}
                    >Очистить</SimpleButton>
                </div>
            </div>
        </>
    )
}
