import React, { ReactNode, useEffect, useState } from 'react'
import { Page, PageMain } from '../../../component/Layout/Page'
import { HeadTitle } from '../../../component/Head/HeadTitle'
import { curTime } from '../../../core/helper'
import { DuetButtons } from '../../../component/ActionButtons/DuetButtons'
import { useNavigate } from 'react-router-dom'
import { urls } from '../../../core/urls'
import { Checkbox } from '../../../component/Form/Elements/Checkbox'
import { Acceptance, Agreement, TermsAcceptance, getTermsAgreements, sendTermsAcceptance } from '../../../core/auth'
import { addErrorIfVersionNotChanged, useErrors } from '../../../hooks/useErrors'
import { SmartErrorList } from '../../../component/SmartErrorList'
import { ApiError } from '../../../core/api'
import cl from './index.module.scss'


function handle(agreements: Agreement[]) {
    const list = agreements.map((one, i) => {
        return document.getElementById(`test${i}`)
    })
    const h = list.map(one => {
        return one?.offsetHeight || 0
    })
    list.slice(0, list.length - 1).forEach((one, i) => {
        let b = 0
        for (let index = i + 1; index < list.length; index++) {
            b += h[index]
        }
        if (one) {
            one.style.bottom = `${b}px`
        }
    })
    return Math.max(...h)
}

type StageType = 'wait_user' | 'send_acceptance' | 'sending_acceptance' | 'loading'
export const AgreementsPage: React.FunctionComponent = () => {
    const navigate = useNavigate()
    const [stage, setStage] = useState('wait_user' as StageType)
    const [errors, addError, resetError] = useErrors()
    const [unixTsStart, setUnixTsStart] = useState(curTime(true))
    const [agreements, setAgreements] = useState([] as Acceptance[])
    const [maxH, setMaxH] = useState(0)

    useEffect(() => {
        if (stage !== 'wait_user') {
            return
        }
        setStage('loading')
        getTermsAgreements().then(res => {
            if (res.result !== 'ok') {
                return Promise.reject({
                    error: -409,
                    message: 'empty agreements terms',
                    detail: 'Ошибка получения пользовательских соглашений',
                } as ApiError)
            }
            setAgreements(res.agreements.map(one => {
                return {
                    ...one,
                    accepted_unix_ts: 0,
                }
            }))
            setUnixTsStart(curTime(true))
            const h = handle.bind(null, res.agreements)
            setMaxH(h())
            window.addEventListener('resize', handle.bind(null, res.agreements))
            return () => {
                window.removeEventListener('resize', h)
            }
        }).catch(addErrorIfVersionNotChanged(addError))
            .finally(() => {
                setStage('wait_user')
            })
    }, [])
    useEffect(() => {
        if (maxH !== 0) {
            return
        }
        setMaxH(handle(agreements))
    }, [agreements])
    useEffect(() => {
        if (stage !== 'send_acceptance') {
            return
        }
        resetError()
        setStage('sending_acceptance')

        const a: TermsAcceptance = {
            start_reading_unix_ts: unixTsStart,
            accepted_agreements: agreements,
        }
        sendTermsAcceptance(a).then(res => {
            console.info('sendTermsAcceptance', res)
            if (res?.result === 'ok') {
                navigate(urls.initializeDatabase, { replace: true })
            }
        }).catch(addErrorIfVersionNotChanged(addError))
            .finally(() => {
                setStage('wait_user')
            })
    }, [stage, unixTsStart, agreements])
    const makeChangeAcceptance = (index: number) => {
        return (checked: boolean) => {
            agreements[index] = {
                ...agreements[index],
                accepted_unix_ts: checked ? curTime(true) : 0,
            }
            setAgreements([...agreements])
        }
    }
    const logout = () => {
        navigate(urls.logoutPage, { replace: true })
    }
    const next = () => {
        setStage('send_acceptance')
    }
    const disabled = stage !== 'wait_user'
    return (
        <Page hFull={true}>
            <Page.Main>
                <PageMain.Title>
                    <HeadTitle showLangs={true}/>
                </PageMain.Title>
                <PageMain.Content>
                    <div className={cl.main}>
                        <div>
                            <h1>Приветствую</h1>
                            { greetings.map(one => one) }
                        </div>
                        {
                            agreements.map((one, i) => {
                                return (
                                    <React.Fragment key={one.title}>
                                        <div>
                                            <h2>{one.title}</h2>
                                            {
                                                one.content.split('\n').map((one, i) => {
                                                    return (
                                                        <p key={one + ' ' + i}>
                                                            {parseTermsText(one)}
                                                        </p>
                                                    )
                                                })
                                            }
                                        </div>
                                        <div id={`test${i}`} className={cl.main__test}>
                                            <Checkbox checkboxId={`terms${i}`}
                                                name={`terms${i}`}
                                                checked={one.accepted_unix_ts !== undefined && one.accepted_unix_ts > 0}
                                                change={makeChangeAcceptance(i)}
                                                disabled={disabled}
                                            >{one.acceptance_text}</Checkbox>
                                        </div>
                                    </React.Fragment>

                                )
                            })
                        }
                    </div>
                    <SmartErrorList errors={errors} resetError={resetError} />
                </PageMain.Content>
            </Page.Main>
            <Page.Footer key="button">
                <div style={{ padding: 16 }}>
                    <DuetButtons
                        first={{
                            type: 'transparent',
                            action: logout,
                            content: 'Назад',
                        }}
                        second={{
                            type: 'success',
                            action: next,
                            content: 'Далее',
                            disabled: agreements.length == 0 || agreements.some(one => one.accepted_unix_ts == 0),
                        }}
                        disabled={disabled}
                    />
                </div>
            </Page.Footer>
        </Page>
    )
}

function parseTermsText(text: string): ReactNode[] {
    const regex = /\[(.*?\|.*?)\]/g
    const fragments = text.split(regex)
    return fragments.map((fragment, i) => {
        if (i % 2 === 0) {
            return <React.Fragment key={i}>{fragment}</React.Fragment>
        } else {
            const [title, href] = fragment.split('|')
            return (
                <a key={i} href={href}>{title}</a>
            )
        }
    })
}

const greetings = [
    <p key="1">Добро пожаловать в разработанный мной сервис контроля соблюдения диетотерапии ФКУ!</p>,
    // eslint-disable-next-line max-len
    <p key="2">Прежде чем вы наконец приступите к работе с сервисом, пожалуйста, внимательно ознакомьтесь с документами ниже перед тем, как начать его использовать.</p>,
    // eslint-disable-next-line max-len
    <p key="3">Я стараюсь предоставить вам лучший сервис и он работает с 2012 года. Сегодня я запускаю бета-тестирование новой версии и приглашаю вас присоединиться в этом путешествии, рассмотрев возможность <a href="https://telegra.ph/PKU-Diet-Donate-11-01">финансовой помощи проекту</a>. С вашей поддержкой сервис не только сможет продолжать свою работу оставаясь бесплатным, но и я смогу развивать сервис, достигнув главной цели: сделать самый лучший в мире инструмент для контроля диетотерапии и сделать процесс контроля питания более комфортным и эффективным.</p>,
    // eslint-disable-next-line max-len
    <p key="4">Спасибо, что выбрали мой сервис мониторинга соблюдения диеты при ФКУ. Желаю вам успехов в управлении соблюдением диеты и надеюсь, что мой сервис станет вам полезным помощником. Если у вас возникнут вопросы или предложения, всегда готов вам помочь (<a href="https://t.me/+8fSwZ0sS-XEzMTEy">чат проекта</a>).</p>,
]