import { useEffect, useRef, useState } from 'react'
import { Condition } from '../core/db_condition'
import { loadConditions } from '../core/conditions'
import { ApiError } from '../core/api'
import { addErrorIfVersionNotChanged } from './useErrors'
import { RequestError } from '../component/SmartError'
import { useTablesCounts } from './useDatabase'

type StatusType = 'wait' | 'load_more' | 'loading' | 'ready'
const limit = 30
export function useConditionList(
    babyId: number | undefined,
    addError: (err: ApiError) => void,
    resetError: (err?: RequestError | undefined) => void,
): [Condition[], (() => void) | undefined] {
    const [list, setList] = useState([] as Condition[])
    const [status, setStatus] = useState('wait' as StatusType)
    const [offset, setOffset] = useState(-1)
    const [tablesCounts] = useTablesCounts()
    const v = useRef(0)
    const load = async (babyId: number, from: number, limit?: number) => {
        v.current += 1
        const version = v.current
        const list = await loadConditions(babyId, from, limit)
        if (version != v.current) {
            return Promise.reject('version_changed')
        }
        return list
    }
    useEffect(() => {
        if (!babyId) {
            return
        }
        if (offset < 0) {
            return
        }
        if (offset === 0) {
            setList([])
        }
        if (status != 'load_more') {
            return
        }
        resetError()
        setStatus('loading')
        load(babyId, offset, limit)
            .then((res) => {
                return new Promise<number>((resolve, reject) => {
                    setList(list => {
                        if (res.length === 0) {
                            resolve(-1 as number)
                        } else {
                            resolve(offset + limit as number)
                        }
                        if (offset > 0) {
                            return [...list.concat(...res)]
                        }
                        return res
                    })
                })
            }).then(offset => {
                setOffset(offset)
            })
            .catch(addErrorIfVersionNotChanged(addError))
            .finally(() => {
                setStatus('ready')
            })
    }, [status])
    useEffect(() => {
        if (!babyId) {
            return
        }
        setOffset(0)
        setStatus('load_more')
    }, [babyId])
    useEffect(() => {
        if (!tablesCounts || offset < -1) {
            return
        }
        if (tablesCounts.conditions <= offset) {
            setOffset(-1)
        }
    }, [offset, tablesCounts])

    const loadMore = status == 'ready' && offset >= 0 ? () => {
        setStatus('load_more')
    } : undefined
    return [list, loadMore]
}