/* eslint-disable max-len */
import { Condition, FromToNutrientsCondition } from './db_condition'
import { Nutrients } from './db_food'


export type Age = {
    years: number
    months: number
}
export type Gender = 'male' | 'female'
export type FromToNutrientsAndEnergy = FromToNutrientsCondition & Omit<Nutrients, 'fa' | 'p' | 'f' | 'c'> & {
    pMed?: number
    pMed2?: number
}
interface INutrientsByCondition {
    fit(age: Age, gender: Gender, cond: Condition, activity: number): boolean
    calc(cond: Condition, activity: number): FromToNutrientsAndEnergy
    canEdit(): boolean
    nutrientsPercents(): Partial<FromToNutrientsAndEnergy> | undefined
}
abstract class AbstractNutrientsByCondition implements INutrientsByCondition {
    abstract canEdit(): boolean
    abstract fit(age: Age, gender: Gender, cond: Condition, activity: number): boolean
    abstract calc(cond: Condition, activity: number): FromToNutrientsAndEnergy
    abstract calcWithoutEnergy(cond: Condition, activity: number, energy: number): FromToNutrientsAndEnergy
    abstract nutrientsPercents(): Partial<FromToNutrientsAndEnergy> | undefined
    static suitableAge(age: Age, fromAge: Age, toAge: Age, includeStart: boolean = true, includeEnd: boolean = false) {
        const x = age.years * 12 + age.months
        const fromX = fromAge.years * 12 + fromAge.months
        const toX = toAge.years * 12 + toAge.months
        if (fromX < x && x < toX) {
            return true
        }
        if (includeStart && x == fromX) {
            return true
        }
        if (includeEnd && x == toX) {
            return true
        }
        return false
    }
}

class ByKGBaby implements AbstractNutrientsByCondition {
    constructor(
        public ageFrom: Age,
        public ageTo: Age,
        public energy: number,
        public proteins: number,
        public fats: number,
        public carbohydrates: number,
    ) { }
    canEdit(): boolean {
        return false
    }
    fit(age: Age, gender: Gender, cond: Condition, activity: number): boolean {
        return AbstractNutrientsByCondition.suitableAge(age, this.ageFrom, this.ageTo, true, true)
    }
    calc(cond: Condition, activity: number): FromToNutrientsAndEnergy {
        const weight = cond.w
        return {
            p: Math.floor(this.proteins * weight),
            p2: Math.ceil(this.proteins * weight),
            c: Math.floor(this.carbohydrates * weight),
            c2: Math.ceil(this.carbohydrates * weight),
            f: Math.floor(this.fats * weight),
            f2: Math.ceil(this.fats * weight),
            e: Math.round(this.energy * weight),
        }
    }
    calcWithoutEnergy(cond: Condition, activity: number, energy: number): FromToNutrientsAndEnergy {
        const weight = cond.w
        return {
            p: Math.floor(this.proteins * weight),
            p2: Math.ceil(this.proteins * weight),
            c: Math.floor(this.carbohydrates * weight),
            c2: Math.ceil(this.carbohydrates * weight),
            f: Math.floor(this.fats * weight),
            f2: Math.ceil(this.fats * weight),
            e: Math.round(this.energy * weight),
        }
    }
    nutrientsPercents(): Partial<FromToNutrientsAndEnergy> | undefined {
        return undefined
    }
}

class ByAgeAndGenderChildren implements AbstractNutrientsByCondition {
    constructor(
        public gender: Gender | undefined,
        public ageFrom: Age,
        public ageTo: Age,
        public norms: {
            energy: number
            proteins: number
            proteinsMed: number
            fats: number
            carbohydrates: number
        },
    ) {
        this.norms.proteinsMed = this.norms.proteinsMed / this.norms.proteins
        this.norms.proteins = 0.12
        this.norms.fats = 0.3
        this.norms.carbohydrates = 0.58
    }
    canEdit(): boolean {
        return true
    }
    fit(age: Age, gender: Gender, cond: Condition, activity: number): boolean {
        if (this.gender !== undefined) {
            if (this.gender != gender) {
                return false
            }
        }
        return AbstractNutrientsByCondition.suitableAge(age, this.ageFrom, this.ageTo, true, false)
    }
    calc(cond: Condition, activity: number): FromToNutrientsAndEnergy {
        const p = Math.floor(this.norms.energy * this.norms.proteins / 4)
        const p2 = Math.ceil(this.norms.energy * this.norms.proteins / 4)
        return {
            e: this.norms.energy,
            p,
            p2,
            c: Math.floor(this.norms.energy * this.norms.carbohydrates / 4),
            c2: Math.ceil(this.norms.energy * this.norms.carbohydrates / 4),
            f: Math.floor(this.norms.energy * this.norms.fats / 9),
            f2: Math.ceil(this.norms.energy * this.norms.fats / 9),
            pMed: Math.floor(p * this.norms.proteinsMed),
            pMed2: Math.ceil(p2 * this.norms.proteinsMed),
        }
    }
    calcWithoutEnergy(cond: Condition, activity: number, energy: number): FromToNutrientsAndEnergy {
        const p = Math.floor(energy * this.norms.proteins / 4)
        const p2 = Math.ceil(energy * this.norms.proteins / 4)
        return {
            e: energy,
            p,
            p2,
            c: Math.floor(energy * this.norms.carbohydrates / 4),
            c2: Math.ceil(energy * this.norms.carbohydrates / 4),
            f: Math.floor(energy * this.norms.fats / 9),
            f2: Math.ceil(energy * this.norms.fats / 9),
            pMed: Math.floor(p * this.norms.proteinsMed),
            pMed2: Math.ceil(p2 * this.norms.proteinsMed),
        }
    }
    nutrientsPercents(): Partial<FromToNutrientsAndEnergy> | undefined {
        return {
            p: this.norms.proteins,
            f: this.norms.fats,
            c: this.norms.carbohydrates,
        }
    }
}

class ByAgeGenderAndActivityAdults implements AbstractNutrientsByCondition {
    constructor(
        public gender: Gender | undefined,
        public ageFrom: Age,
        public ageTo: Age | undefined,
        public norms: {
            energy: number
            proteins: number
            fats: number
            carbohydrates: number,
            activity: number
        },
    ) { }
    canEdit(): boolean {
        return true
    }
    nutrientsPercents(): Partial<FromToNutrientsAndEnergy> | undefined {
        return {
            p: this.norms.proteins,
            f: this.norms.fats,
            c: this.norms.carbohydrates,
        }
    }
    fit(age: Age, gender: Gender, cond: Condition, activity: number): boolean {
        if (this.norms.activity !== activity) {
            return false
        }
        if (this.gender !== undefined) {
            if (this.gender != gender) {
                return false
            }
        }
        const ageMonth = this.ageInMonths(age)
        if (this.ageInMonths(this.ageFrom) > ageMonth) {
            return false
        }
        if (this.ageTo === undefined) {
            return true
        }
        return AbstractNutrientsByCondition.suitableAge(age, this.ageFrom, this.ageTo, true, false)
    }
    calc(cond: Condition, activity: number): FromToNutrientsAndEnergy {
        return {
            e: this.norms.energy,
            p: Math.floor(this.norms.energy * this.norms.proteins / 4),
            p2: Math.ceil(this.norms.energy * this.norms.proteins / 4),
            c: Math.floor(this.norms.energy * this.norms.carbohydrates / 4),
            c2: Math.ceil(this.norms.energy * this.norms.carbohydrates / 4),
            f: Math.floor(this.norms.energy * this.norms.fats / 9),
            f2: Math.ceil(this.norms.energy * this.norms.fats / 9),
        }
    }
    calcWithoutEnergy(cond: Condition, activity: number, energy: number): FromToNutrientsAndEnergy {
        return {
            e: energy,
            p: Math.floor(energy * this.norms.proteins / 4),
            p2: Math.ceil(energy * this.norms.proteins / 4),
            c: Math.floor(energy * this.norms.carbohydrates / 4),
            c2: Math.ceil(energy * this.norms.carbohydrates / 4),
            f: Math.floor(energy * this.norms.fats / 9),
            f2: Math.ceil(energy * this.norms.fats / 9),
        }
    }
    protected ageInMonths(age: Age) {
        return age.years * 12 + age.months
    }


}

export function CalculateMacroNutrientsFindHandler(age: Age, gender: Gender, cond: Condition | undefined, activity: number): AbstractNutrientsByCondition | undefined {
    if (!cond) {
        return undefined
    }
    return mzTable.find(one => one.fit(age, gender, cond, activity))
}
export function CalculateMacroNutrients(
    age: Age, gender: Gender, cond: Condition | undefined, activity: number,
): Partial<FromToNutrientsAndEnergy> {
    const handler = CalculateMacroNutrientsFindHandler(age, gender, cond, activity)
    if (!handler || !cond) {
        return {}
    }
    return handler.calc(cond, activity)
}
export function CalculateMacroNutrientsByEnergy(
    age: Age, gender: Gender, cond: Condition | undefined, activity: number, energy: number,
): Partial<FromToNutrientsAndEnergy> {
    const handler = CalculateMacroNutrientsFindHandler(age, gender, cond, activity)
    if (!handler || !cond) {
        return {}
    }
    return handler.calcWithoutEnergy(cond, activity, energy)
}

const mzTable = [
    new ByKGBaby({ years: 0, months: 0 }, { years: 0, months: 3 }, 115, 2.2, 6.5, 13),
    new ByKGBaby({ years: 0, months: 4 }, { years: 0, months: 6 }, 115, 2.6, 6.0, 13),
    new ByKGBaby({ years: 0, months: 7 }, { years: 0, months: 12 }, 110, 2.9, 5.5, 13),
    new ByAgeAndGenderChildren(undefined, { years: 1, months: 0 }, { years: 2, months: 0 }, { energy: 1200, proteins: 36, proteinsMed: 28, fats: 40, carbohydrates: 174 }),
    new ByAgeAndGenderChildren(undefined, { years: 2, months: 0 }, { years: 3, months: 0 }, { energy: 1400, proteins: 42, proteinsMed: 33, fats: 47, carbohydrates: 203 }),
    new ByAgeAndGenderChildren(undefined, { years: 3, months: 0 }, { years: 7, months: 0 }, { energy: 1800, proteins: 54, proteinsMed: 46, fats: 60, carbohydrates: 261 }),
    new ByAgeAndGenderChildren(undefined, { years: 7, months: 0 }, { years: 11, months: 0 }, { energy: 2100, proteins: 63, proteinsMed: 54, fats: 70, carbohydrates: 305 }),
    new ByAgeAndGenderChildren('male', { years: 11, months: 0 }, { years: 14, months: 0 }, { energy: 2500, proteins: 75, proteinsMed: 64, fats: 83, carbohydrates: 363 }),
    new ByAgeAndGenderChildren('female', { years: 11, months: 0 }, { years: 14, months: 0 }, { energy: 2300, proteins: 69, proteinsMed: 59, fats: 77, carbohydrates: 334 }),
    new ByAgeAndGenderChildren('male', { years: 14, months: 0 }, { years: 18, months: 0 }, { energy: 2900, proteins: 87, proteinsMed: 74, fats: 97, carbohydrates: 421 }),
    new ByAgeAndGenderChildren('female', { years: 14, months: 0 }, { years: 18, months: 0 }, { energy: 2500, proteins: 76, proteinsMed: 64, fats: 83, carbohydrates: 363 }),
    // adults
    // male 18-64 1.4
    new ByAgeGenderAndActivityAdults('male', { years: 18, months: 0 }, { years: 29, months: 12 }, { activity: 1.4, energy: 2400, proteins: 0.14, fats: 0.3, carbohydrates: 0.56 }),
    new ByAgeGenderAndActivityAdults('male', { years: 30, months: 0 }, { years: 44, months: 12 }, { activity: 1.4, energy: 2300, proteins: 0.14, fats: 0.3, carbohydrates: 0.56 }),
    new ByAgeGenderAndActivityAdults('male', { years: 45, months: 0 }, { years: 64, months: 12 }, { activity: 1.4, energy: 2150, proteins: 0.14, fats: 0.3, carbohydrates: 0.56 }),

    // male 18-64 1.6
    new ByAgeGenderAndActivityAdults('male', { years: 18, months: 0 }, { years: 29, months: 12 }, { activity: 1.6, energy: 2750, proteins: 0.13, fats: 0.3, carbohydrates: 0.57 }),
    new ByAgeGenderAndActivityAdults('male', { years: 30, months: 0 }, { years: 44, months: 12 }, { activity: 1.6, energy: 2650, proteins: 0.13, fats: 0.3, carbohydrates: 0.57 }),
    new ByAgeGenderAndActivityAdults('male', { years: 45, months: 0 }, { years: 64, months: 12 }, { activity: 1.6, energy: 2450, proteins: 0.13, fats: 0.3, carbohydrates: 0.57 }),

    // male 18-64 1.9
    new ByAgeGenderAndActivityAdults('male', { years: 18, months: 0 }, { years: 29, months: 12 }, { activity: 1.9, energy: 3250, proteins: 0.125, fats: 0.3, carbohydrates: 0.575 }),
    new ByAgeGenderAndActivityAdults('male', { years: 30, months: 0 }, { years: 44, months: 12 }, { activity: 1.9, energy: 3150, proteins: 0.125, fats: 0.3, carbohydrates: 0.575 }),
    new ByAgeGenderAndActivityAdults('male', { years: 45, months: 0 }, { years: 64, months: 12 }, { activity: 1.9, energy: 2900, proteins: 0.125, fats: 0.3, carbohydrates: 0.575 }),

    // male 18-64 2.2
    new ByAgeGenderAndActivityAdults('male', { years: 18, months: 0 }, { years: 29, months: 12 }, { activity: 2.2, energy: 3800, proteins: 0.12, fats: 0.3, carbohydrates: 0.58 }),
    new ByAgeGenderAndActivityAdults('male', { years: 30, months: 0 }, { years: 44, months: 12 }, { activity: 2.2, energy: 3650, proteins: 0.12, fats: 0.3, carbohydrates: 0.58 }),
    new ByAgeGenderAndActivityAdults('male', { years: 45, months: 0 }, { years: 64, months: 12 }, { activity: 2.2, energy: 3400, proteins: 0.12, fats: 0.3, carbohydrates: 0.58 }),

    // male 64+ 1.7
    new ByAgeGenderAndActivityAdults('male', { years: 65, months: 0 }, { years: 74, months: 12 }, { activity: 1.7, energy: 2400, proteins: 0.14, fats: 0.3, carbohydrates: 0.56 }),
    new ByAgeGenderAndActivityAdults('male', { years: 75, months: 0 }, undefined, { activity: 1.7, energy: 2300, proteins: 0.14, fats: 0.3, carbohydrates: 0.56 }),

    // FEMALE
    // female 18-64 1.4
    new ByAgeGenderAndActivityAdults('female', { years: 18, months: 0 }, { years: 29, months: 12 }, { activity: 1.4, energy: 1900, proteins: 0.14, fats: 0.3, carbohydrates: 0.56 }),
    new ByAgeGenderAndActivityAdults('female', { years: 30, months: 0 }, { years: 44, months: 12 }, { activity: 1.4, energy: 1800, proteins: 0.14, fats: 0.3, carbohydrates: 0.56 }),
    new ByAgeGenderAndActivityAdults('female', { years: 45, months: 0 }, { years: 64, months: 12 }, { activity: 1.4, energy: 1700, proteins: 0.14, fats: 0.3, carbohydrates: 0.56 }),

    // female 18-64 1.6
    new ByAgeGenderAndActivityAdults('female', { years: 18, months: 0 }, { years: 29, months: 12 }, { activity: 1.6, energy: 2200, proteins: 0.13, fats: 0.3, carbohydrates: 0.57 }),
    new ByAgeGenderAndActivityAdults('female', { years: 30, months: 0 }, { years: 44, months: 12 }, { activity: 1.6, energy: 2100, proteins: 0.13, fats: 0.3, carbohydrates: 0.57 }),
    new ByAgeGenderAndActivityAdults('female', { years: 45, months: 0 }, { years: 64, months: 12 }, { activity: 1.6, energy: 1950, proteins: 0.13, fats: 0.3, carbohydrates: 0.57 }),

    // female 18-64 1.9
    new ByAgeGenderAndActivityAdults('female', { years: 18, months: 0 }, { years: 29, months: 12 }, { activity: 1.9, energy: 2600, proteins: 0.125, fats: 0.3, carbohydrates: 0.575 }),
    new ByAgeGenderAndActivityAdults('female', { years: 30, months: 0 }, { years: 44, months: 12 }, { activity: 1.9, energy: 2500, proteins: 0.125, fats: 0.3, carbohydrates: 0.575 }),
    new ByAgeGenderAndActivityAdults('female', { years: 45, months: 0 }, { years: 64, months: 12 }, { activity: 1.9, energy: 2300, proteins: 0.125, fats: 0.3, carbohydrates: 0.575 }),

    // female 18-64 2.2
    new ByAgeGenderAndActivityAdults('female', { years: 18, months: 0 }, { years: 29, months: 12 }, { activity: 2.2, energy: 3000, proteins: 0.12, fats: 0.3, carbohydrates: 0.58 }),
    new ByAgeGenderAndActivityAdults('female', { years: 30, months: 0 }, { years: 44, months: 12 }, { activity: 2.2, energy: 2850, proteins: 0.12, fats: 0.3, carbohydrates: 0.58 }),
    new ByAgeGenderAndActivityAdults('female', { years: 45, months: 0 }, { years: 64, months: 12 }, { activity: 2.2, energy: 2700, proteins: 0.12, fats: 0.3, carbohydrates: 0.58 }),

    // female 64+ 1.7
    new ByAgeGenderAndActivityAdults('female', { years: 65, months: 0 }, { years: 74, months: 12 }, { activity: 1.7, energy: 1900, proteins: 0.14, fats: 0.3, carbohydrates: 0.56 }),
    new ByAgeGenderAndActivityAdults('female', { years: 75, months: 0 }, undefined, { activity: 1.7, energy: 1800, proteins: 0.14, fats: 0.3, carbohydrates: 0.56 }),

]
