import React, { useEffect, useRef, useState } from 'react'
import { useLoaderData } from 'react-router-dom'
import { dbManager } from '../core/db'
import { Mealtime, MealtimeDish } from '../core/db_mealtime'
import { curTime, dtDate, dtToUnix } from '../core/helper'
import { ApiError } from '../core/api'
import { MealtimeDraft } from '../core/draft'
import { EdibleItem, EdibleItemsManager } from '../core/edibleItemsManager'
import { AbstractManager } from '../core/abstractManager'
import { addErrorIfVersionNotChanged } from './useErrors'
import { LastFoodManager } from '../core/lastFood'
import { MealtimeTypeManager } from './useMealtimeTypes'
import { MealtimeType } from '../core/db_mealtime_type'


export function useMealtime(addError: (err: ApiError) => void, mts?: Record<number, MealtimeType>): [
        MealtimeManager | undefined,
        Mealtime | undefined,
        React.Dispatch<React.SetStateAction<Mealtime | undefined>
    >] {
    const data = useLoaderData() as { mealtimeId: number, babyId: number, mealtimeManager: MealtimeManager }
    const [mealtime, setMealtime] = useState(undefined as Mealtime | undefined)
    const [manager, setManager] = useState(undefined as MealtimeManager | undefined)
    const v = useRef(0)
    useEffect(() => {
        if (mealtime) {
            return
        }
        if (!mts) {
            return
        }
        v.current += 1
        const version = v.current
        createMealtimeManager(data.babyId, data.mealtimeId, mts)
            .then(manager => {
                if (version !== v.current) {
                    return Promise.reject('version_changed')
                }
                setMealtime(manager.getCurrent())
                setManager(manager)
            }).catch(addErrorIfVersionNotChanged(addError))
    }, [data, mts])
    return [manager, mealtime, setMealtime]
}


export async function createMealtimeManager(
    babyId: number, mealtimeId: number, mts?: Record<number, MealtimeType>): Promise<MealtimeManager> {
    const draft = new MealtimeDraft(mealtimeId, babyId)
    if (mealtimeId == -1) { // isNew
        if (!mts) {
            throw Error('mealtime types not loaded')
        }
        const initMealtimeId = draft.getNextDraftId()
        const newMealtime = new MealtimeManager(babyId, initMealtimeId, emptyMealtime(babyId, mts))
        return newMealtime
    }
    if (mealtimeId < -1 || draft.haveDraft()) { // isDraft
        const dm = draft.loadItem()
        if (dm && dm.current) {
            const manager = new MealtimeManager(babyId, mealtimeId, dm.current)
            manager.setBase(dm.base)
            return manager
        }
    }
    const mealtime = await dbManager.db.mealtimes.where(['id', 'b'])
        .equals([mealtimeId, babyId])
        .first() as Mealtime | undefined
    if (mealtime) {
        const manager = new MealtimeManager(babyId, mealtimeId, mealtime)
        manager.setBase(JSON.parse(JSON.stringify(mealtime)))
        return manager

    }
    return Promise.reject({
        error: -101,
        message: 'No mealtime',
        detail: 'Mealtime не найден',
    } as ApiError)
}


export class MealtimeManager extends AbstractManager<Mealtime, MealtimeDraft> {
    constructor(
        private initBabyId: number,
        initId: number,
        current: Mealtime) {
        super(initId, current, new MealtimeDraft(initId, initBabyId))
        this.edibleManager = new EdibleItemsManager(EdibleItemsManager.fromMealtimeDishes(current.l))
    }

    public hasComePlanned(): boolean {
        if (!this.current) {
            return false
        }
        return MealtimeManager.hasComePlanned(this.current)
    }
    static hasComePlanned(mealtime: Mealtime): boolean {
        if (mealtime.n !== 1) {
            return false
        }
        const dt = dtDate(mealtime.dt)
        const cur = new Date()
        dt.setSeconds(0)
        dt.setMilliseconds(0)
        return dt < cur
    }

    public hasChanges(): boolean {
        if (this.base === undefined) {
            if (this.current.l.length == 0) {
                return false
            }
        }
        return super.hasChanges()
    }

    public eaten() {
        if (!this.current) {
            return
        }
        this.current.n = 0
    }

    public async save(): Promise<boolean> {
        const nut = this.edibleManager.getTotalNutrients()
        this.current.p = nut.p
        this.current.f = nut.f
        this.current.c = nut.c
        this.current.fa = nut.fa
        this.current.e = nut.e
        this.current.l = this.edibleManager.getAsMealtimeDishes()
        if (this.current.n === undefined) {
            this.current.n = this.current.dt > dtToUnix(new Date(), true) ? 1 : 0
        }
        const m = await dbManager.db.mealtimes.where(['id', 'b']).equals([this.current.id, this.initBabyId]).first()
        if (!m) {
            await dbManager.db.mealtimes.add(this.current)
        } else {
            await dbManager.db.mealtimes.put(this.current)
        }
        this.base = JSON.parse(JSON.stringify(this.current))
        await this.updateLastFood()
        return true
    }

    private async updateLastFood() {
        const mngr = new LastFoodManager(EdibleItemsManager.fromMealtimeDishes(this.current.l))
        return await mngr.save()
    }

    public async delete(): Promise<boolean> {
        await dbManager.db.mealtimes.delete(this.current.id)
        return true
    }

    public hasDraft() {
        if (this.initId < -1) {
            return true
        }
        return this.draft.haveDraft()
    }

    getEdibleItems(val: Mealtime): EdibleItem[] {
        return EdibleItemsManager.fromMealtimeDishes(val.l)
    }
    applyEdibleItems(val: Mealtime): void {
        val.l = this.edibleManager.getAsMealtimeDishes()
    }
}

function emptyMealtime(babyId: number, mts: Record<number, MealtimeType>) {
    const ct = curTime(true)
    return {
        id: ct,
        dt: ct,
        t: MealtimeTypeManager.calcTypeByTime(ct, mts).id,
        b: babyId,
        p: 0,
        f: 0,
        c: 0,
        fa: 0,
        e: 0,
        l: [] as MealtimeDish[],
        n: undefined,
        ct: '',
        d: 0,
    } as Mealtime
}