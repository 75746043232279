import React, { useCallback, useState } from 'react'
import { Condition } from '../../../../core/db_condition'
import { PercentCalculator } from './PercentCalculator'
import { SimpleButton } from '../../../../component/Form/Button/SimpleButton'
import { FromInput } from '../../FoodEditPage/SimpleFood'
import { MZCalculator } from './MZCalculator'
import { Gender } from '../../../../core/calc_macronutrients_mz'
import { BaseCalculator } from './BaseCalculator'
import cl from './CalculatorsManager.module.scss'


type Props = {
    gender: Gender
    condition: Condition | undefined
    updateCondition: (cond: Partial<Condition>) => void
    disabled: boolean
    disabledReset: boolean
}

type MethodType = 'mz_rf' | 'base' | 'show'
export const CalculatorsManager: React.FunctionComponent<Props> = ({
    gender,
    condition,
    updateCondition,
    disabled, disabledReset,
}) => {
    const [method, setMethod] = useState<MethodType>('show')
    const [age, setAge] = useState<{ years: number, months: number }>({ years: 0, months: 0 })
    const resetNutrients = useCallback(() => {
        updateCondition({
            p: undefined,
            f: undefined,
            c: undefined,
            p2: undefined,
            f2: undefined,
            c2: undefined,
            e: undefined,
        })
    }, [updateCondition])
    const changeEars = (name: 'years' | 'months', val: number) => {
        if (name == 'years') {
            if (val === 0) {
                setAge({
                    years: 0,
                    months: 12,
                })
            }
            setAge(old => {
                return {
                    ...old,
                    years: val,
                }
            })
        } else if (val > 12) {
            const addYears = Math.floor(val / 12)
            const months = val - addYears * 12
            setAge(old => {
                return {
                    years: old.years + addYears,
                    months,
                }
            })
        } else {
            setAge(old => {
                return {
                    ...old,
                    months: val,
                }
            })
        }
    }
    return (
        <div className={cl.manager}>
            <div className={cl.manager__methods}>
                <SimpleButton type={method == 'show' ? 'light-success' : 'transparent'}
                    size='small' click={() => { setMethod('show') }}>Ручной ввод</SimpleButton>
                <SimpleButton type={method == 'mz_rf' ? 'light-success' : 'transparent'}
                    size='small' click={() => { setMethod('mz_rf') }}>Табличные данные</SimpleButton>
                {/* <SimpleButton type={method == 'base' ? 'light-success' : 'transparent'}
                    size='small' click={() => { setMethod('base') }}>
                    <div>
                        <div>Таблицы</div>
                        <div>% от ккал</div>
                    </div>
                </SimpleButton> */}
            </div>
            {
                method === 'mz_rf' ? (
                    <>
                        <div>
                            <div className='form_label' style={{ textAlign: 'center', padding: 10 }}>Расчёт для возраста</div>
                            <div className={cl.manager__age}>
                                <FromInput label='Полных лет:' labelExt=''
                                    value={age.years} change={changeEars.bind(null, 'years')} step={1} disabled={disabled} />
                                <FromInput label='Месяцев:' labelExt=''
                                    value={age.months} change={changeEars.bind(null, 'months')} step={1} disabled={disabled} />
                            </div>
                        </div>
                        <MZCalculator gender={gender} age={age}
                            condition={condition} updateCondition={updateCondition}
                            disabled={disabled} disabledReset={disabledReset} />
                    </>
                ) : null
            }

            {
                method == 'base' ? <PercentCalculator condition={condition} updateCondition={updateCondition}
                    disabled={disabled} disabledReset={disabledReset} /> : null
            }
            {
                method == 'show' ? (
                    <>
                        <BaseCalculator condition={condition} changeCondition={() => { }}
                            disabled={disabled} disabledReset={disabledReset} />
                        <div className={cl.cond__form_actions} style={{ marginTop: 8 }}>
                            <div className={cl.cond__form_actions__btn}
                                style={{ textAlign: 'center', display: 'flex', justifyContent: 'center' }}>
                                <SimpleButton type='transparent'
                                    click={resetNutrients}
                                    disabled={disabled || disabledReset}
                                >Очистить</SimpleButton>
                            </div>
                        </div>
                    </>
                ) : null
            }

        </div>
    )
}
