import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useLiveQuery } from 'dexie-react-hooks'
import { dbManager } from '../../../core/db'
import { Baby } from '../../../core/db_baby'
import { BabyItem } from '../../../component/BabyItem'
import { urls } from '../../../core/urls'
import { BottomMenu } from '../../../component/BottomMenu'
import { Page, PageMain } from '../../../component/Layout/Page'
import { ActionButtons } from '../../../component/ActionButtons'
import { AddButton } from '../../../component/ActionButtons/RoundButton'
import { HeadTitle } from '../../../component/Head/HeadTitle'
import cl from './index.module.scss'


export const ChildrenPage: React.FC = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const children: Baby[] | undefined = useLiveQuery(() => dbManager.db.babies.toArray())
    const onSelectBaby = (baby: Baby) => {
        navigate(urls.mealtimePage(baby.id))
    }
    const onCreateNew = () => {
        navigate(urls.editBabyPage('new') + '#' + location.pathname, { replace: true })
    }
    return (
        <Page>
            <Page.Main>
                <PageMain.Title>
                    <HeadTitle />
                </PageMain.Title>
                <PageMain.Content>
                    <h1 className='title'
                        style={{ position: 'sticky', top: 0, zIndex: 11 }}
                    >Список людей</h1>
                    <div className={cl.class}>
                        {
                            children?.map(baby => {
                                return <BabyItem key={`${baby.id}`} onClick={onSelectBaby} baby={baby} />
                            })
                        }
                    </div>
                    <ActionButtons>
                        <AddButton onClick={onCreateNew}/>
                    </ActionButtons>
                </PageMain.Content>
            </Page.Main>
            <Page.Footer key="button-menu">
                <BottomMenu key="button-menu" selected='children' />
            </Page.Footer>
        </Page>
    )
}
