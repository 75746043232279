import { useEffect, useState } from 'react'
import { MealtimeType } from '../core/db_mealtime_type'
import { dbManager } from '../core/db'
import { dtDate, dtToUnix, leadZero } from '../core/helper'

export function useMealtimeTypes(): [
    Record<number, MealtimeType> | undefined,
    React.Dispatch<React.SetStateAction<Record<number, MealtimeType>| undefined>>
    ] {
    const [types, setTypes] = useState(undefined as Record<number, MealtimeType> | undefined)
    useEffect(() => {
        dbManager.db.mealtimeTypes.toArray().then((res) => {
            return (res as MealtimeType[]).reduce((r, o) => {
                r[o.id] = o
                return r
            }, {} as Record<number, MealtimeType>)
        }).then(res => {
            setTypes(res)
        })
    }, [])
    return [types, setTypes]
}

export class MealtimeTypeManager {
    static changeTime(dtm: number, time: string): number | undefined {
        const dt = dtDate(dtm)
        const [h, m] = strToHoursAndMinutes(time)
        dt.setHours(h)
        dt.setMinutes(m)
        return dtToUnix(dt, true)

    }

    static calcTimeByType(dtm: number, slot: MealtimeType) {
        const dt = dtDate(dtm)
        return getNearestTimeInSlot(dt, slot)
    }

    static calcTypeByTime(dt: number, mts: Record<number, MealtimeType>) {
        return findTimeSlot(dtDate(dt), mts)
    }

    static dtToTimeString(dt: number): string {
        const d = dtDate(dt)
        return `${leadZero(d.getHours())}:${leadZero(d.getMinutes())}`
    }
}

function findTimeSlot(date: Date, timeSlots: Record<number, MealtimeType>): MealtimeType {
    const hour = date.getHours()
    const minute = date.getMinutes()
    const cur = hour + minute / 60

    let closestSlot: MealtimeType | null = null
    let closestSlotDistance = Infinity

    Object.values(timeSlots).forEach(slot => {
        const tfp = hmToPoint(strToHoursAndMinutes(slot.tf))
        const ttp = hmToPoint(strToHoursAndMinutes(slot.tt))
        if (tfp <= ttp) {
            if (cur >= tfp && cur <= ttp) {
                const d = Math.min(cur - tfp, ttp - cur)
                if (d < closestSlotDistance) {
                    closestSlotDistance = d
                    closestSlot = slot
                    return
                }

            }
        } else {
            if (cur >= tfp && cur >= ttp) {
                const d = Math.min(distance(tfp, cur), distance(cur, ttp))
                if (d < closestSlotDistance) {
                    closestSlotDistance = d
                    closestSlot = slot
                    return
                }
            }
        }
    })

    if (closestSlot) {
        return closestSlot
    }

    Object.values(timeSlots).forEach(slot => {
        const tfp = hmToPoint(strToHoursAndMinutes(slot.tf))
        const ttp = hmToPoint(strToHoursAndMinutes(slot.tt))
        const d = Math.min(distance(tfp, cur), distance(cur, ttp))
        if (d < closestSlotDistance) {
            closestSlotDistance = d
            closestSlot = slot
            return
        }
    })
    if (!closestSlot) {
        throw Error('aaaa что то пошло не так')
    }
    return closestSlot
}

function getNearestTimeInSlot(date: Date, slot: MealtimeType): Date {
    const [tfHour, tfMinute] = slot.tf.split(':').map(Number)
    const [ttHour, ttMinute] = slot.tt.split(':').map(Number)
    const tf = tfHour + tfMinute / 60
    const tt = ttHour + ttMinute / 60

    const interval = distance(tf, tt) / 2
    const p = tf + interval > 24 ? tf + interval - 24 : tf + interval
    const h = Math.floor(p)
    const m = Math.floor((p - h) * 60)
    const dt = new Date(date.getTime())
    dt.setHours(h)
    dt.setMinutes(m)
    return dt
}


function strToHoursAndMinutes(time: string): [number, number] {
    return time.split(':').map(Number) as [number, number]
}

function hmToPoint(hm: [number, number]): number {
    return hm[0] + hm[1] / 60
}

function distance(point1: number, point2: number): number {
    return point1 < point2 ? Math.abs(point1 - point2) : Math.abs(24 - point1 + point2)
}