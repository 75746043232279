import React, { useState } from 'react'
import { InputNumber } from '../../../../component/Form/Elements/InputNumber'
// import cl from './index.module.scss';


type Props = {
    value?: number
    step: number
    disabled: boolean
    tabIndex?: number
    autofocus?: boolean
    change: (value: number) => void
}

export const DishNumber: React.FunctionComponent<Props> = (props) => {
    const [vis, setVis] = useState(0)
    return (
        <div style={{ flex: 1, maxWidth: 50, textAlign: 'center', position: 'relative' }}>
            <InputNumber {...props}
                style={{
                    marginTop: -4,
                    textAlign: 'center',
                    opacity: vis }}
                focus={() => setVis(1)}
                blur={() => setVis(0)}
                disabled={props.disabled}/>
            <div className='font-roboto font-14' style={{
                fontStyle: 'italic',
                position: 'absolute',
                top: 0, left: 0, right: 0,
                pointerEvents: 'none',
                display: vis > 0 ? 'none' : 'block',
            }}>{props.value}</div>
        </div>
    )
}
