import React, { useEffect, useState } from 'react'
import { IngredientFood, emptyNutrients } from '../../../core/db_food'
import { NutrientsItem } from '../../../component/NutrientsItem'
import { nutrientsByWeightAndPortions } from '../../../core/abstractManager'
import { useSwipeable } from 'react-swipeable'
import { SimpleButton } from '../../../component/Form/Button/SimpleButton'
import { DishNumber } from '../MealtimeEditPage/DishNumber'
import cl from './index.module.scss'


type Props = {
    item: IngredientFood
    disabled: boolean
    change: (item: IngredientFood) => void
    del: (dt: number) => void
}

export const MixedFoodItem: React.FunctionComponent<Props> = ({ item, change, disabled, ...props }) => {
    const [calculatedNutrients, setCalculatedNutrients] = useState(emptyNutrients())
    const [showDel, setShowDel] = useState(0)
    const handlers = useSwipeable({
        onSwipedLeft: () => setShowDel(m => m - 1 < 0 ? -1 : 0),
        onSwipedRight: () => setShowDel(m => m + 1 > 0 ? 1 : 0),
    })
    useEffect(() => {
        setCalculatedNutrients(nutrientsByWeightAndPortions(item))
    }, [item])
    const changeWeight = (value: number) => {
        item.w = value
        change({ ...item })
    }
    const del = () => {
        props.del(item.dt)
    }
    return (
        <div className={cl.dish}>
            <div className={cl.dish__del_left} style={{
                marginLeft: (showDel > 0 ? 0 : -100 * (Math.abs(showDel) + 1)),
            }}>
                <SimpleButton type='danger' disabled={disabled} click={del} >Удалить</SimpleButton>
            </div>
            <div className={cl.dish_item} >
                <div className={cl.dish_item__name_nuts} {...handlers} onClick={() => setShowDel(m => m != 0 ? 0 : 1)}>
                    <div className={cl.dish_item__name_nuts__name}>{item.name}</div>
                    <div className='nuts' style={{ paddingRight: 2 }}>
                        <NutrientsItem nutrients={calculatedNutrients} ext={'no'} />
                    </div>
                </div>
                <div className={cl.dish_item__weight}>
                    <DishNumber value={item.w} step={1} disabled={disabled} change={changeWeight}/>
                </div>
            </div>
            <div className={cl.dish__del_right} style={{
                marginRight: (showDel < 0 ? 0 : -100),
            }}>
                <SimpleButton type='danger' disabled={disabled} click={del} >Удалить</SimpleButton>
            </div>
        </div>
    )
}
