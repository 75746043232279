import React, { useEffect, useRef, useState } from 'react'
import { Food, Nutrients } from '../../../core/db_food'
import { useLoaderData } from 'react-router-dom'
import { FoodNutrientsManager } from '../../../core/food_simple'
import { InputNumber } from '../../../component/Form/Elements/InputNumber'
import cl from './index.module.scss'


type Props = {
    food: Food
    change: (nut: Nutrients) => void
    disabled: boolean
}

export const SimpleFood: React.FunctionComponent<Props> = (props) => {
    const data = useLoaderData() as { foodId: number, babyId?: number }
    const [nut, setNut] = useState(undefined as Partial<Nutrients> | undefined)
    const nutManager = useRef(undefined as undefined | FoodNutrientsManager)
    useEffect(() => {
        if (!props.food) {
            return
        }
        if (!nutManager.current) {
            nutManager.current = new FoodNutrientsManager(props.food, data.foodId < 0)
            setNut(nutManager.current.nut)
        } else {
            const newNut = nutManager.current.extractPartialNutrients(props.food)
            if (JSON.stringify(nut) !== JSON.stringify(newNut)) {
                setNut(newNut)
            }
        }
    }, [props.food])
    useEffect(() => {
        if (!nut || !nutManager.current) {
            return
        }
        props.change(nutManager.current.getNutrients())
    }, [nut])
    if (!nut) {
        return null
    }
    const onChangeProt = (value: number) => {
        if (!nutManager.current) return
        setNut(nutManager.current.change({ p: value }))
    }
    const onChangeFat = (value: number) => {
        if (!nutManager.current) return
        setNut(nutManager.current.change({ f: value }))
    }
    const onChangeCar = (value: number) => {
        if (!nutManager.current) return
        setNut(nutManager.current.change({ c: value }))
    }
    const onChangeFa = (value: number) => {
        if (!nutManager.current) return
        setNut(nutManager.current.change({ fa: value }))
    }
    const onChangeEne = (value: number) => {
        if (!nutManager.current) return
        setNut(nutManager.current.change({ e: value }))
    }
    return (
        <>
            <FromInput label='Белки:' labelExt='г/100г'
                autofocus={props.food.name.length !== 0}
                value={nut.p || 0} step={0.1}
                change={onChangeProt}
                disabled={props.disabled}
            />
            <FromInput label='ФА:' labelExt='мг/100г'
                value={nut.fa || 0} step={0.1}
                change={onChangeFa}
                disabled={props.disabled}
            />
            <FromInput label='Жиры:' labelExt='г/100г'
                value={nut.f || 0} step={0.1}
                change={onChangeFat}
                disabled={props.disabled}
            />
            <FromInput label='Углеводы:' labelExt='г/100г'
                value={nut.c || 0} step={0.1}
                change={onChangeCar}
                disabled={props.disabled}
            />
            <FromInput label='Энерг.ценность:' labelExt='ккал'
                value={nut.e || 0} step={0.1}
                change={onChangeEne}
                disabled={props.disabled}
            />
            <div className={cl.simple_food}></div>
        </>
    )
}

export type FormBlankInputProps = {
    label: string
    labelExt: string
    children: React.ReactNode
}
export const FromBlankInput: React.FunctionComponent<FormBlankInputProps> = (props) => {
    return (
        <>
            <span className={cl.form__label}>{props.label}</span>
            {props.children}
            <span className={cl.form__ext}>{props.labelExt}</span>
        </>
    )
}

export type FormInputProps = {
    label: string
    labelExt: string
    value?: number
    step: number
    autofocus?: boolean
    change: (value: number) => void
    disabled: boolean
}
export const FromInput: React.FunctionComponent<FormInputProps> = (props) => {
    return <FromBlankInput label={props.label} labelExt={props.labelExt}>
        <InputNumber className={cl.form__input_number}
            value={props.value} step={props.step}
            autofocus={props.autofocus}
            change={props.change}
            disabled={props.disabled}
        />
    </FromBlankInput>
}




export const FormLabelOnly: React.FC<{label: string}> = (props) => {
    return <FromBlankInput label={props.label} labelExt=''><span></span></FromBlankInput>
}

export type FormFromToInputProps = {
    label: string
    labelExt: string
    value1?: number
    value2?: number
    step: number
    autofocus?: boolean
    change1: (value: number) => void
    change2: (value: number) => void
    disabled: boolean
}
export const FromFromToInput: React.FunctionComponent<FormFromToInputProps> = (props) => {
    return <FromBlankInput label={props.label} labelExt={props.labelExt}>
        <InputNumber className={cl.form__input_number}
            value={props.value1} step={props.step}
            autofocus={props.autofocus}
            change={props.change1}
            disabled={props.disabled}
        />
        <InputNumber className={cl.form__input_number}
            value={props.value2} step={props.step}
            change={props.change2}
            disabled={props.disabled}
        />
    </FromBlankInput>
}
