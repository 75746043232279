import React from 'react'
import { RadioInput } from './RadioInput'
import cl from './index.module.scss'
import { cls } from '../../../../core/helper'


type Props<T = any> = {
    radioId: string
    group: string
    label: string
    value: T
    selected: boolean
    change: (val: T) => void
    disabled?: boolean
}

export const Radio: React.FunctionComponent<Props> = ({ label, ...props }) => {
    return (
        <div {...cls(
            cl.radio,
            props.disabled === true ? cl['radio--disabled'] : '',
        )}>
            <div className={cl.radio__input}><RadioInput {...props} /></div>
            <label htmlFor={props.radioId}>{label}</label>
        </div>
    )
}
