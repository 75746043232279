export const urls = {
    selectEnterPage: '/',
    createAccountInformationPage: '/info/create-account',
    loginPage: '/main/login',
    createUserPage: '/main/create',
    agreementsPage: '/client/agreements',
    migrationPage: '/client/migration',
    initializeDatabase: '/client/init-database',
    homePage: '/client/home',
    logoutPage: '/client/logout',
    childrenPage: '/client/children',
    editBabyPage: (babyId: any = ':babyId') => `/client/baby/${babyId}`,
    mealtimePage: (babyId: any = ':babyId') => `/client/baby/${babyId}/mealtime`,
    editMealtimePage: (babyId: any = ':babyId', mealtimeId: any = ':mealtimeId') => `/client/baby/${babyId}/mealtime/${mealtimeId}`,
    conditionPage: (babyId: any = ':babyId') => `/client/baby/${babyId}/condition`,
    listConditionsPage: (babyId: any = ':babyId') => `/client/baby/${babyId}/condition/list`,
    editConditionsPage: (babyId: any = ':babyId', conditionDt: any = ':conditionId') =>
        `/client/baby/${babyId}/condition/${conditionDt}/edit`,
    foodsPage: (babyId?: number | string, mealtimeId?: number, foodsDts?: number[]) => {
        let url = '/client/baby/foods'
        if (babyId) {
            url = `/client/baby/${babyId}/foods`
        }
        if (!babyId || (!mealtimeId && (!foodsDts || foodsDts.length == 0))) {
            return url
        }
        url += `#b=${babyId}`
        if (mealtimeId) {
            url += `&m=${mealtimeId}`
        }
        if (foodsDts && foodsDts.length > 0) {
            url += `&f=${JSON.stringify(foodsDts)}`
        }
        return url
    },
    editFoodPage: (
        foodId: any = ':foodId',
        babyId?: number | string,
        mealtimeId?: number,
        foodsDts?: number[],
        foodName?: string,
    ) => {
        let url = `/client/baby/food/${foodId}/edit`
        const hashArr = [] as string[]
        if (foodName) {
            hashArr.push(`fname=${encodeURIComponent(foodName)}`)
        }
        if (babyId) {
            url = `/client/baby/${babyId}/food/${foodId}/edit`
            if (!isNaN(parseInt(`${babyId}`))) {
                // чтобы из root.tsx не добавлялось
                hashArr.push(`b=${babyId}`)
            }
            if (mealtimeId) {
                hashArr.push(`m=${mealtimeId}`)
            }
        }
        if (foodsDts && foodsDts.length > 0) {
            hashArr.push(`f=${JSON.stringify(foodsDts)}`)
        }
        if (hashArr.length == 0) {
            return url
        }
        return url + '#' + hashArr.join('&')
    },
}