import React, { FunctionComponent, useCallback, useEffect, useState } from 'react'
import { Await, LoaderFunctionArgs, defer, redirect, useLoaderData, useNavigate } from 'react-router-dom'
import { Input, Password } from '../../component/Input'
import { ClientVersion, authorization, authorized, checkAuthorized, clientVersion, getClient, setClientVersion } from '../../core/auth'
import { ApiError } from '../../core/api'
import { urls } from '../../core/urls'
import { AwaitError } from '../../component/AwaitError'
import { HeadTitle } from '../../component/Head/HeadTitle'
import { Page, PageMain } from '../../component/Layout/Page'
import { useErrors } from '../../hooks/useErrors'
import { SmartErrorList } from '../../component/SmartErrorList'
import { DuetButtons } from '../../component/ActionButtons/DuetButtons'
import cl from './index.module.scss'


type StageType = 'wait_user' | 'authorization' | 'error' | 'login' | 'authorized'
export const LoginPage: FunctionComponent = () => {
    const navigate = useNavigate()
    const data = useLoaderData() as {
        result: Promise<{ client: string, version: string }>
    }
    const [stage, setStage] = useState('wait_user' as StageType)
    const onNext = useCallback(() => {
        setStage('login')
    }, [setStage])
    return (
        <Page hFull={true}>
            <Page.Main>
                <PageMain.Title>
                    <HeadTitle showLangs={true} showVersion={true}/>
                </PageMain.Title>
                <PageMain.Content>
                    <div className={cl.class}>
                        <React.Suspense
                            fallback={<p className="p-3">
                                <span className="loading loading-spinner loading-xs"></span>
                                <span className="pl-1">Проверка авторизации...</span>
                            </p>}
                        >
                            <Await
                                resolve={data.result}
                                errorElement={<AwaitError />}
                            >
                                {(ver) => <LoginContent version={ver?.version} stage={stage} setStage={setStage} />}
                            </Await>
                        </React.Suspense>
                    </div>
                </PageMain.Content>
            </Page.Main>
            <Page.Footer key="button">
                <div style={{ padding: 16 }}>
                    <DuetButtons
                        first={{
                            type: 'transparent',
                            content: 'Назад',
                            action: () => navigate(urls.selectEnterPage),
                        }}
                        second={{
                            type: 'success',
                            content: 'Далее',
                            action: onNext,
                        }}
                    />
                </div>
            </Page.Footer>
        </Page>
    )
}

const LoginContent: FunctionComponent<{
    version?: string
    stage: string
    setStage: React.Dispatch<React.SetStateAction<StageType>>
 }> = ({ stage, setStage, ...props }) => {
     const [login, setLogin] = useState('')
     const [pass, setPass] = useState('')
     const [version, setVersion] = useState(props.version)
     const [errors, addError, resetError] = useErrors()
     const navigate = useNavigate()
     useEffect(() => {
         setVersion(props.version)
     }, [props.version])
     useEffect(() => {
         if (version === undefined) {
             return
         }
         if (version === 'new') {
             setClientVersion(undefined, version)
             navigate(urls.createUserPage)
         } else if (version === 'v1') {
             setClientVersion(undefined, version)
             navigate(urls.migrationPage)
         } else if (version === 'v2') {
             setClientVersion(undefined, version)
             navigate(urls.initializeDatabase)
         } else {
             console.error({
                 error: 409,
                 message: 'wrong version',
                 detail: `Текущая версия (${version}) ошибочна, требуется авторизоваться заново.`,
             } as ApiError)
             setStage('error')
         }
     }, [version])
     useEffect(() => {
         if (stage !== 'login') {
             return
         }
         setStage('authorization')
         authorization(login, pass).then(result => {
             authorized(result)
             return clientVersion()
         }).then(result => {
             return new Promise<string>((resolve, reject) => {
                 if (['new', 'v1', 'v2'].includes(result.version)) {
                     resolve(result.version)
                 } else {
                     reject({
                         error: 409,
                         message: 'wrong version',
                         detail: `Текущая версия (${version}) ошибочна, требуется авторизоваться заново`,
                     } as ApiError)
                 }
             })
         }).then(version => {
             setVersion(version)
             setStage('authorized')
             return redirect(urls.migrationPage)
         }).catch((err: ApiError) => {
             console.error('ApiError:', err)
             if (err.error === 451) {
                 navigate(urls.agreementsPage)
             } else {
                 setStage('error')
                 addError(err)
             }
         })
     }, [stage])

     const onClick = useCallback(() => { setStage('login') }, [])
     const isDisabled = stage === 'authorization'
     return (
         <div className={cl.class__content}>
             <h1>Авторизация</h1>
             <div className={cl.class__content_form}>
                 <span className={cl.class__content_form_label}>Логин:</span>
                 <Input autofocus={true} value={login} onChange={setLogin} disabled={isDisabled} placeholder=' ' />
                 <span className={cl.class__content_form_label}>Пароль:</span>
                 <Password value={pass} onChange={setPass} disabled={isDisabled} placeholder=' ' />
             </div>
             <SmartErrorList errors={errors} resetError={resetError} />
         </div>
     )
 }

export function loader(r: LoaderFunctionArgs) {
    const [isAuthorized, message] = checkAuthorized()
    if (!isAuthorized) {
        return {}
    }
    const client = getClient()
    if (!client) {
        console.info('Нет данных о клиенте, требуется их получить')
        const promise = clientVersion().then(result => {
            setClientVersion(undefined, result.version as ClientVersion)
            return {
                version: result.version,
            }
        })
        return defer({ result: promise })
    }
    if (client.version === 'new') {
        return redirect(urls.createUserPage)
    } else if (client.version === 'v1') {
        return redirect(urls.migrationPage)
    } else if (client.version === 'v2') {
        return redirect(urls.initializeDatabase)
    }
    throw new Error('AAAAA')
}
