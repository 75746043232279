import React, { useCallback, useEffect, useState } from 'react'
import { Condition } from '../../../core/db_condition'
import { SimpleButton } from '../../../component/Form/Button/SimpleButton'
import { cls, curTime } from '../../../core/helper'
import { dtToTimeDate } from '../MealtimePage/DateComp'
import { ModalDateTime } from '../../../component/ModalDateTime'
import cl from './index.module.scss'


type Props = {
    dt?: number
    updateCondition: (val: Partial<Condition>) => void
    disabled: boolean
}

export const DateTime: React.FunctionComponent<Props> = ({ updateCondition, disabled, ...props }) => {
    const [info, setInfo] = useState(props.dt && dtToTimeDate(props.dt))
    const [str, setStr] = useState('')
    const [modalVisible, setModalVisible] = useState(false)
    useEffect(() => {
        if (!props.dt) { return setInfo(undefined) }
        const info = dtToTimeDate(props.dt)
        setStr(`${info.date} ${info.time}`)
        setInfo(info)
    }, [props.dt])
    const clickCurrentTime = useCallback(() => {
        updateCondition({ dt: curTime(true) })
    }, [updateCondition])
    const change = useCallback((dt: number)=> {
        updateCondition({ dt })
    }, [updateCondition])
    return (
        <>
            <span {...cls(
                'form_label',
                cl.datetime__label,
            )}>Дата замера:</span>
            <div className={cl.datetime__and_but}>
                <SimpleButton type='light-success'
                    click={() => setModalVisible(true)}
                    disabled={disabled}
                >{str}</SimpleButton>
                <SimpleButton type='transparent'
                    click={clickCurrentTime}
                    disabled={disabled}
                >Сейчас</SimpleButton>
            </div>
            {
                props.dt ? <ModalDateTime
                    title='Дата и время анализа'
                    dt={props.dt}
                    change={change}
                    visible={modalVisible}
                    setVisible={setModalVisible}
                    disabled={disabled}
                /> : undefined
            }
        </>
    )
}
