import React, { useEffect, useState } from 'react'
import { DAY_SEC, cls, dtDate } from '../../core/helper'
import { BabyNeedStatistics } from '../../core/baby_info'
import { NutrientsItem, NutrientsLine } from '../NutrientsItem'
import { Nutrients } from '../../core/db_food'
import { textAgo } from '../TextAgo'
import cl from './index.module.scss'


type Props = {
    info?: BabyNeedStatistics
}

export const NetLastDays: React.FunctionComponent<Props> = ({ info, ...props }) => {
    if (!info) {
        return null
    }
    return (
        <div className={cl.net_last_days}>
            {
                info.lastDay
                    ? <div {...cls(cl.class__user_nuts, 'lnuts')} style={{ width: '100%' }}>
                        <NutrientsLine line={['', 'б(г)', 'ФА(мг)', 'ж', 'у', 'ккал']} />
                        <DayNutrientsItem day={info?.lastDay} />
                        <DayNutrientsItem day={info?.prevDay} />
                    </div> : undefined
            }
            <div {...cls(cl.class__user_needs, 'font-10')}>
                <div>
                    Потребность ФА: <span className='text-fa'>{info?.faNeeds} мг</span> ({textAgo(info?.dtNeeds)} назад)
                </div>

            </div>
        </div>
    )
}

const DayNutrientsItem: React.FC<{ day?: { dt: number, nutrients: Nutrients } }> = (props) => {
    const [day, setDay] = useState('')
    useEffect(() => {
        if (!props.day) {
            return
        }
        const curDt = new Date()
        const prevDt = new Date(curDt.getTime() - DAY_SEC * 1000)
        const dt = dtDate(props.day.dt)
        let day = dt.toLocaleDateString()
        if (dt.toDateString() == curDt.toDateString()) {
            day = 'Сегодня'
        } else if (dt.toDateString() == prevDt.toDateString()) {
            day = 'Вчера'
        }
        setDay(day)
    }, [props.day])
    if (!props.day) {
        return null
    }
    return (
        <>
            <div style={{ paddingLeft: 0, paddingRight: 8, textAlign: 'left' }}>{day}</div>
            <NutrientsItem nutrients={props.day.nutrients} ext={'no_with_dot_less'} dotLess={20} />
        </>
    )
}
