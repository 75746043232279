import React, { useEffect, useState } from 'react'
import { useLoaderData } from 'react-router-dom'
import { Baby } from '../core/db_baby'
import { dbManager } from '../core/db'
import { BabyManager } from '../core/mealtime'

export function useBaby(): [Baby | undefined, React.Dispatch<React.SetStateAction<Baby | undefined>>, BabyManager] {
    const data = useLoaderData() as { babyId?: number, babyManager: BabyManager }
    const [baby, setBaby] = useState(undefined as Baby | undefined)
    useEffect(() => {
        if (!data.babyId) {
            return
        }
        const b = initBaby(data.babyId)
        if (b instanceof Promise) {
            b.then((result) => {
                setBaby(result)
            }).catch((err) => {
                console.error(err)
            })
        } else {
            setBaby(b)
        }
    }, [data.babyId])
    return [baby, setBaby, data.babyManager]
}


function initBaby(babyId: number): Baby | Promise<Baby> {
    if (babyId < 0) {
        return {
            id: -1,
            boy: 1,
            name: '',
        }
    }
    return new Promise<Baby>((resolve, reject) => {
        dbManager.db.babies.where('id').equals(babyId).first()
            .then(r => {
                if (!r) {
                    return {
                        id: -1,
                        boy: 1,
                        name: '',
                    }
                }
                return r
            }).then(resolve).catch(reject)
    })
}