import React, { ReactNode, useEffect, useState } from 'react'
import { useLoaderData, useNavigate } from 'react-router-dom'
import { BabyItem } from '../../../component/BabyItem'
import { useBaby } from '../../../hooks/useBaby'
import { cls, curTime, minStringNumber } from '../../../core/helper'
import { MealtimeManager, useMealtime } from '../../../hooks/useMealtime'
import { Mealtime, MealtimeDish } from '../../../core/db_mealtime'
import { useMealtimeTypes } from '../../../hooks/useMealtimeTypes'
import { MealtimeDateTime } from '../../../component/Form/MealtimeDateTime'
import { emptyNutrients } from '../../../core/db_food'
import { NutrientsItem, NutrientsLine } from '../../../component/NutrientsItem'
import { MealtimeDishItem } from './MealtimeDishItem'
import { urls } from '../../../core/urls'
import { Modal } from '../../../component/Modal'
import { ModalDelete } from './ModalDelete'
import { useErrors } from '../../../hooks/useErrors'
import { SmartErrorList } from '../../../component/SmartErrorList'
import { RequestError } from '../../../component/SmartError'
import { ApiError } from '../../../core/api'
import { Page, PageMain } from '../../../component/Layout/Page'
import { ActionButtons } from '../../../component/ActionButtons'
import { RoundButton } from '../../../component/ActionButtons/RoundButton'
import { BtnSettings, DuetButtons } from '../../../component/ActionButtons/DuetButtons'
import cl from './index.module.scss'

type StageType = 'wait_user' | 'save' | 'saving' | 'delete' | 'deleting'
export const MealtimeEditPage: React.FunctionComponent = () => {
    const [errors, addError, resetError] = useErrors()
    const [mts] = useMealtimeTypes()
    const [manager, mealtime, setMealtime] = useMealtime(addError, mts)
    if (!manager || !mealtime) {
        return null
    }
    return (
        <Page>
            <Content manager={manager} mealtime={mealtime} setMealtime={setMealtime}
                errors={errors} addError={addError} resetError={resetError}
            />
        </Page>
    )
}


type Props = {
    errors: RequestError[]
    addError: (err: ApiError) => void
    resetError: (err?: RequestError | undefined) => void
    manager: MealtimeManager
    mealtime: Mealtime
    setMealtime: React.Dispatch<React.SetStateAction<Mealtime | undefined>>
}
const Content: React.FunctionComponent<Props> = ({
    errors, addError, resetError,
    manager, mealtime, setMealtime }) => {
    const navigate = useNavigate()
    const [stage, setStage] = useState('wait_user' as StageType)
    const data = useLoaderData() as { babyId: number, mealtimeId: number }
    const [mts] = useMealtimeTypes()
    const [baby] = useBaby()
    const [totalWeight, setTotalWeight] = useState(NaN as number)
    const [calculatedNutrients, setCalculatedNutrients] = useState(emptyNutrients())
    const [modalVisible, setModalVisible] = useState(false)
    const [modalRedirect, setModalRedirect] = useState('')
    const [hasChanges, setHasChanges] = useState(false)
    const [inDraft, setInDraft] = useState(false)
    useEffect(() => {
        if (!mealtime) {
            return
        }
        manager.setCurrent(mealtime)
        if (!manager.hasChanges() && manager.hasDraft()) {
            manager.deleteDraft()
        }
        setInDraft(manager.hasDraft())
        setHasChanges(manager.hasChanges())
        setTotalWeight(manager.getTotalWeight())
        setCalculatedNutrients(manager.getTotalNutrients())
    }, [mealtime])
    useEffect(() => {
        if (stage == 'delete') {
            resetError()
            setStage('deleting')
            manager.deleteDraft()
            setInDraft(manager.hasDraft())
            manager.delete().then(res => {
                navigate(urls.mealtimePage(baby?.id))
            }).catch((err) => {
                addError(err)
                setStage('wait_user')
            })
        }
        if (stage === 'save') {
            resetError()
            setStage('saving')
            manager.deleteDraft()
            setInDraft(manager.hasDraft())
            manager.save().then(res => {
                setHasChanges(manager.hasChanges())
                setStage('wait_user')
                navigate(urls.mealtimePage(baby?.id))
            }).catch((err) => {
                addError(err)
                setStage('wait_user')
            })
        }

    }, [stage])
    if (!mealtime || !mts) {
        return null
    }
    const changeMealtimeTimeType = (dt: number, typeId: number) => {
        setMealtime((m) => {
            return {
                ...m as Mealtime,
                t: typeId,
                dt,
                n: dt > curTime() ? 1 : 0,
            }
        })
    }
    const changeDish = (dish: MealtimeDish) => {
        setMealtime(m => {
            return {
                ...m as Mealtime,
                l: [...(m as Mealtime).l.map(one => {
                    if (one.dt === dish.dt) {
                        return dish
                    }
                    return one
                })],
            }
        })
    }
    const deleteDish = (dish: MealtimeDish) => {
        setMealtime(m => {
            return {
                ...m as Mealtime,
                l: [...(m as Mealtime).l.filter(one => one.dt !== dish.dt)],
            }
        })
    }
    const cancel = () => {
        const target = urls.mealtimePage(data.babyId)
        if (manager.hasChanges()) {
            setModalRedirect(target)
            setModalVisible(true)
        } else {
            navigate(target)
        }
    }
    const clearAndClose = () => {
        manager.deleteDraft()
        navigate(modalRedirect)
    }
    const saveDraft = () => {
        manager.saveDraft()
        navigate(modalRedirect)
    }
    const save = () => {
        setStage('save')
    }
    const eaten = () => {
        manager.eaten()
        setStage('save')
    }
    const addFood = () => {
        manager.saveDraft()
        const mid = manager.getDraftId()
        navigate(urls.foodsPage(data.babyId, mid))
    }
    const del = () => {
        setStage('delete')
    }



    const isDisabled = stage !== 'wait_user'
    const secondButton: BtnSettings | ReactNode | undefined = hasChanges
        ? {
            type: 'success',
            action: save,
            content: 'Сохранить',
        } as BtnSettings
        : manager.hasComePlanned()
            ? {
                type: 'success',
                action: eaten,
                content: 'Съедено',
            } as BtnSettings
            : manager.hasDraft()
                ? undefined
                : <ModalDelete delete={del} disabled={isDisabled} />
    return (
        <>
            <Page.Main>
                <PageMain.Title>
                    <BabyItem key={`${data.babyId}`} baby={baby}
                        tsInDay={mealtime.dt}
                        selectedItem={1}
                        includePlanning={true}
                        excludeMealtimes={[mealtime.id]}
                        additionNutrients={calculatedNutrients}
                    />
                </PageMain.Title>
                <PageMain.Content>
                    <div className={cl.mealtime}>
                        <h1 className='title'
                            style={{ position: 'sticky', top: 0, zIndex: 11,
                                background: '#fff',
                            }}
                        >{data && data.mealtimeId != -1 ? 'Редактируем приём пищи' : 'Новый приём пищи'}</h1>
                        <div className={cl.mealtime__datetime}>
                            <MealtimeDateTime mealtime={mealtime} change={changeMealtimeTimeType} disable={isDisabled} />
                        </div>
                        <div className='font-roboto'
                            style={{
                                display: 'flex',
                                paddingTop: 8,
                                position: 'sticky', top: 34, zIndex: 11,
                                background: '#fff',
                            }}>
                            <div style={{ flex: 1 }}>
                                <div className={cl.mealtime__nuts}>
                                    <NutrientsLine line={['б (г)', 'ФА (мг)', 'ж (г)', 'у (г)', 'ккал']}/>
                                    <NutrientsItem nutrients={calculatedNutrients} ext={'no'} />
                                </div>
                            </div>
                            <div style={{
                                minWidth: 100, fontWeight: 'bold', display: 'flex',
                                alignItems: 'center', justifyContent: 'center',
                            }}>{minStringNumber(totalWeight.toFixed(2))} г</div>
                        </div>

                        <div className='font-roboto'>
                            <div {...cls('border-bottom')} style={{
                                display: 'grid', gridTemplateColumns: '5fr 50px 50px',
                                fontWeight: 'lighter',
                                fontSize: 12,
                                position: 'sticky', top: 72, zIndex: 11,
                                background: '#fff',
                                padding: '5px 8px 0 8px',
                            }}>
                                <div>Название</div>
                                <div style={{ width: 50, textAlign: 'center' }}>Вес</div>
                                <div style={{ width: 50, textAlign: 'center' }}>Порций</div>
                            </div>
                            <div style={{ background: '#F0F0F0', minHeight: '70vh' }}>
                                {
                                    mealtime.l.map(dish => {
                                        return <MealtimeDishItem key={dish.dt} dish={dish}
                                            disabled={isDisabled}
                                            change={changeDish} del={deleteDish} />
                                    })
                                }
                            </div>
                        </div>

                        <SmartErrorList errors={errors} resetError={resetError} />
                        <Modal isShow={modalVisible} changed={setModalVisible}>
                            {
                                inDraft ? (
                                    <>
                                        <h1 className='font-kurale text-title font-20'
                                            style={{ fontWeight: 'normal' }}>Сохранить черновик?</h1>
                                        <p>
                            Обнаружен черновик, содержащий внесённые, но не сохранённые данные.
                                        </p>
                                        <p>
                                                Можно его охранить и продолжить позднее.
                                        </p>
                                    </>
                                ) : (
                                    <>
                                        <h1 className='font-kurale text-title font-20'
                                            style={{ fontWeight: 'normal' }}>Сохранить черновик?</h1>
                                        <p>
                                Обнаружены внесённые, но не сохранённые изменения.
                                        </p>
                                        <p>
                                Есть возможность сохранить их как черновик и продолжить позднее.
                                        </p>
                                    </>
                                )
                            }
                            <div style={{ paddingTop: 64 }}>
                                <DuetButtons
                                    first={{
                                        type: 'light-danger',
                                        action: clearAndClose,
                                        content: inDraft ? 'Удалить' : 'Не сохранять',
                                    }}
                                    second={{
                                        type: 'success',
                                        action: saveDraft,
                                        content: 'Сохранить',
                                    }}
                                />
                            </div>
                        </Modal>
                        <ActionButtons>
                            <RoundButton onClick={addFood} disabled={isDisabled}>
                                <img src='/img/ico-plus.svg' width={24} height={24} />
                            </RoundButton>
                        </ActionButtons>
                    </div>
                </PageMain.Content>
            </Page.Main>
            <Page.Footer key="button">
                <div style={{ padding: 16 }}>
                    <DuetButtons
                        first={{
                            type: 'transparent',
                            action: cancel,
                            content: 'Назад',
                        }}
                        second={secondButton}
                        disabled={isDisabled}
                    />
                </div>
            </Page.Footer>
        </>
    )
}
