import React from 'react'
import cl from './index.module.scss'
import { cls } from '../../../core/helper'


interface Props {
    children: React.ReactNode;
}

const Header: React.FC<Props> = ({ children }) => {
    return <header className={cl['page__header']}>{children}</header>
}
const PreFooter: React.FC<Props> = ({ children }) => {
    return <div className={cl['page__pre-footer']}>{children}</div>
}
const Footer: React.FC<Props> = ({ children }) => {
    return <footer className={cl['page__footer']}>{children}</footer>
}



const Title: React.FC<Props> = ({ children }) => {
    return <div className={cl['main__title']}>{children}</div>
}
const Content: React.FC<Props> = ({ children }) => {
    return <main className={cl['main__content']}>{children}</main>
}

interface MainProps {
    crop?: boolean
    children: React.ReactNode;
}
interface MainComponent {
    (props: MainProps): JSX.Element;
    Title: React.FC<Props>;
    Content: React.FC<Props>;
}
const main: MainComponent = ({ children, crop }: MainProps) => {
    return <main {...cls(
        cl['page__main'],
        cl['main'],
        (crop ? cl['main--crop'] : ''),
    ) }>{children}</main>
}
main.Title = Title
main.Content = Content



interface PageProps {
    hFull?: boolean
    children: React.ReactNode;
}
interface PageComponent {
    (props: PageProps): JSX.Element;
    Header: React.FC<Props>;
    Main: React.FC<MainProps>;
    PreFooter: React.FC<Props>;
    Footer: React.FC<Props>;
}
const page: PageComponent = ({ children, hFull }: PageProps) => {
    return <div {...cls(
        cl['page'],
        hFull === true ? cl['page--hfull'] : '',
    )}>{children}</div>
}

page.Header = Header
page.Main = main
page.PreFooter = PreFooter
page.Footer = Footer


export const Page = page
export const PageMain = main