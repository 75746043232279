import React from 'react'
import { Condition } from '../../../../core/db_condition'
import { OneInput } from './OneInput'


type FormItemType = {
    key: Exclude<keyof Condition, 'ct'>,
    autofocus?: boolean
    label: string
    ext: string
    step: number
}
export const FormItems: React.FC<{
    items: FormItemType[]
    condition?: Partial<Condition>
    updateCondition: (cond: Partial<Condition>) => void
    disabled: boolean
}> = ({ condition, ...props }) => {
    return (
        <>
            {
                props.items.map(one => {
                    return <OneInput key={one.key} label={one.label} labelExt={one.ext}
                        autofocus={one.autofocus}
                        k={one.key} value={condition && condition[one.key]} step={one.step}
                        change={props.updateCondition}
                        disabled={props.disabled} />
                })
            }
        </>
    )
}