import React, { useCallback, useEffect, useRef } from 'react'
import { InputNumber } from '../../Elements/InputNumber'
import { dtToDateString, dtToUnix } from '../../../../core/helper'
import cl from './index.module.scss'


export type ValueType = 'number' | 'date' | 'string' | 'password'
export type Props<T = any> = {
    inputId?: string
    type?: ValueType
    tabIndex?: number
    autofocus?: boolean
    placeholder?: string
    value: T
    step?: number
    change: (v: T) => void
    disabled?: boolean
}

export const OnlyInput: React.FunctionComponent<Props> = (props) => {
    if (props.type === 'number') {
        return <InputNumber {...props} step={props.step || 1} disabled={props.disabled === true}/>
    } else if (props.type === 'date' && (props.value === undefined || typeof (props.value) === 'number')) {
        return <InputDateDt {...props} />
    } else {
        return <InputText {...props} />
    }
}

export const InputText: React.FunctionComponent<Props> = ({ change, ...props }) => {
    const onChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        change(e.target.value)
    }, [change])
    return (
        <input className={cl.text_input}
            type={props.type || 'string'}
            placeholder={props.placeholder || ''}
            tabIndex={props.tabIndex}
            value={props.value} onChange={onChange}
            autoFocus={props.autofocus === true}
            disabled={props.disabled}/>
    )
}

const InputDateDt: React.FunctionComponent<Props<number>> = ({ change, ...props }) => {
    const ref = useRef(null as HTMLInputElement | null)
    useEffect(() => {
        if (!ref.current) {
            return
        }
        ref.current.value = dtToDate(props.value) || ''
    }, [props.value])
    const onBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
        const dt = new Date(e.target.value)
        if (isNaN(dt.getTime())) {
            change(undefined as any)
        } else {
            change(dtToUnix(dt, true))
        }
    }
    return (
        <input ref={ref} className={cl.text_input}
            type={props.type || 'date'}
            placeholder={props.placeholder || ''}
            tabIndex={props.tabIndex}
            autoFocus={props.autofocus === true}
            onBlur={onBlur}
            disabled={props.disabled}/>
    )
}


function dtToDate(dt?: number): string {
    if (!dt) {
        return ''
    }
    return dtToDateString(dt)
}