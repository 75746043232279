import { LastAndFood } from '../hooks/useFood'
import { ApiError } from './api'
import { IngredientFood, Nutrients, emptyNutrients } from './db_food'
import { MealtimeDish } from './db_mealtime'


export type EdibleItem = {
    dt: number
    name: string
    /** @description микстура */
    m?: number
    /** @description вес */
    w: number
    /** @description порций */
    pn: number

} & Nutrients

export class EdibleItemsManager {
    items: EdibleItem[]
    constructor(items: EdibleItem[]) {
        this.items = items
    }

    public setItems(items: EdibleItem[]) {
        this.items = items
    }

    public addItem(item: EdibleItem) {
        if (this.items.find(one => one.dt === item.dt)) {
            throw {
                error: -400,
                message: 'Already added',
                detail: 'Уже добавлено',

            } as ApiError
        }
        this.items.push(item)
    }
    /** на текущий вес и количество порций, может быть как 100 грамм для приёма пищи, так и вес ингредиента для смеси */
    public getTotalNutrients(): Nutrients {
        return this.items.reduce((total, dish) => {
            total.p += nutWeight(dish.p, dish.w, dish.pn)
            total.f += nutWeight(dish.f, dish.w, dish.pn)
            total.c += nutWeight(dish.c, dish.w, dish.pn)
            total.fa += nutWeight(dish.fa, dish.w, dish.pn)
            total.e += nutWeight(dish.e, dish.w, dish.pn)
            return total
        }, emptyNutrients())
    }

    public getTotalWeight(): number {
        return this.items.reduce((total, dish) => {
            total += dish.w * dish.pn
            return total
        }, 0)
    }

    public getAsMealtimeDishes(): MealtimeDish[] {
        return this.items.map(one => {
            return {
                dt: one.dt,
                m: one.m !== undefined ? one.m : 0,
                n: one.name,
                p: one.p,
                f: one.f,
                c: one.c,
                fa: one.fa,
                e: one.e,
                w: one.w,
                pn: one.pn,
            }
        })
    }

    public getAsIngredients(): IngredientFood[] {
        return this.items.map(one => {
            return {
                dt: one.dt,
                name: one.name,
                p: one.p,
                f: one.f,
                c: one.c,
                fa: one.fa,
                e: one.e,
                w: one.w,
            }
        })
    }

    static fromIngredientsList(list: IngredientFood[]): EdibleItem[] {
        return list.map(EdibleItemsManager.fromIngredient)
    }
    static fromMealtimeDishes(list: MealtimeDish[]): EdibleItem[] {
        return list.map(EdibleItemsManager.fromMealtimeDish)
    }
    static fromIngredient(ingredient: IngredientFood): EdibleItem {
        return {
            dt: ingredient.dt,
            name: ingredient.name,
            p: ingredient.p,
            f: ingredient.f,
            c: ingredient.c,
            fa: ingredient.fa,
            e: ingredient.e,
            w: ingredient.w,
            pn: 1,
        }
    }
    static fromLastAndFood(dish: LastAndFood): EdibleItem {
        return {
            dt: dish.f.dt,
            name: dish.f.name,
            p: dish.f.p,
            f: dish.f.f,
            c: dish.f.c,
            fa: dish.f.fa,
            e: dish.f.e,
            m: dish.f.m ? 1 : 0,
            w: dish.lf?.g || 100,
            pn: dish.lf?.pt || 1,
        }
    }

    static fromMealtimeDish(dish: MealtimeDish): EdibleItem {
        return {
            dt: dish.dt,
            name: dish.n,
            p: dish.p,
            f: dish.f,
            c: dish.c,
            fa: dish.fa,
            e: dish.e,
            m: dish.m,
            w: dish.w,
            pn: dish.pn,
        }
    }
}

function nutWeight(nut100: number, weight: number, portions: number): number {
    if (nut100 === 0 || weight === 0 || portions === 0) {
        return 0
    }
    return nut100 / 100 * weight * portions
}