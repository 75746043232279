import React from 'react'
import cl from './checkbox.module.scss'


type Props = {
    title: string
    value: boolean
    tabIndex?: number
    autofocus?: boolean
    change: (v: boolean) => void
    disabled?: boolean
}

export const SmartCheckbox: React.FunctionComponent<Props> = (props) => {
    const onChange = () => {
        props.change(!props.value)
    }
    return (
        <>
            <span></span>
            <span className={cl.checkbox} onClick={onChange} >
                <input type='checkbox'
                    autoFocus={props.autofocus === true}
                    tabIndex={props.tabIndex === undefined ? 0 : props.tabIndex}
                    checked={props.value} onChange={onChange}
                    disabled={props.disabled}/>
                <span>{props.title}</span>
            </span>
        </>
    )
}
