import React from 'react'
import { Page, PageMain } from '../../../../component/Layout/Page'
import { HeadTitle } from '../../../../component/Head/HeadTitle'
import { SimpleButton } from '../../../../component/Form/Button/SimpleButton'
import { useNavigate } from 'react-router-dom'
import { urls } from '../../../../core/urls'
import { DuetButtons } from '../../../../component/ActionButtons/DuetButtons'
import cl from './index.module.scss'



export const SelectEnterPage: React.FunctionComponent = () => {
    const navigate = useNavigate()
    const onSelectExists = () => {
        navigate(urls.loginPage)
    }
    const onCreateNew = () => {
        navigate(urls.createAccountInformationPage)
    }
    return (
        <Page hFull={true}>
            <Page.Main>
                <PageMain.Title>
                    <HeadTitle showLangs={true} showVersion={true}/>
                </PageMain.Title>
                <PageMain.Content>
                    <div className={cl.main}>
                        <div>
                            <SimpleButton type='success' click={onSelectExists} size='big'>Есть аккаунт</SimpleButton>
                        </div>
                        <div>
                            <SimpleButton type='transparent' click={onCreateNew} size='big'>Создать новый</SimpleButton>
                        </div>
                        <div></div>
                    </div>
                </PageMain.Content>
            </Page.Main>
            <Page.Footer key="button">
                <div style={{ padding: 16 }}>
                    <DuetButtons/>
                </div>
            </Page.Footer>
        </Page>
    )
}
