import React from 'react'
import { Mealtime } from '../../../../core/db_mealtime'
import { MealtimeType } from '../../../../core/db_mealtime_type'
import { DateComp } from '../DateComp'
import { NutrientsItem } from '../../../../component/NutrientsItem'
import cl from './index.module.scss'
import { useNavigate } from 'react-router-dom'
import { urls } from '../../../../core/urls'
import { cls } from '../../../../core/helper'
import { MealtimeManager } from '../../../../hooks/useMealtime'


type Props = {
    item: Mealtime
    mts: Record<number, MealtimeType>
    future?: boolean
}

export const MealtimeItem: React.FunctionComponent<Props> = ({ item, mts, future }) => {
    const navigate = useNavigate()
    const mealtimeIsReady = MealtimeManager.hasComePlanned(item)
    return (
        <div style={{ position: 'relative' }}>
            <div {...cls(
                cl.class,
                mealtimeIsReady ? cl['class--come-planned'] : '',
                future ? cl['class--future'] : '',
            )}
            onClick={() => navigate(urls.editMealtimePage(item.b, item.id))}>
                <DateComp dt={item.dt} />
                <div className={cl.class__info}>
                    <div>{mts[item.t].n}</div>
                    <div className={cl.class__nutrients}>
                        <NutrientsItem nutrients={item} />
                    </div>
                </div>
            </div>
            <div className={cl.flag }>
                <img src={future ? '/img/item-future.png' : '/img/item-past.png'} width={6}/>
            </div>
        </div>
    )
}
