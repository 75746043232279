import { ApiError } from '../core/api'

export function rejectVersionChanged() {
    return Promise.reject('version_changed')
}

export function throwVersionChanged(changed: boolean) {
    if (changed) {
        throw 'version_changed'
    }
    // throw {
    //     error: -1000,
    //     message: 'test error',
    //     detail: 'Проверяю ошибку',
    // } as ApiError
}