import { LoaderFunctionArgs, redirect } from 'react-router-dom'
import { checkAuthorized, clientLive, getClient, getInAdvanceRequestTime, setExpiredTime, setInAdvanceRequestTime } from '../core/auth'
import { urls } from '../core/urls'
import { ApiError } from '../core/api'
import { HOUR_SEC, curTime } from '../core/helper'
import { BabyManager } from '../core/mealtime'
import { DbTableName, dbManager, getTablesCounts } from '../core/db'


type Handler = (r: LoaderFunctionArgs) => Promise<LoaderFunctionArgs<any> | Response>
export function smartLoader(
    main?: (r: LoaderFunctionArgs) => Promise<Record<string, any>>,
    ...handlers: Handler[]
) {
    return async (r: LoaderFunctionArgs) => {
        if (!r.context) {
            r.context = {}
        }
        const req = await handlers.reduce(async (r, h) => {
            const req = await r
            if (req instanceof Response) {
                return req
            }
            return await h(req)
        }, Promise.resolve(r) as Promise<LoaderFunctionArgs<any> | Response>)
        if (req instanceof Response) {
            return req
        }
        return main ? main(req) : r.context
    }
}

export async function babyIdLoader(req: LoaderFunctionArgs) {
    const babyId = parseInt(req.params.babyId || '-1') || -1
    req.context.babyId = babyId
    req.context.babyManager = new BabyManager(req.context.babyId)
    return req
}

export async function foodIdLoader(req: LoaderFunctionArgs) {
    const foodId = parseInt(req.params.foodId || '-1') || -1
    req.context.foodId = foodId
    return req
}

export async function mealtimeIdLoader(req: LoaderFunctionArgs) {
    const mealtimeId = parseInt(req.params.mealtimeId || '-1') || -1
    req.context.mealtimeId = mealtimeId
    return req
}

export async function conditionDtLoader(req: LoaderFunctionArgs) {
    const conditionDt = parseInt(req.params.conditionDt || '-1') || -1
    req.context.conditionDt = conditionDt
    return req
}

export async function authorizedLoader(req: LoaderFunctionArgs) {
    const [isAuthorized, err] = checkAuthorized()
    async function live(in_advance?: boolean) {
        if (in_advance) {
            const last_request = getInAdvanceRequestTime()
            if (last_request && (last_request + HOUR_SEC > curTime())) {
                console.info('Пока не спрашиваем что там с session expired')
                return Promise.resolve(true)
            }
            setInAdvanceRequestTime(Math.floor(curTime()))
        }
        const res = await clientLive()
        if (res.unix_expired && res.unix_expired > 0) {
            setExpiredTime(res.unix_expired)
            return Promise.resolve()
        }
        return await Promise.reject(undefined)
    }
    if (!isAuthorized) {
        if (err === 'soon_session_expired') {
            live(true).then(_=>{}).catch(_=>{})
            return req
        } else if (err === 'session_expired') {
            return new Promise<LoaderFunctionArgs<any> | Response>((resolve, reject) => {
                live().then(() => {
                    resolve(req)
                }).catch(err => {
                    if (!err) {
                        reject({
                            error: 401,
                            message: 'Unauthorized',
                            details: 'Время действия авторизации истекло',
                        } as ApiError)
                    } else {
                        resolve(req)
                    }
                })
            })
        } else {
            return redirect(urls.loginPage)
        }
    } else {
        const client = getClient()
        if (!getClient()) {
            return redirect(urls.loginPage)
        }
        return req
    }
}

export async function appMainPageLoader(r: LoaderFunctionArgs) {
    const [isAuthorized, err] = checkAuthorized()
    if (!isAuthorized && err != 'soon_session_expired') {
        return {}
    }
    return getTablesCounts().then(async (res) => {
        const keys = Object.keys(res) as DbTableName[]
        if (res['mealtimeTypes'] <= 0) {
            return Promise.resolve({})
        }
        let total = 0
        for (let i = 0; i < keys.length; i++) {
            const tableCountRecords = res[keys[i]]
            if (tableCountRecords < 0) {
                return Promise.resolve({})
            }
            if (keys[i] !== 'mealtimeTypes') {
                total += tableCountRecords
            }
        }
        if (total === 0) {
            return Promise.resolve(redirect(urls.initializeDatabase))
        }
        if (res['babies'] !== 1) {
            return Promise.resolve(redirect(urls.childrenPage))
        }
        const baby = await dbManager.db.babies.orderBy('id').first()
        if (!baby?.id) {
            return Promise.resolve(redirect(urls.childrenPage))
        }
        return await Promise.resolve(redirect(urls.mealtimePage(baby.id)))
    }).catch(err => {
        return Promise.resolve({})
    })
}
