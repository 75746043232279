import React, { ReactNode } from 'react'
import cl from './index.module.scss'
import { clickDelay, defaultDelay } from '../../core/helper'


type Props = {
    onClick: () => void
    children: ReactNode
    disabled?: boolean
}

export const RoundButton: React.FunctionComponent<Props> = (props) => {
    const click = clickDelay(defaultDelay, props.onClick)
    return (
        <button className={cl['round-button']}
            onClick={click}
            disabled={props.disabled}>
            {props.children}
        </button>
    )
}


export const AddButton: React.FC<{ onClick: () => void, disabled?: boolean }> = (props) => {
    return (
        <RoundButton onClick={props.onClick} disabled={props.disabled}>
            <img src='/img/ico-plus.svg' width={24} height={24} />
        </RoundButton>
    )
}