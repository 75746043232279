import { useCallback, useEffect, useRef, useState } from 'react'
import { useLoaderData } from 'react-router-dom'
import { Condition } from '../core/db_condition'
import { ApiError } from '../core/api'
import { ConditionDraft } from '../core/draft'
import { AbstractManager } from '../core/abstractManager'
import { EdibleItem, EdibleItemsManager } from '../core/edibleItemsManager'
import { curTime, dtToUnix } from '../core/helper'
import { dbManager } from '../core/db'
import { addErrorIfVersionNotChanged } from './useErrors'


type DataType = {
    babyId?: number
    conditionDt?: number
}

export function useCondition(addError: (err: ApiError) => void): [
    ConditionManager | undefined,
    Condition | undefined,
    (cond: Partial<Condition>) => void
] {
    const data = useLoaderData() as undefined | DataType
    const [manager, setManager] = useState(undefined as undefined | ConditionManager)
    const [condition, setCondition] = useState(undefined as undefined | Condition)
    const v = useRef(0)
    const updateCondition = useCallback((cond: Partial<Condition>) => {
        if (!manager) {return}
        const newCond = {
            ...manager.getCurrent(),
            ...cond,
        }
        manager.setCurrent(newCond)
        setCondition(manager.getCurrent())
    }, [manager, setCondition])
    useEffect(() => {
        if (!data) {
            return
        }
        v.current += 1
        const ver = v.current
        createConditionManager(data)
            .then(mngr => {
                if (ver !== v.current) { return Promise.reject('version_changed')}
                setCondition(mngr.getCurrent())
                setManager(mngr)
            })
            .catch(addErrorIfVersionNotChanged(addError))
    }, [data])
    return [manager, condition, updateCondition]
}

class LegalError extends Error {}
async function createConditionManager(data?: DataType): Promise<ConditionManager> {
    if (!data || !data.babyId || !data.conditionDt) {
        return Promise.reject(new LegalError('no load info'))
    }
    if (data.babyId <= 0) {
        return Promise.reject({
            error: -32,
            message: 'no child for analysis needed',
            detail: 'Не указан человек для которого нужна работа над анализом состояния',
        } as ApiError)
    }
    const draft = new ConditionDraft(data.conditionDt, data.babyId)
    if (data.conditionDt === -1) {
        const cond = emptyCondition(data.babyId)
        const lastCond = await dbManager.db.conditions.where('b').equals(data.babyId).last() as undefined | Condition
        if (lastCond) {
            cond.g = lastCond.g
            cond.w = lastCond.w
            cond.fa = lastCond.fa
            cond.faNeed = lastCond.faNeed
            cond.ct = lastCond.ct
            cond.p = lastCond.p
            cond.p2 = lastCond.p2
            cond.f = lastCond.f
            cond.f2 = lastCond.f2
            cond.c = lastCond.c
            cond.c2 = lastCond.c2
            cond.e = lastCond.e
        }
        return new ConditionManager(data.babyId, draft.getNextDraftId(), cond)
    }
    if (data.conditionDt < -1 || draft.haveDraft()) {
        const d = draft.loadItem()
        if (d && d.current) {
            const mngr = new ConditionManager(data.babyId, data.conditionDt, d.current)
            mngr.setBase(d.base)
            return mngr
        }
    }
    const cond = await dbManager.db.conditions
        .where(['dt', 'b'])
        .equals([data.conditionDt, data.babyId])
        .first() as Condition | undefined
    if (cond) {
        const mngr = new ConditionManager(data.babyId, data.conditionDt, cond)
        mngr.setBase(JSON.parse(JSON.stringify(cond)))
        return mngr
    }
    return Promise.reject({
        error: -31,
        message: 'No condition',
        detail: 'Анализ состояния не найден',
    } as ApiError)

}

export class ConditionManager extends AbstractManager<Condition, ConditionDraft> {
    constructor(
        private initBabyId: number,
        initConditionDt: number,
        current: Condition,
    ) {
        super(initConditionDt, current, new ConditionDraft(initConditionDt, initBabyId))
        this.edibleManager = new EdibleItemsManager([])
    }

    hasDraft(...args: any): boolean {
        if (this.initId < -1) {
            return true
        }
        return this.draft.haveDraft()
    }
    getEdibleItems(val: Condition): EdibleItem[] {
        return []
    }
    applyEdibleItems(val: Condition): void {}

    public setCurrent(current: Condition): void {
        super.setCurrent(current)
    }

    public hasChanges(): boolean {
        this.current.p = this.current.p || undefined
        this.current.f = this.current.f || undefined
        this.current.c = this.current.c || undefined
        this.current.e = this.current.e || undefined
        return super.hasChanges()
    }

    public async save(): Promise<boolean> {
        this.current.p = this.current.p || undefined
        this.current.f = this.current.f || undefined
        this.current.c = this.current.c || undefined
        this.current.e = this.current.e || undefined
        if (this.base && this.base.dt !== this.current.dt && this.base.dt > 0) {
            await dbManager.db.conditions.delete(this.base.dt)
        }
        const cond = await dbManager.db.conditions.where(['dt', 'b']).equals([this.current.dt, this.initBabyId]).first()
        if (!cond) {
            await dbManager.db.conditions.add(this.current)
        } else {
            await dbManager.db.conditions.put(this.current)
        }
        this.base = JSON.parse(JSON.stringify(this.current))
        return true
    }

}

function emptyCondition(babyId: number): Condition {
    return {
        dt: curDateAndTime(),
        b: babyId,
        g: 0,
        w: 0,
        fa: 0,
        faNeed: 0,
        ct: '',
    }
}

export function curDateAndTime(time: string = '08:00'): number {
    const dt = new Date()
    const arr = time.split(':').map(one => parseInt(one))
    if (arr.length == 2) {
        dt.setHours(arr[0])
        dt.setMinutes(arr[1])
        dt.setSeconds(dt.getSeconds())
        dt.setMilliseconds(0)
    }
    const dts = dtToUnix(dt, true)
    if (dts < curTime()) {
        return dts
    }
    return curTime(true)
}