import React, { useCallback } from 'react'
import cl from './index.module.scss'
import { Condition } from '../../../core/db_condition'
import { DateComp } from '../MealtimePage/DateComp'
import { cls, minStringNumber } from '../../../core/helper'


type Props = {
    item: Condition
    click: (item: Condition) => void
}

export const ConditionItem: React.FunctionComponent<Props> = ({ item, ...props }) => {
    const click = useCallback(() => {
        props.click(item)
    }, [item, props.click])
    return (
        <div className={cl.item}
            onClick={click}
        >
            <DateComp dt={item.dt} />
            <div className={cl.item__info}>
                <OneInfo label='Вес:' value={item.w} ext='кг' />
                <OneInfo label='Кол-во ФА:' value={item.fa} ext='мг' />
                <OneInfo label='Рост:' value={item.g} ext='см' />
                <OneInfo label='Потребность ФА:' value={item.faNeed} ext='мг' />
                <div {...cls(
                    cl.info,
                    cl['info--full-line'],
                )}>
                    <div className={cl.info__comment}>{item.ct} </div>
                </div>
            </div>
        </div>
    )
}


const OneInfo: React.FC<{ label: string, value: number, ext: string }> = (props) => {
    return (
        <div className={cl.info}>
            <span className={cl.info__label}>{props.label}</span>
            <span className={cl.info__value}>{minStringNumber(props.value.toFixed(2))}</span>
            <span className={cl.info__ext}>{props.ext}</span>
        </div>
    )
}