import React, { useEffect, useMemo, useRef, useState } from 'react'
import { LoaderFunctionArgs, redirect, useNavigate } from 'react-router-dom'
import { urls } from '../../core/urls'
import { loadDatabase, loadDefaultMealtimeTypes } from '../../core/loadDatabase'
import { checkAuthorized, getClient } from '../../core/auth'
import { DbTableName, ITable, dbManager, getTablesDataToSave, saveTableWithProgress } from '../../core/db'
import { addErrorIfVersionNotChanged, useErrors } from '../../hooks/useErrors'
import { SmartErrorList } from '../../component/SmartErrorList'
import { Page, PageMain } from '../../component/Layout/Page'
import { BottomMenu } from '../../component/BottomMenu'
import { SimpleButton } from '../../component/Form/Button/SimpleButton'
import { HeadTitle } from '../../component/Head/HeadTitle'
import { useTablesCounts } from '../../hooks/useDatabase'
import { ModalWithButton } from '../../component/Modal'
import { ApiError } from '../../core/api'
import { throwVersionChanged } from '../../hooks/hooksHelper'
import { uploadFileToBase } from '../../core/migration'
import { config } from '../../config'
import styles from './index.module.scss'


type Stages = 'wait_user' | 'load_database' | 'loading' |
 'delete_tables' | 'deleting' |
  'upload_database' | 'uploading' |
  'load_mealtime_types' | 'loading_mealtime_types' | 'update' | 'updating'


function resetDbStatuses(tableCounts: Record<DbTableName, number>): Record<string, string> {
    return {
        'mealtime_types': tableCounts?.mealtimeTypes || 'пусто',
        'babies': tableCounts?.babies || 'пусто',
        'condition': tableCounts?.conditions || 'пусто',
        'food': tableCounts?.foods || 'пусто',
        'mealtime': tableCounts?.mealtimes || 'пусто',
        'lastfood': tableCounts?.lastFoods || 'пусто',
    } as Record<string, string>
}

export const InitializeDatabasePage: React.FunctionComponent = () => {
    const navigate = useNavigate()
    const [serVer, setSerVer] = useState(undefined as number | null | undefined)
    const [stage, setStage] = useState('wait_user' as Stages)
    const [tablesCounts, updateTableCounts] = useTablesCounts()
    const [dbStatus, setDbStatus] = useState(resetDbStatuses(tablesCounts))
    const [errors, addErrors, resetError] = useErrors()
    useLoadDataFromServer(stage, setStage, setDbStatus, updateTableCounts, addErrors)
    useLoadMealtimeTypesFromServer(stage, setStage, setDbStatus, updateTableCounts, addErrors)
    useUploadDataToServer(stage, setStage, setDbStatus, addErrors)
    useEffect(() => {
        if (stage !== 'wait_user') {
            return
        }
        if (tablesCounts.mealtimeTypes === 0) {
            setStage('load_mealtime_types')
        }
        setDbStatus(resetDbStatuses(tablesCounts))
    }, [tablesCounts])
    useEffect(() => {
        if (stage !== 'delete_tables') {
            return
        }
        setStage('deleting')
        dbManager.deleteDB().then(() => {
            updateTableCounts()
        }).catch(addErrors).finally(() => {
            setStage('wait_user')
        })
    }, [stage])
    useEffect(() => {
        if (stage !== 'update') {
            return
        }
        setStage('updating')
        const messageChannel = new MessageChannel()
        messageChannel.port1.onmessage = (event) => {
            if (event.data && event.data.type === 'CLEARED_OFFLINE_CACHE') {
                setTimeout(() => {
                    window.location.href = `${config.site_url}/update.html?rnd=${Math.random()}`
                }, 1000)
            }
        }
        if (navigator.serviceWorker.controller) {
            setTimeout(() => {
                window.location.href = `${config.site_url}/update.html?rnd=${Math.random()}`
            }, 20000)
            navigator.serviceWorker.controller.postMessage({ type: 'CLEAR_OFFLINE_CACHE' }, [messageChannel.port2])
        } else {
            window.location.href = `${config.site_url}/update.html?rnd=${Math.random()}`
        }

    }, [stage])
    const isDbEmpty = useMemo(() => {
        const n = Number(dbStatus['babies'])
        return isNaN(n) || n == 0
    }, [dbStatus])
    const onLoad = () => {
        setStage('load_database')
    }
    const onUpload = () => {
        setStage('upload_database')
    }
    const onClearTables = () => {
        setStage('delete_tables')
    }
    const logout = () => {
        dbManager.deleteDB().then(() => {
            navigate(urls.logoutPage)
        }).catch(addErrors)
    }
    const updateVersion = () => {
        setStage('update')
    }
    const isDisabled = stage !== 'wait_user'
    const isOffline = serVer != config.cache_version
    return (
        <Page>
            <Page.Main>
                <PageMain.Title>
                    <HeadTitle showVersion={true} onServerChanged={setSerVer}/>
                </PageMain.Title>
                <PageMain.Content>
                    <div className={styles.class}>
                        <h1>Настройки</h1>
                        {
                            isOffline && serVer !== null && serVer !== undefined ? (
                                <div style={{ marginTop: 12, marginBottom: 12 }}>
                                    <SimpleButton click={updateVersion} type='success'
                                        disabled={stage !== 'wait_user'}>Обновить версию</SimpleButton>
                                </div>
                            ) : null
                        }
                        <div style={{ marginTop: 12, marginBottom: 12 }}>
                            {
                                tablesCounts.babies === 0 && tablesCounts.conditions === 0
                                    ? <SimpleButton type='light-success' click={onLoad} disabled={isDisabled || isOffline}>
                                        Загрузить данные с сервера
                                    </SimpleButton>
                                    : <ModalWithButton
                                        btn={{
                                            type: 'light-success',
                                            children: 'Загрузить данные с сервера',
                                            disabled: isDisabled || isOffline,
                                        }}
                                        duetBtn={{
                                            first: 'cancel',
                                            second: {
                                                action: onLoad,
                                                type: 'success',
                                                content: 'Перезаписать',
                                            },
                                        }}
                                        disabled={isDisabled || isOffline}
                                    >
                                        <h1>Внимание!</h1>
                                        <p>Все локальные данные будут удалены и перезаписаны данными с сервера!</p>
                                    </ModalWithButton>
                            }
                        </div>
                        {
                            Object.keys(dbStatus).map(key => {
                                return <li key={key}>
                                    {key}: <span style={{ fontSize: '80%' }}>{dbStatus[key]}</span>
                                </li>
                            })
                        }
                        <div style={{ marginTop: 8 }}>
                            <ModalWithButton
                                btn={{
                                    type: 'light-success',
                                    children: 'Выгрузить на сервер',
                                    disabled: isDisabled || !serVer,
                                }}
                                duetBtn={{
                                    first: 'cancel',
                                    second: {
                                        action: onUpload,
                                        type: 'success',
                                        content: 'Выгрузить',
                                    },
                                }}
                                disabled={isDisabled || !serVer || isDbEmpty}
                            >
                                <h1>Выгрузка на сервер</h1>
                                <p>Внимание все данные на сервере будут заменены!</p>
                            </ModalWithButton>
                        </div>
                        <div style={{ marginTop: 8 }}>
                            <ModalWithButton
                                btn={{
                                    type: 'danger',
                                    children: 'Очистить БД',
                                    disabled: isDisabled || isOffline,
                                }}
                                duetBtn={{
                                    first: 'cancel',
                                    second: {
                                        action: onClearTables,
                                        type: 'danger',
                                        content: 'Очистить',
                                    },
                                }}
                                disabled={isDisabled || isOffline}
                            >
                                <h1>Очистить БД?</h1>
                                <p>Внимание все локальные данные будут удалены</p>
                            </ModalWithButton>
                        </div>
                        <div style={{ marginTop: 32 }}>
                            <ModalWithButton btn={{ type: 'light-success', children: 'Выйти', disabled: isDisabled }}
                                duetBtn={{
                                    first: 'cancel',
                                    second: {
                                        type: 'danger',
                                        action: logout,
                                        content: 'Очистить и выйти',
                                    },
                                }}
                                disabled={isDisabled}
                            >
                                <h1>Внимание!</h1>
                                <p>Все данные которые небыли предварительно загружены на сервере, будут удалены.</p>
                                <p>Очистить данные и выйти?</p>
                            </ModalWithButton>
                        </div>
                        <SmartErrorList errors={errors} resetError={resetError} />
                    </div>
                </PageMain.Content>
            </Page.Main>
            <Page.Footer key="button-menu">
                <BottomMenu key="button-menu" selected='settings' disabled={isDisabled} />
            </Page.Footer>
        </Page>
    )
}

export function loader(r: LoaderFunctionArgs) {
    const [isAuthorized, _] = checkAuthorized()
    if (!isAuthorized) {
        return redirect(urls.loginPage)
    }
    if (!getClient()) {
        return redirect(urls.loginPage)
    }
    return {}
}




function useUploadDataToServer(
    stage: Stages,
    setStage: React.Dispatch<React.SetStateAction<Stages>>,
    setDbStatus: React.Dispatch<React.SetStateAction<Record<string, string>>>,
    addErrors: (err: ApiError) => void,

) {
    function changeTableStage(distTable: string, status: string) {
        setDbStatus(dict => {
            return { ...dict, [distTable]: status }
        })
    }
    function upload(table: DbTableName, distTable: string, res: Record<DbTableName, any[]>, changeTableStage: any) {
        return new Promise<Record<DbTableName, any[]>>((resolve, reject) => {
            changeTableStage(distTable, 'выгружаем...')
            uploadFileToBase(distTable, res[table]).then(ret => {
                changeTableStage(distTable, 'выгрузили')
                resolve(res)
            }).catch(reject)
        })
    }
    const v = useRef(0)
    useEffect(() => {
        if (stage !== 'upload_database') {
            return
        }
        setStage('uploading')
        v.current += 1
        const ver = v.current
        getTablesDataToSave().then(async res => {
            throwVersionChanged(ver !== v.current)
            return await upload('mealtimeTypes', 'mealtime_types', res, changeTableStage)
        }).then(async res=> {
            throwVersionChanged(ver !== v.current)
            return await upload('foods', 'food', res, changeTableStage)
        }).then(res=> {
            throwVersionChanged(ver !== v.current)
            return upload('lastFoods', 'lastfood', res, changeTableStage)
        }).then(res=> {
            throwVersionChanged(ver !== v.current)
            return upload('babies', 'babies', res, changeTableStage)
        }).then(res=> {
            throwVersionChanged(ver !== v.current)
            return upload('conditions', 'condition', res, changeTableStage)
        }).then(res=> {
            throwVersionChanged(ver !== v.current)
            return upload('mealtimes', 'mealtime', res, changeTableStage)
        }).then(res=> {
        }).catch(addErrorIfVersionNotChanged(addErrors)).finally(() => {
            setStage('wait_user')
        })
    })
}

function useLoadMealtimeTypesFromServer(
    stage: Stages,
    setStage: React.Dispatch<React.SetStateAction<Stages>>,
    setDbStatus: React.Dispatch<React.SetStateAction<Record<string, string>>>,
    updateTableCounts: () => void,
    addErrors: (err: ApiError) => void,
) {
    useEffect(() => {
        if (stage !== 'load_mealtime_types') {
            return
        }
        setStage('loading_mealtime_types')
        setDbStatus(cur => ({ ...cur, 'mealtime_types': 'загрузка...' }))
        loadDefaultMealtimeTypes().then(res => {
            setDbStatus(cur => ({ ...cur, 'mealtime_types': 'загружено' }))
            if (!res.result) {
                return Promise.reject({
                    error: -55,
                    message: 'No mealtime types',
                    detail: 'Не загрузились данные о стондартных названиях приёмов пищи',
                } as ApiError)
            } else {
                dbManager.db.mealtimeTypes.bulkAdd(res.result)
            }
        }).catch(addErrorIfVersionNotChanged(addErrors))
            .finally(() => {
                updateTableCounts()
                setStage('wait_user')
            })

    }, [stage, setDbStatus])
}
function useLoadDataFromServer(
    stage: Stages,
    setStage: React.Dispatch<React.SetStateAction<Stages>>,
    setDbStatus: React.Dispatch<React.SetStateAction<Record<string, string>>>,
    updateTableCounts: () => void,
    addErrors: (err: ApiError) => void,
) {
    useEffect(() => {
        if (stage !== 'load_database') {
            return
        }
        setStage('loading')
        const status: Record<string, string> = {
            'mealtime_types': 'ожидание',
            'babies': 'ожидание',
            'condition': 'ожидание',
            'food': 'ожидание',
            'mealtime': 'ожидание',
            'lastfood': 'ожидание',
        }
        setDbStatus({ ...status })
        const funcs = Object.keys(status).map(key => {
            return function() {
                status[key] = 'подготовка'
                setDbStatus({ ...status })
                return new Promise((resolve, reject) => {
                    status[key] = 'загрузка'
                    loadDatabase(key).then(result => {
                        status[key] = 'готово для записи'
                        setDbStatus({ ...status })
                        const t: Record<string, any> = {}
                        t[key] = result
                        setTimeout(() => {
                            resolve(t)
                        }, 0)
                    }).catch(err => {
                        status[key] = 'error'
                        setDbStatus({ ...status })
                        setTimeout(() => {
                            reject(err)
                        }, 0)
                    })
                })
            }
        })

        runPromisesOneByOne(funcs).then(async (results) => {
            const saving = [
                saveTableWithProgress('mealtime_types', dbManager.db.mealtimeTypes, results['mealtime_types']),
                saveTableWithProgress('babies', dbManager.db.babies, results['babies']),
                saveTableWithProgress('condition', dbManager.db.conditions, results['condition']),
                saveTableWithProgress('food', dbManager.db.foods, results['food']),
                saveTableWithProgress('mealtime', dbManager.db.mealtimes, results['mealtime']),
                saveTableWithProgress('lastfood', dbManager.db.lastFoods, results['lastfood']),
            ]
            for (let i = 0; i < saving.length; i++) {
                const one = saving[i]
                let nam = ''
                const n = await one((name, p) => {
                    nam = name
                    status[name] = `запись в БД ${Math.round(p * 100)}%`
                    setDbStatus({ ...status })
                })
                status[nam] = `в БД = ${n}`
                setDbStatus({ ...status })
            }
            return
        }).then(result => {
            // navigation(urls.childrenPage)
            console.log(result)
            setStage('wait_user')
        }).catch((err) => {
            addErrors(err)
            setStage('wait_user')
        }).finally(updateTableCounts)
    }, [stage])

}


async function runPromisesOneByOne(funcs: (() => Promise<any>)[]): Promise<Record<string, ITable[]>> {
    let results = {}
    let err
    for (let index = 0; index < funcs.length; index++) {
        try {
            results = {
                ...results,
                ...await funcs[index](),
            }
        } catch (e) {
            err = e
        }
    }
    if (err !== undefined) {
        throw err
    }
    return results
}