import { MealtimeDraft } from './draft'

export class BabyManager {
    constructor(private babyId: number) {}
    public getMealtimeDraft() {
        const key = MealtimeDraft.draftKey(this.babyId)
        const j = localStorage.getItem(key)
        if (!j) {
            return undefined
        }
        return JSON.parse(j)
    }

}