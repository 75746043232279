import { ApiRequest } from './api'
import { MealtimeType } from './db_mealtime_type'

export function loadDatabase<T>(table: string) {
    return ApiRequest<{
        error_message?: string
        result?: 'ok'
    }|T>({
        method: 'post',
        url: `/api/client/load-db/${table}`,
    })
}


export function loadDefaultMealtimeTypes() {
    return ApiRequest<{
        result?: MealtimeType[]
    }>({
        method: 'post',
        url: '/api/client/load-defaults/mealtime-types',
    })
}
