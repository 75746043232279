import React from 'react'
import { Condition, FromToNutrientsCondition } from '../../../../core/db_condition'
import { OneFromToInput } from './OneFromToInput'


type FormFromToItemType = {
    key1: keyof FromToNutrientsCondition,
    key2: keyof FromToNutrientsCondition,
    label: string
    ext: string
    step: number
}
export const FormFromToItems: React.FC<{
    items: FormFromToItemType[]
    condition?: Partial<Condition>
    updateCondition: (cond: Partial<Condition>) => void
    disabled: boolean
}> = ({ condition, ...props }) => {
    return (
        <>
            {
                props.items.map(one => {
                    return <OneFromToInput key={one.key1 + one.key2} label={one.label} labelExt={one.ext}
                        k1={one.key1}
                        k2={one.key2}
                        value1={condition && condition[one.key1]}
                        value2={condition && condition[one.key2]}
                        step={one.step}
                        change1={props.updateCondition}
                        change2={props.updateCondition}
                        disabled={props.disabled} />
                })
            }
        </>
    )
}