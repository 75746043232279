import { ApiRequest } from './api'
import { MealtimeType } from './db_mealtime_type'

export function migration() {
    return ApiRequest<{ error_message?: 'already_v2', result?: 'ok'}>({
        method: 'post',
        url: '/api/client/migration',
    })
}

export function createNewBase() {
    return ApiRequest<{ error_message?: 'already_v2', result?: 'ok', mealtime_types?: MealtimeType[]}>({
        method: 'post',
        url: '/api/client/create-new-base',
    })
}

export function uploadFileToBase(filename: string, data: any) {
    return ApiRequest<{result?: 'ok'}>({
        method: 'post',
        url: `/api/client/upload-db/${filename}`,
        data,
    })
}