import { useCallback, useState } from 'react'
import { ApiError } from '../core/api'
import { RequestError } from '../component/SmartError'
import { ApiErrorToRequestError } from '../core/helper'

export function useErrors(): [
    RequestError[],
    (err: ApiError) => void,
    (err?: RequestError) => void
    ] {
    const [errors, setErrors] = useState([] as RequestError[])
    const addError = (err: ApiError) => {
        const e = ApiErrorToRequestError(err)
        if (!e) {
            return
        }
        errors.push(e)
        setErrors([...errors])
    }
    const resetError = useCallback((err?: RequestError) => {
        if (!err) {
            setErrors([])
            return
        }
        console.log(err.ts, 'resetError')
        setErrors((errors) => {
            console.log(err.ts, 'resetError 2')
            const arr = errors.filter(one => one != err)
            if (arr.length != errors.length) {
                return [...arr]
            }
            return errors
        })
    }, [setErrors])
    return [revers(errors), addError, resetError]
}

export function addErrorIfVersionNotChanged(addError: (err: ApiError) => void) {
    return (err: any) => {
        if (err !== 'version_changed') {
            addError(err)
        }
    }
}

function revers<T>(arr: T[]): T[] {
    const l = arr.slice(0, arr.length)
    l.reverse()
    return l
}