import { dbManager } from './db'
import { Condition, FromToNutrientsCondition } from './db_condition'
import { Nutrients, emptyNutrients } from './db_food'
import { Mealtime } from './db_mealtime'
import { DAY_SEC, curTime, dtDate } from './helper'
import { getMealtimeDayStartEnd } from './mealtimeTypes'

export type BabyNeedStatistics = {
    faNeeds: number
    dtNeeds: number
    pfcaNeeds: Partial<FromToNutrientsCondition> & Pick<Condition, 'e'>
    lastDay?: {
        dt: number
        nutrients: Nutrients
    }
    prevDay?: {
        dt: number
        nutrients: Nutrients
    }
}

export async function babyNeedsStatisticsInDay(babyId?: number, dtInDay?: number,
    includePlanning?: boolean, excludeMealtimeIds: number[] = []) {
    if (!babyId) {
        return undefined
    }
    if (!dtInDay) {
        const lm = (await dbManager.db.mealtimes.where('b').equals(babyId).sortBy('dt'))
            .reverse()
            .find(one => includePlanning || (one as Mealtime).n !== 1)
        if (!lm) {
            dtInDay = curTime(true)
        } else {
            dtInDay = lm.dt
        }
    }
    const [start, end] = await getMealtimeDayStartEnd(dtInDay)
    const condition = await babyConditionBefore(babyId, end)
    if (!condition) {
        return undefined
    }
    async function getStatStartEnd(s: number, e: number) {
        const sub = await dbManager.db.mealtimes.where('dt')
            .between(s, e, true, false)
            .filter(one => {
                if (one.b != babyId) {
                    return false
                }
                if (!includePlanning && (one as Mealtime).n === 1) {
                    return false
                }
                if (excludeMealtimeIds.indexOf(one.id) !== -1) {
                    return false
                }
                return true
            })
            .toArray() as Mealtime[]
        return sub.reduce((r, one) => {
            r.p += one.p
            r.f += one.f
            r.c += one.c
            r.fa += one.fa
            r.e += one.e
            return r
        }, emptyNutrients())
    }
    const info: BabyNeedStatistics = {
        dtNeeds: condition.dt,
        faNeeds: condition.faNeed,
        pfcaNeeds: condition,
        lastDay: {
            dt: start,
            nutrients: await getStatStartEnd(start, end),
        },
        prevDay: {
            dt: start - DAY_SEC,
            nutrients: await getStatStartEnd(start - DAY_SEC, end - DAY_SEC),
        },
    }
    // console.log('condition:', info)
    return info
}

export async function babyConditionBefore(babyId: number, ts?: number): Promise<Condition | undefined> {
    if (!ts) {
        return await dbManager.db.conditions.where('b').equals(babyId).last() as Condition | undefined
    }
    return await dbManager.db.conditions.where('b').equals(babyId).filter((one) => {
        return one.dt <= ts
    }).sortBy('dt').then(res => res.pop() as Condition | undefined)
}