import React, { useEffect, useState } from 'react'
import { useNavigate, useRouteError } from 'react-router-dom'
import { RequestError } from '../../../component/SmartError'
import { parseError } from '../../../core/helper'
import { Page, PageMain } from '../../../component/Layout/Page'
import { HeadTitle } from '../../../component/Head/HeadTitle'
import { SimpleButton } from '../../../component/Form/Button/SimpleButton'
import { urls } from '../../../core/urls'
import cl from './index.module.scss'


export const ClientErrorPage: React.FunctionComponent = () => {
    const navigate = useNavigate()
    const error: any = useRouteError()
    const [err, setErr] = useState(undefined as RequestError | undefined)
    useEffect(() => {
        if (!error) {
            setErr(undefined)
        } else {
            console.error(error)
            setErr(parseError(error))
        }
    }, [error])
    const click = () => {
        navigate(urls.loginPage)
    }
    const onLogout = () => {
        navigate(urls.logoutPage)
    }
    if (error === null) {
        return (
            <div>
                <h1>Ошибка</h1>
                <p>{error?.statusText || error?.message || 'Произошла неизвестная ошибка'}</p>
            </div>)
    }
    return (
        <Page>
            <Page.Main>
                <PageMain.Title>
                    <HeadTitle />
                </PageMain.Title>
                <PageMain.Content>
                    <div className={cl.main}>
                        <h1>{err?.error}</h1>
                        <h2>Ошибка: {err?.message}</h2>
                        <p>{err?.detail}</p>
                        {
                            error.error === 403 || error.error === 401 ? (
                                <div>
                                    <SimpleButton type='success' click={click} >Авторизоваться</SimpleButton>
                                </div>
                            ) : error.error === 409
                                ? (
                                    <div>
                                        <SimpleButton type='success' click={onLogout} >Выйти</SimpleButton>
                                    </div>
                                )
                                : <SimpleButton type='light-success' click={click}>
                                Перейти на страницу логина
                                </SimpleButton>
                        }
                    </div>
                </PageMain.Content>
            </Page.Main>
            <Page.Footer key="button-menu">
                <br/>
                <br/>
                <br/>
            </Page.Footer>
        </Page>
    )
}
